<template>
  <div class="top-back">
    <router-link to="/active" class="dashboard-active-item">
      <em class="icon-back"></em>
      Back to Dashboard
    </router-link>
  </div>
</template>
<script>

export default {
  name: "backDashboard",
  data() {
    return {
    };
  }
};
</script>
