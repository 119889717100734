<template>
  <div class="container-full">
    <div class="top-header">
      <backDashboard />
      <headerTitle :moduleDetails="section" />
      <userLogout />
    </div>
    <div class="site-content">
      <menuSidebar :moduleID="this.modules.id" />
      <div class="lo-list">
            <div
              v-for="(lo, index) in loList"
              :key="lo.id"
              :class="[lo.attributes.learning_object_id == loId ? 'active' : '']"
              class="lo-list-item"
            >
              <button
                @click="
                  getLoPlagiarismData(
                    lo,
                    lo.attributes.learning_object_id,
                    lo.attributes.editable_by_instructor,
                    index,
                    lo.attributes.objectable_type
                  )
                "
              >
                <span>{{ lo.attributes.learning_object_order }}.</span>
                {{ lo.attributes.learning_object_name }}
              </button>
            </div>
          </div>
      <div class="main-content">
        <div class="main-title">
          <h1>Plagiarism Detection</h1>

           <!-- Modules dropdown Start -->
            <div class="button-right row">
              <div class="main-searchForms">
                <div class="search-dropdown" v-if="modules_all.length > 1">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >{{ModuleSelectedName}}</button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <a
                      class="dropdown-item"
                      type="button"
                      @click="toggleSort(sortColumn,sortOrder,'')"
                    ></a>
                    <a
                      v-for="(mods,index) in modules_all"
                      :key="index"
                      class="dropdown-item"
                      type="button"
                      @click="getSelectedModules(index)"
                    >{{mods.attributes.name}}</a>
                  </div>
                </div>
              </div>
            </div>

              <!-- Modules dropdown End -->
        </div>
        <div class="main-container">
          
          <div class="lo-view">
            <div class="lo-view__title">
              <h2>LO 4: A dialogic LO let's say</h2>
            </div>
            <div class="lo-view__settings">
              <div class="enable-plagiarism">
                <input type="checkbox" />
                <p>Enable plagiarism detection for this learning object</p>
              </div>
              <div class="plagiarism-range">
                <h3>Strictness:</h3>
                <div class="plagiarism-range__container">
                  <label>Less Strict</label>
                  <input type="range" min="0" max="100" />
                  <label>More Strict</label>
                </div>
              </div>
              <div class="plagiarism-instances">
                <h4>Plagiarism instances detected: 6</h4>
              </div>
            </div>
            <div class="learner-list">
              <div class="data-table column-9">
                <div class="data-head">
                  <div class="data-col">
                    Learner
                    <span class="sorting">
                      <a
                        :class="[
                          'desc',
                          sortColumn === 'name' && sortOrder === 'asc'
                            ? 'active'
                            : '',
                        ]"
                      ></a>
                      <a
                        :class="[
                          'asc',
                          sortColumn === 'name' && sortOrder === 'desc'
                            ? 'active'
                            : '',
                        ]"
                      ></a>
                    </span>
                  </div>
                  <div class="data-col">
                    Matches this learner's work
                    <span class="sorting">
                      <a
                        :class="[
                          'desc',
                          sortColumn === 'name' && sortOrder === 'asc'
                            ? 'active'
                            : '',
                        ]"
                      ></a>
                      <a
                        :class="[
                          'asc',
                          sortColumn === 'name' && sortOrder === 'desc'
                            ? 'active'
                            : '',
                        ]"
                      ></a>
                    </span>
                  </div>
                  <div class="data-col">
                    Percentage Match
                    <span class="sorting">
                      <a
                        :class="[
                          'desc',
                          sortColumn === 'name' && sortOrder === 'asc'
                            ? 'active'
                            : '',
                        ]"
                      ></a>
                      <a
                        :class="[
                          'asc',
                          sortColumn === 'name' && sortOrder === 'desc'
                            ? 'active'
                            : '',
                        ]"
                      ></a>
                    </span>
                  </div>
                  <div class="data-col">
                    Reviewed
                    <span class="sorting">
                      <a
                        :class="[
                          'desc',
                          sortColumn === 'name' && sortOrder === 'asc'
                            ? 'active'
                            : '',
                        ]"
                      ></a>
                      <a
                        :class="[
                          'asc',
                          sortColumn === 'name' && sortOrder === 'desc'
                            ? 'active'
                            : '',
                        ]"
                      ></a>
                    </span>
                  </div>
                </div>
                <div class="data-content">
                  <div class="data-row">
                    <div class="data-col">Abernathy, Swain</div>
                    <div class="data-col">Teleos, Bordan</div>
                    <div class="data-col">99%</div>
                    <div class="data-col">Yes</div>
                  </div>
                  <div class="data-row">
                    <div class="data-col">Abernathy, Swain</div>
                    <div class="data-col">Teleos, Bordan</div>
                    <div class="data-col">99%</div>
                    <div class="data-col">Yes</div>
                  </div>
                  <div class="data-row">
                    <div class="data-col">Abernathy, Swain</div>
                    <div class="data-col">Teleos, Bordan</div>
                    <div class="data-col">99%</div>
                    <div class="data-col">Yes</div>
                  </div>
                  <div class="data-row">
                    <div class="data-col">Abernathy, Swain</div>
                    <div class="data-col">Teleos, Bordan</div>
                    <div class="data-col">99%</div>
                    <div class="data-col">Yes</div>
                  </div>
                  <div class="data-row">
                    <div class="data-col">Abernathy, Swain</div>
                    <div class="data-col">Teleos, Bordan</div>
                    <div class="data-col">99%</div>
                    <div class="data-col">Yes</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import menuSidebar from "../components/menuSidebar.vue";
import headerTitle from "../components/headerTitle.vue";
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";

export default {
  name: "plagiarism-component",
  components: {
    backDashboard,
    userLogout,
    menuSidebar,
    headerTitle,
  },
  data() {
    return {
      learner_list: [],
      section_list: [],
      loList: [],
      section: '',
      ModuleSelectedName: "",
      modules_all: [],
      section_details: null,
      totalPages: [],
      page: 1,
      total_learner: "",
      search_learner: "",
      sorting: "desc",
      sortOrder: "asc",
      sortColumn: "name",
      searchStatus: false,
      display: false,
      lo_index: "",
      lastFeedbackDate: "",
      modules: {
        id: "",
      },
    };
  },
  methods: {

    getCustomerCourseLoList() {
      utilFunctionService.showLoader();
      this.section = JSON.parse(localStorage.getItem("section"));
      api
        .plagiarismLOsList(this.section.id, this.section.attributes.learn_mods[0].id)
        .then(res => {
          utilFunctionService.hideLoader();
          console.log(res.data.data, "*** res.data.data in getLoList learningObjLists");
          this.loList = res.data.data;
          this.loDetails = this.loList[0];
          
        })
        .catch(() => {});
    },

    getSelectedModules(index) {
      
        api
          .plagiarismLOsList(this.section.id, this.modules_all[index].id)
          .then((res) => {
            console.log(res.data);
            this.loList = res.data.data;
            
            
          })
          .catch(() => {});

      this.ModuleSelectedName = this.modules_all[index].attributes.name;
    },

    getLoPlagiarismData() {

    },
    
    getMultiELMList() {
        api
          .getMultiELMListLight(this.section.id)
          .then(res => {
            this.modules_all = res.data.data.attributes.learn_mods
            this.ModuleSelectedName = this.modules_all[0].attributes.name
          })
          .catch(() => {});
    },
    
    
  },
  created() {
      console.log("*** hit created in plagiarism.vue");
      this.section = JSON.parse(localStorage.getItem("section"));
      this.getCustomerCourseLoList();
      this.getMultiELMList();
    }
};
</script>

<style lang="scss" scoped>
.main-content {
  height: 100%;
}
.main-title h1 {
  font-weight: bold;
  font-size: 19px;
  text-transform: capitalize;
  margin-bottom: 32px;
}
.lo-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    width: 240px;
    padding: 4em 2em 1em 2em;
    background: white;
    overflow-y: scroll;
    .lo-list-item {
      display: inline-flex;
      width: 100%;
    }
    button {
      margin-bottom: 1em;
      width: 100%;
      text-align: left;
      background: none;
      border: 1px solid #3dbc9e;
      border-radius: 8px;
      padding: 16px;
    }
  }
.main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  
}
.lo-view {
  width: 100%;
  margin-left: 0;
  .lo-view__title {
    margin-bottom: 16px;
    h2 {
      font-size: 20px;
    }
  }
  .lo-view__settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .enable-plagiarism {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;
      input {
        margin-right: 16px;
      }
    }
    .plagiarism-range {
      width: 100%;
      margin-bottom: 32px;
      h3 {
        font-size: 16px;
        margin-bottom: 8px;
      }
      .plagiarism-range__container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        label:first-of-type {
          margin: 0 16px 0 0;
        }
        label:last-of-type {
          margin: 0 0 0 16px;
        }
        input[type="range"] {
          width: 100%;
          max-width: 700px;
        }
      }
    }
    .plagiarism-instances {
      h4 {
        font-size: 16px;
      }
    }
  }
}
.data-head {
  // border: solid 1px #f1f2f2;
  // background-color: #fff;
  padding: 20px 5px;
  margin: 0 0 5px 0;
}
.data-row {
  border: solid 1px #f1f2f2;
  background-color: #fff;
  padding: 20px 5px;
  margin: 0 0 5px 0;
  margin-bottom: 5px;
}
.data-col {
  display: flex;
}
.data-table.column-7 .data-col:nth-child(2) {
  width: 30%;
}
.data-table.column-7 .data-col:nth-child(2) {
  width: 20%;
}
.sorting {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 2px 0 0 5px;
  a {
    display: block;
    color: #656565;
    margin: 3px 0;
    height: 4px;
    line-height: 0;
    font-size: 0;
    opacity: 0.7;
  }
  .asc {
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-top: #656565 4px solid;
      border-left: 4px transparent solid;
      border-right: 4px transparent solid;
    }
    &.active {
      &:before {
        border-top: black 4px solid;
      }
    }
  }
  .desc {
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-bottom: #656565 4px solid;
      border-left: 4px transparent solid;
      border-right: 4px transparent solid;
    }
    &.active {
      &:before {
        border-bottom: black 4px solid;
      }
    }
  }
}
</style>
