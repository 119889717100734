<template>
  <div class="container-full">
    <div class="top-header">
      <backDashboard />
      <headerTitle :moduleDetails="section"/>
      <userLogout />
    </div>
    <div class="site-content">
      <menuSidebar />
      <div class="main-content pt-4 mt-2">
        <div class="main-title align-items-center">
          <div class="d-flex justify-content-between w-100">
            <h1>Reporting</h1>
          </div>
        </div>
        <tab-slot :tabs="tabs" :initialTab="initialTab">
          <completion slot="tab-panel-completion" ></completion>
          <assessment slot="tab-panel-assessment" ></assessment>
          <grading slot="tab-panel-grading" :section_data="section.attributes" ></grading>
        </tab-slot>
      </div>
    </div>
  </div>
</template>
<script>
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import menuSidebar from "../components/menuSidebar.vue";
import headerTitle from "../components/headerTitle.vue";
import tabSlot from "../components/tabSlot.vue";
import completion from "../components/completion-reporting.vue";
import assessment from "../components/assessment-reporting.vue";
import grading from "../components/grading-reporting.vue";
import api from "../services/api";

export default {
  name: "reportingView",
  components: {
    backDashboard,
    userLogout,
    menuSidebar,
    headerTitle,
    tabSlot,
    completion,
    assessment,
    grading
  },
  data() {
    return {
      section: '',
      reports: '',
      initialTab: 'completion',
      enable_grading_report: false,
      enable_completion_report: false,
      enable_assessment_report: false,
      tabs: [],
      other_tabs: ['completion','assessment', 'grading']
    };
  },
  methods: {
    getReports(section_id) {
      api 
        .getReportsAvailable(section_id)
        .then((res) => {
          this.reports = res.data;
          console.log(this.reports, "*** this.reports");
          this.enable_grading_report = this.reports.enable_grading_report;
          this.enable_completion_report = this.reports.enable_completion_report;
          this.enable_assessment_report = this.reports.enable_assessment_report;
          if(this.enable_completion_report) {
            this.tabs.push('completion');
          }
          if(this.enable_assessment_report) {
            this.tabs.push('assessment');
          }
          if(this.enable_grading_report) {
            this.tabs.push('grading');
          }
          this.initialTab = this.tabs[0];
        })
    }
  },
  created() {
    this.section = JSON.parse(localStorage.getItem("section"));
    this.getReports(this.section.id);
  }
};
</script>


<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";

.resource-search {
  background: #ffffff;
  padding: 0 16px;
  margin: 0;
}

.main-title {
  margin-bottom: 32px;
}

.main-content {
  h1 {
    font-family: 'robotobold', sans-serif;
    font-size: 19px;
    text-transform: capitalize;
  }
}

</style>
