<template>
  <modal
    name="plagiarismModal"
    class=""
    width="80%"
    height="auto"
    :scrollable="true"
  >
    <div class="modal-content">
      <button
        type="button"
        class="modal-close"
        @click="$modal.hide('plagiarismModal')"
      >
        <em class="icon-close"></em>
      </button>
      <div class="modal-body">
        <div class="plagiarised-text">
          <p>{{text}}</p>
        </div>
        <div class="match-description">
            <div class="match-submission">
                <p class="match-submission__title">Matches submission by Otherstudent, Name</p>
                <p class="match-submission__details">Submitted by Otherstudent, Name on May 21, 2022, 9:36am EST</p>
            </div>
            <div class="match-amount">
                <p>100% Match</p>
            </div>
        </div>
        <div class="match-text">
            <p>Sed viverra, diam sit amet eleifend
                            ornare, enim nibh tristique sapien, eget vestibulum
                            lacus diam quis mi.</p>
            <p>Sed viverra, diam sit amet eleifend
                            ornare, enim nibh tristique sapien, eget vestibulum
                            lacus diam quis mi.</p>
            <p>Sed viverra, diam sit amet eleifend
                            ornare, enim nibh tristique sapien, eget vestibulum
                            lacus diam quis mi.</p>
            <p>Sed viverra, diam sit amet eleifend
                            ornare, enim nibh tristique sapien, eget vestibulum
                            lacus diam quis mi.</p>
            <p>Sed viverra, diam sit amet eleifend
                            ornare, enim nibh tristique sapien, eget vestibulum
                            lacus diam quis mi.</p>
            <!-- <p>{{matchText}}</p> -->
        </div>
        <div class="btn-container">
            <button class="btn btn-primary btn-reviewed">Mark as Reviewed</button>
            <button class="btn btn-primary btn-not-plagiarised">Mark as not plagiarised</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
    name: "PlagiarismModal",
    props: ["text", "matchText"],
    methods: {
        closeByEvent() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 64px;
    .modal-body {
        padding: 0;
        width: 100%;
    }
}
.modal-close {
    position: absolute;
    top: 16px;;
    right: 25px;
    margin: 0;
    background-color: transparent;
    border: 0;
    font-size: 20px;
    outline: 0;
}
.plagiarised-text {
    margin-bottom: 16px;
}
.match-description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    .match-submission__title {
        font-weight: bold;
        font-size: 16px;
    }
    .match-submission__details {
        font-size: 10px;
    }
    .match-amount {
        font-weight: bold;
        font-size: 16px;
    }
}
.match-text {
    margin-bottom: 32px;
}
.btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .btn-not-plagiarised {
        background: #3dbc9e;
    }
}
</style>