import { utilFunctionService } from "@/utils/utils.service"
export default function auth ({ to, next }) {
 if (!utilFunctionService.getLocalStorageService("instructor")) {
    if (to.params.id === undefined) {
      return next(`login?&name=${to.name}`)
    } else {
      return next(`login?&name=${to.name}&id=${to.params.id}&loId=${to.params.loId}&moduleId=${to.params.moduleId}`)
    }
  }
 return next()
}
