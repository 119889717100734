<template>
  <div id="resetPassword" class="login-container">
    <loginSidebar />
    <div class="login-right">
      <div class="login-form" v-if="!resetMailSent">
        <ValidationObserver ref="form">
          <div class="form-group">
            <h3>Forgot your password?</h3>
            <p>Please enter the email you use to Log In to Ametros</p>
          </div>
          <div class="form-group">
            <label>Email Address</label>
            <ValidationProvider name="Email address" rules="required|email" v-slot="{ errors }">
                <input type="text" class="form-control" name="username" v-model="email" />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                >{{ errors[0].replace("The ", "" )}}</span>
              </ValidationProvider>
              <span class="invalid" v-if="invalidCred">
                Please enter a registered email
              </span>
          </div>
          <div class="form-group pt-1 mb-3">
            <button type="button" class="btn primary" v-on:click="reset()">Request Password Reset </button>
          </div>
          <div class="text-center">
            <router-link to="/login"
              class="ml-auto forgot-link" href="javascript:void(0);"
            >
              Back to Log In
            </router-link>
          </div>
        </ValidationObserver>
      </div>
      <div class="login-form" v-if="resetMailSent">
        <h4>Password reset link sent</h4>
        <p class="mt-4">
          Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.
        </p>
        <div class="text-right mt-3">
          <router-link to="/login"
              class="btn primary" href="javascript:void(0);"
            >
              Return to log In
            </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import api from "../services/api";
import loginSidebar from "../components/loginSidebar.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "resetPassword",
  components: {
    ValidationProvider,
    ValidationObserver,
    loginSidebar
  },
  data() {
    return {
      email : '',
      isActive: "",
      invalidCred: false,
      passwordFieldType: "password",
      resetMailSent : ''
    };
  },
  methods: {
    reset() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const fb = new FormData();
          fb.append(`reset_password[email]`, this.email);
          fb.append(`reset_password[role]`, 'instructor');
          api
            .forgotPassword(fb)
            .then(res => {
              if(res.status === 200) {
                this.resetMailSent = true
              }else {
                this.invalidCred = true
              }
            })
            .catch(() => {
              this.invalidCred = true;
            });
        }
      });
    }
  }
};
</script>
