<template>
  <div class="container-full">
    <div class="site-content pt-0">
      <div class="main-subMenubar">
        <ul>
          <li class="active">
            <a href="javascript:void(0);"><span>1.</span> Professiona Communication</a>
          </li>
          <li>
            <a href="javascript:void(0);"><span>2.</span> Request from VP</a>
          </li>
          <li>
            <a href="javascript:void(0);"><span>3.</span> Keeping Your Purpose in Mind
            </a>
            <div class="main-menuNotification">
              <span class="notify-icon message"></span>
              <span class="notify-icon submission"></span>
            </div>
          </li>
          <li>
            <a href="javascript:void(0);"><span>4.</span> Reply to Felicity</a>
            <div class="main-menuNotification">
              <span class="notify-icon message"></span>
            </div>
          </li>
          <li>
            <a href="javascript:void(0);"><span>5.</span> Meeting with Mahmoud</a>
             <div class="main-menuNotification">
              <span class="notify-icon submission"></span>
            </div>
          </li>
          <li>
            <a href="javascript:void(0);"><span>6.</span> Send Email to Olive</a>
          </li>
          <li>
            <a href="javascript:void(0);"><span>7.</span> Email from Drew</a>
          </li>
          <li>
            <a href="javascript:void(0);"><span>8.</span> Negative Information Strategy</a>
          </li>
          <li>
            <a href="javascript:void(0);"><span>9.</span> Professional Voice</a>
          </li>
        </ul>
      </div>
      <div class="main-content">
        <div class="main-breadcrumbs">
          <ul>
            <li>
              <a href="javascript:void(0);">Dashboard
                <em class="icon-next"></em>
              </a>
            </li>
            <li><a href="javascript:void(0);">Learner Central</a></li>
          </ul>
          <div class="breadcrumbs-btn">
            <div class="note-dropdown">
              <button class="btn primary medium dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Add Note
              </button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                <div class="head">
                  <h4>Add Note</h4>
                  <a href="javascript:void(0);">
                    <em class="icon-close"></em>
                  </a>
                </div>
                <div class="content">
                  <textarea class="form-control" rows="4"></textarea>
                </div>
                <div class="footer">
                  <button class="btn danger">Remove Note</button>
                  <button class="btn primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-title my-4 pb-2">
          <h3>Submission LO Grading</h3>
        </div>
        <div class="main-photoUser">
          <div class="main-photoUserImage"></div>
          <div class="main-photoUserContent">
            <h4>Russell Hopkins</h4>
            <p>russellhopkins@gmail.com</p>
          </div>
        </div>
        <div class="submission-paragraph">
          <p> Hello, </p>
          <p>Your first assignment is to causing profitability to drop.</p>
        </div>
        <div class="submission-content">
          <learnerSubmission />
        </div>
      </div>
      <div class="main-chats">
        <div class="chat-head">Send Message to Ressell </div>
        <div class="chat-starDate">
          <span>March 25, 2020</span>
        </div>
        <div class="chat-content">
          <div class="chat-item">
            <div class="chat-box">
              <div class="chat-text">
                Hi Russell , did you have a chance to read Drew’s email?
              </div>
              <div class="chat-image"></div>
            </div>
            <div class="chat-timer">
              <div class="chat-time">8 min</div>
            </div>
          </div>
          <div class="chat-item user-item">
            <div class="chat-box">
              <div class="chat-text">
                Hi Russell , did you have a chance to read Drew’s email?
              </div>
              <div class="chat-image"></div>
            </div>
            <div class="chat-timer">
              <div class="chat-time">8 min</div>
            </div>
          </div>
          <div class="chat-item">
            <div class="chat-box">
              <div class="chat-text">
                I think I should let him know that the brief I am preparing is informational and it will not make a recommendation.
              </div>
              <div class="chat-image"></div>
            </div>
            <div class="chat-timer">
              <div class="chat-time">8 min</div>
            </div>
          </div>
          <div class="chat-item user-item">
            <div class="chat-box">
              <div class="chat-text">
                How will you do that?
              </div>
              <div class="chat-image"></div>
            </div>
            <div class="chat-timer">
              <div class="chat-time">8 min</div>
            </div>
          </div>
          <div class="chat-item">
            <div class="chat-box">
              <div class="chat-text">Seamlessly control multiple computers with your flow enabled MX Master 3 for Mac by simply moving</div>
              <div class="chat-image"></div>
            </div>
            <div class="chat-timer">
              <div class="chat-time">8 min</div>
            </div>
          </div>
        </div>
        <div class="chat-footer">
          <div class="chat-form">
            <div class="form-input">
              <textarea type="text" class="form-control"></textarea>
            </div>
            <button class="btn primary">Send</button>
          </div>
          <div class="chat-dropdown note-dropdown">
              <button class="btn dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Saved Messages
              </button>
              <div class="dropdown-menu dropdown-menu-top" aria-labelledby="dropdownMenu2">
                <div class="head">
                  <h4>Saved Messages (4)</h4>
                  <a href="javascript:void(0);">
                    <em class="icon-close"></em>
                  </a>
                </div>
                <div class="content">
                  <div class="chat-messages">
                    Hello, As a digital creator, Not only for to-day creative tasks.
                  </div>
                  <div class="chat-messages">Hello, moving your cursor to the edge of the screen.</div>
                  <div class="chat-messages">
                    Hi Russell , did you have a chance to read Drew’s email?
                  </div>
                  <div class="chat-messages">
                   As a digital creator, investing in the right products efficiency, but also to make your day-to-day creative tasks.
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import learnerSubmission from "../views/learnerSubmission.vue";
export default {
  name: "submissionLo",
  components: {
    learnerSubmission,
  },
  data() {
    return {
      url: process.env.VUE_APP_URL
    };
  },
  methods: {

  }
};
</script>
