<template>
  <div class="container-full">
    <div class="top-header">
      <backDashboard />
      <headerTitle :moduleDetails="modules" />
      <userLogout />
    </div>
    <div class="site-content">
      <menuSidebar />
      <div class="main-content pt-4">
        <div class="data-table column-5">
          <div class="data-head">
            <div class="data-row">

              <div class="data-col">Learning Object</div>
              <div class="data-col">Type</div>
              <div class="data-col">Completed</div>
              <div class="data-col">Report</div>
              <div class="data-col">Analysis Report (only for Coding LO)
            </div>
            </div>
          </div>
          <div class="data-content">
            <div class="data-row" v-for="lo in lo_list" :key="lo.id">
              <div class="data-col">
                {{ lo.name }}
              </div>
              <div class="data-col">{{ lo.type }}</div>
              <div class="data-col">
                {{ lo.completed }} | {{ lo.percentage_completed }}%
              </div>
              <div class="data-col mx-3">
                <button class="btn primary" @click="downloadLoReport(lo)">Download
                </button>
              </div>
              <div class="data-col" v-if="lo.type == 'coding'">
                <button
                  class="btn primary"
                  @click="downloadCodingLoAnalysisReport(lo)"
                >
                  Analysis Report
                </button>
              </div>
              <div class="data-col" v-else></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-5 my-5">
        <h6 class="my-3">
          Total Learners in Section: {{ totalLearnersCount }}
        </h6>
        <h6>
          Completed Module: {{ learnersCompletedCount }} |
          {{ learnersCompletedPercentage }}%
        </h6>
        <div>
          <div class="my-3">
            <button class="btn primary" @click="downloadAllCsv()">Download all CSV
            </button>
          </div>
          <div class="my-3">
            <button class="btn primary" @click="downloadAggregateDialogicCsv()">Download aggregate dialogic CSV
            </button>
          </div>
          <div class="my-3">
            <button class="btn primary" @click="downloadAggregateCodingCsv()">Download aggregate coding CSV
            </button>
          </div>
          <div class="my-3">
            <button class="btn primary" @click="downloadAggregateQuizCsv()">Download aggregate Quiz CSV
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import menuSidebar from "../components/menuSidebar.vue";
import headerTitle from "../components/headerTitle.vue";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import JSZip from "jszip";
import FileSaver from "file-saver";

export default {
  name: "coding-reports",
  components: {
    backDashboard,
    userLogout,
    menuSidebar,
    headerTitle,
  },
  data() {
    return {
      lo_list: [],
      totalPages: [],
      totalLearnersCount: 0,
      learnersCompletedCount: 0,
      learnersCompletedPercentage: 0,
      modules: {}
    };
  },
  methods: {
    getSectionLoDetails() {
      utilFunctionService.showLoader();
      api
        .getSectionLoDetails(this.modules.id)
        .then((res) => {
          console.log(res.data.data);
          let response = res.data.data;
          this.totalLearnersCount =
            response.attributes.total_learners_in_section;
          this.learnersCompletedCount =
            response.attributes.section_completed_learners;
          this.learnersCompletedPercentage =
            response.attributes.section_completed_learner_percentage;
          this.lo_list = response.attributes.user_learning_object_details;
          console.log("**this.lo_list", this.lo_list);
          utilFunctionService.hideLoader();
          this.searchStatus = false;
        })
        .catch(() => {});
    },
    downloadLoReport(lo) {
      utilFunctionService.showLoader();
      api
        .getLoReport(this.modules.id, lo.id, lo.learn_mod_id)
        .then((response) => {
          console.log("*** data");
          console.log(response.data);
          let res = response.data.csv_data;
          let fileName = response.data.filename;
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "text/csv" })
          );
          var link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    downloadCodingLoAnalysisReport(lo) {
      utilFunctionService.showLoader();
      api
        .getCodingLoAnalysisReport(this.modules.id, lo.id, lo.learn_mod_id)
        .then((response) => {
          let res = response.data.csv_data;
          let fileName = response.data.filename;
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "text/csv" })
          );
          var link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    downloadAllCsv() {
      utilFunctionService.showLoader();
      api
        .getAllReports(this.modules.id, this.modules.attributes.learn_mods[0].id)
        .then((response) => {
          const zip = new JSZip();
          let zipName = response.data.filename;
          response.data.all_reports.forEach((element) => {
            let res = element.csv_data;
            let fileName = element.filename;
            zip.file(fileName, res, { binary: true });
          });
          zip.generateAsync({ type: "blob" }).then((content) => {
            FileSaver.saveAs(content, zipName);
          });

          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    downloadAggregateDialogicCsv() {
      utilFunctionService.showLoader();
      api
        .getAggregateDialogicReport(this.modules.id, this.modules.attributes.learn_mods[0].id)
        .then((response) => {
          let res = response.data.csv_data;
          let fileName = response.data.filename;
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "text/csv" })
          );
          var link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    downloadAggregateCodingCsv() {
      utilFunctionService.showLoader();
      api
        .getAggregateCodingReport(this.modules.id, this.modules.attributes.learn_mods[0].id)
        .then((response) => {
          let res = response.data.csv_data;
          let fileName = response.data.filename;
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "text/csv" })
          );
          var link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    downloadAggregateQuizCsv() {
      utilFunctionService.showLoader();
      api
        .getAggregateQuizReport(this.modules.id, this.modules.attributes.learn_mods[0].id)
        .then((response) => {
          let res = response.data.csv_data;
          let fileName = response.data.filename;
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "text/csv" })
          );
          var link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
  },
  created() {
    this.modules = JSON.parse(localStorage.getItem("module"));
    this.getSectionLoDetails();
  },
};
</script>

<style lang="scss" scoped>

  .data-table.column-5 {
    
    .data-head {
      cursor: auto;
      
      .data-row {
        width: 100%;
        background: transparent;
      }
      .data-col {
        padding: 0 0 2em 0;
      }
    }
    .data-content {
      background: white;
      padding: 1em 0;
      display: table;
      width: 100%;
    }
    .data-row {
      justify-content: left;
      display: table-row;
      width: 100%;
      
    }
    .data-col {
      display: table-cell;
      padding: 8px 0 8px 14px;

      .btn.primary {
        padding: 4px 8px;
        min-width: 0;
      }
    }
  }

</style>