<template>
  <div class="container-full">
    <div class="top-header">
      <backDashboard />
      <headerTitle :moduleDetails="module" />
      <userLogout />
    </div>
    <div class="site-content">
      <menuSidebar />
      <div class="main-subMenubar">
        <ul>
          <li
            v-for="(lo, index) in loList"
            :key="lo.id"
            :class="[lo.attributes.learning_object_id == loId ? 'active' : '']"
          >
            <button
              @click="
                getRubricData(
                  lo,
                  lo.attributes.learning_object_id,
                  lo.attributes.editable_by_instructor,
                  index,
                  lo.attributes.objectable_type
                )
              "
            >
              <span>{{ index + 1 }}.</span>
              {{ lo.attributes.name }}
            </button>
          </li>
        </ul>
      </div>
      <div class="main-content">
        <div class="main-title">
          <h3>Manage Rubrics</h3>
          <!-- Modules dropdown Start -->
          <div class="main-searchForms">
            <div class="search-dropdown" v-if="modules_all.length > 1">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ ModuleSelectedName }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                <button
                  class="dropdown-item"
                  type="button"
                  @click="toggleSort(sortColumn, sortOrder, '')"
                ></button>
                <button
                  v-for="(mods, index) in modules_all"
                  :key="index"
                  class="dropdown-item"
                  type="button"
                  @click="getSelectedModules(index)"
                  >{{ mods.attributes.name }}</button
                >
              </div>
            </div>
          </div>

          <!-- Modules dropdown End -->
        </div>
        <h4 v-if="!enableManageRuberic" class="notice-padding">
          There are no rubrics associated with this module.
        </h4>
        <div v-if="enableManageRuberic">
          <div class="data-table column-8 mb-0">
            <div class="data-head">
              <div class="data-col">criteria</div>
              <div class="data-col">0-49</div>
              <div class="data-col">50-59</div>
              <div class="data-col">60-69</div>
              <div class="data-col">70-79</div>
              <div class="data-col">80-89</div>
              <div class="data-col">90-100</div>
              <div class="data-col">Weight</div>
              <div class="data-col">&nbsp;</div>
            </div>
            <div class="data-content">
              <!-- data row repeat start here -->
              <div
                class="grading-row"
                v-for="(data, index) in rubric"
                :key="data + index"
              >
                <div class="grading-col">
                  <div
                    class="grading-box"
                    @click="
                      show(
                        'addCriteriaWeightModal',
                        index,
                        'criteria',
                        data.criteria,
                        data.id,
                        'Criteria'
                      )
                    "
                  >
                    <div
                      class="grading-criteria"
                      :class="[
                        data.criteria == null && validate
                          ? 'grading-invalid'
                          : '',
                      ]"
                    >
                      <div
                        type="text"
                        class="form-control"
                        placeholder="Enter Criteria"
                        v-html="data.criteria"
                      />
                      <p
                        class="invalid"
                        v-if="data.criteria == null && validate"
                      >
                        Mandatory
                      </p>
                    </div>
                  </div>
                </div>
                <div class="grading-col">
                  <div
                    class="grading-box"
                    @click="
                      show(
                        'addFeedbackModal',
                        index,
                        'range_1',
                        data.range_1,
                        data.id,
                        '0-49'
                      )
                    "
                  >
                    <div class="grading-edit" v-if="data.range_1 != null">
                      <div
                        class="form-control"
                        v-html="data.range_1"
                        placeholder="Enter Criteria"
                      ></div>
                      <p></p>
                    </div>
                    <div
                      class="grading-add"
                      :class="[
                        data.range_1 == null && validate
                          ? 'grading-invalid'
                          : '',
                      ]"
                      v-if="data.range_1 == null"
                    >
                      <a href="javascript:void(0);">Add Feedback</a>
                      <p
                        class="invalid"
                        v-if="data.range_1 == null && validate"
                      >
                        Mandatory
                      </p>
                    </div>
                  </div>
                </div>
                <div class="grading-col">
                  <div
                    class="grading-box"
                    @click="
                      show(
                        'addFeedbackModal',
                        index,
                        'range_2',
                        data.range_2,
                        data.id,
                        '50-59'
                      )
                    "
                  >
                    <div class="grading-edit" v-if="data.range_2 != null">
                      <div class="form-control" v-html="data.range_2"></div>
                    </div>
                    <div
                      class="grading-add"
                      :class="[
                        data.range_2 == null && validate
                          ? 'grading-invalid'
                          : '',
                      ]"
                      v-if="data.range_2 == null"
                    >
                      <a href="javascript:void(0);">Add Feedback</a>
                      <p
                        class="invalid"
                        v-if="data.range_2 == null && validate"
                      >
                        Mandatory
                      </p>
                    </div>
                  </div>
                </div>
                <div class="grading-col">
                  <div
                    class="grading-box"
                    @click="
                      show(
                        'addFeedbackModal',
                        index,
                        'range_3',
                        data.range_3,
                        data.id,
                        '60-69'
                      )
                    "
                  >
                    <div class="grading-edit" v-if="data.range_3 != null">
                      <div class="form-control" v-html="data.range_3"></div>
                    </div>
                    <div
                      class="grading-add"
                      :class="[
                        data.range_3 == null && validate
                          ? 'grading-invalid'
                          : '',
                      ]"
                      v-if="data.range_3 == null"
                    >
                      <a href="javascript:void(0);">Add Feedback</a>
                      <p
                        class="invalid"
                        v-if="data.range_3 == null && validate"
                      >
                        Mandatory
                      </p>
                    </div>
                  </div>
                </div>
                <div class="grading-col">
                  <div
                    class="grading-box"
                    @click="
                      show(
                        'addFeedbackModal',
                        index,
                        'range_4',
                        data.range_4,
                        data.id,
                        '70-79'
                      )
                    "
                  >
                    <div class="grading-edit" v-if="data.range_4 != null">
                      <div class="form-control" v-html="data.range_4"></div>
                    </div>
                    <div
                      class="grading-add"
                      :class="[
                        data.range_4 == null && validate
                          ? 'grading-invalid'
                          : '',
                      ]"
                      v-if="data.range_4 == null"
                    >
                      <a href="javascript:void(0);">Add Feedback</a>
                      <p
                        class="invalid"
                        v-if="data.range_5 == null && validate"
                      >
                        Mandatory
                      </p>
                    </div>
                  </div>
                </div>
                <div class="grading-col">
                  <div
                    class="grading-box"
                    @click="
                      show(
                        'addFeedbackModal',
                        index,
                        'range_5',
                        data.range_5,
                        data.id,
                        '80-89'
                      )
                    "
                  >
                    <div class="grading-edit" v-if="data.range_5 != null">
                      <div class="form-control" v-html="data.range_5"></div>
                    </div>
                    <div
                      class="grading-add"
                      :class="[
                        data.range_5 == null && validate
                          ? 'grading-invalid'
                          : '',
                      ]"
                      v-if="data.range_5 == null"
                    >
                      <a href="javascript:void(0);">Add Feedback</a>
                      <p
                        class="invalid"
                        v-if="data.range_5 == null && validate"
                      >
                        Mandatory
                      </p>
                    </div>
                  </div>
                </div>
                <div class="grading-col">
                  <div
                    class="grading-box"
                    @click="
                      show(
                        'addFeedbackModal',
                        index,
                        'range_6',
                        data.range_6,
                        data.id,
                        '90-100'
                      )
                    "
                  >
                    <div class="grading-edit" v-if="data.range_6 != null">
                      <div class="form-control" v-html="data.range_6"></div>
                    </div>
                    <div
                      class="grading-add"
                      :class="[
                        data.range_6 == null && validate
                          ? 'grading-invalid'
                          : '',
                      ]"
                      v-if="data.range_6 == null"
                    >
                      <a href="javascript:void(0);">Add Feedback</a>
                      <p
                        class="invalid"
                        v-if="data.range_6 == null && validate"
                      >
                        Mandatory
                      </p>
                    </div>
                  </div>
                </div>
                <div class="grading-col">
                  <div
                    class="grading-box"
                    :class="[data.disabled ? 'grading-disabled' : '']"
                  >
                    <div
                      class="grading-criteria"
                      :class="[
                        data.weight == null && validate
                          ? 'grading-invalid'
                          : '',
                      ]"
                    >
                      <input
                        class="form-control"
                        v-model="data.weight"
                        placeholder="100%"
                        @click="
                          show(
                            'addCriteriaWeightModal',
                            index,
                            'weight',
                            data.weight,
                            data.id,
                            'Weight'
                          )
                        "
                      />
                      <p class="invalid" v-if="data.weight == null && validate">
                        Mandatory
                      </p>
                    </div>
                  </div>
                </div>
                <div class="grading-col" v-if="editable">
                  <div class="grading-delete">
                    <a href="javascript:void(0);">
                      <em
                        class="icon-trash"
                        @click="
                          $modal.show('deleteRubricModal'),
                            deleteRubric(index, data.id, data.type)
                        "
                      ></em>
                    </a>
                  </div>
                </div>
              </div>
              <div class="add-grading-row pt-2">
                <a
                  v-if="editable"
                  href="javascript:void(0);"
                  @click="addCriteria"
                  class="btn primary add-grading-btn"
                  >+ Criteria</a
                >
                <div
                  class="add-grading-weight"
                  :class="[editable == false ? 'pr-0' : 0]"
                >
                  <!-- Move distribute weight here for provisioning -->
                  <button
                    v-if="editable && loType === 'SubmissionLearnObj'"
                    type="button"
                    class="btn primary"
                    @click="distributeWeightEqually"
                  >
                    Distribute Weight
                  </button>

                  <!-- Move distribute weight here for provisioning end -->

                  <p>Total Weight: {{ getTotalWeights() }}%</p>
                  <p class="invalid mb-2" v-if="totalWeight > 100">
                    Total weight can't be more than 100%
                  </p>
                  <p class="invalid mb-2" v-if="invalidWeight">
                    Total weight should be 100%
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="main-footer-button mt-0 pr-3"
            v-if="loType !== 'SubmissionEmailLearnObj'"
          >
            <button class="btn primary" @click="next" v-if="!isBasicInstructor">
              Save
            </button>
            <div class="mt-4" v-if="selectedLo">
              <p>
                Display learner grade and scores:
                <toggle-button
                  :labels="true"
                  :value="selectedLo.attributes.display_grades"
                  @change="
                    setDisplayGrade(
                      selectedLo.id,
                      !selectedLo.attributes.display_grades
                    )
                  "
                  :disabled="isBasicInstructor"
                />
              </p>
              <p>
                Learner hold on submission:
                <toggle-button
                  :labels="true"
                  :value="selectedLo.attributes.learner_hold_on_submission"
                  @change="
                    setLearnerHoldOnSubmission(
                      selectedLo.id,
                      !selectedLo.attributes.learner_hold_on_submission
                    )
                  "
                  :disabled="isBasicInstructor"
                />
              </p>
            </div>
          </div>
          <div v-if="loType === 'SubmissionEmailLearnObj'">
            <div class="configure-character-response">
              <div class="main-title">
                <h3>Configure Character Response</h3>
              </div>
              <div class="main-tabs">
                <ul>
                  <li
                    :class="[
                      character_affix.active_range === 1 ? 'active' : '',
                    ]"
                    @click="setActiveRange(1, 1)"
                  >
                    <a href="javascript:void(0);">0-59</a>
                  </li>
                  <li
                    :class="[
                      character_affix.active_range === 2 ? 'active' : '',
                    ]"
                    @click="setActiveRange(2, 1)"
                  >
                    <a href="javascript:void(0);">60-79</a>
                  </li>
                  <li
                    :class="[
                      character_affix.active_range === 3 ? 'active' : '',
                    ]"
                    @click="setActiveRange(3, 1)"
                  >
                    <a href="javascript:void(0);">80-100</a>
                  </li>
                </ul>
                <div class="main-tabsContent">
                  <div class="main-tabsPane">
                    <ul class="iteration-tabs">
                      <li
                        :class="[
                          character_affix.active_order === 1 ? 'active' : '',
                        ]"
                        v-if="
                          resubmission_limit == 0 ||
                          resubmission_limit == 1 ||
                          resubmission_limit == 2
                        "
                      >
                        <a
                          @click="
                            setActiveRange(character_affix.active_range, 1)
                          "
                          >Iteration 1</a
                        >
                      </li>
                      <li
                        v-if="
                          resubmission_limit == 1 || resubmission_limit == 2
                        "
                        :class="[
                          character_affix.active_order === 2 ? 'active' : '',
                        ]"
                      >
                        <a
                          @click="
                            setActiveRange(character_affix.active_range, 2)
                          "
                          >Iteration 2</a
                        >
                      </li>
                      <li
                        v-if="resubmission_limit == 2"
                        :class="[
                          character_affix.active_order === 3 ? 'active' : '',
                        ]"
                      >
                        <a
                          @click="
                            setActiveRange(character_affix.active_range, 3)
                          "
                          >Iteration 3</a
                        >
                      </li>
                    </ul>
                    <div class="iteration-tabsContent">
                      <div class="iteration-tabsPane row">
                        <div class="form-group col-6">
                          <label>Introduction</label>
                          <div class="form-control">
                            <span>
                              <p
                                v-html="
                                  character_response_affix[
                                    character_affix.intro
                                  ][character_affix.active_order - 1].value
                                "
                              ></p>
                            </span>
                          </div>
                        </div>
                        <div class="form-group col-6">
                          <label>Conclusion</label>
                          <div class="form-control">
                            <span>
                              <p
                                v-html="
                                  character_response_affix[
                                    character_affix.conc
                                  ][character_affix.active_order - 1].value
                                "
                              ></p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="main-tabs">
                <ul>
                  <li
                    :class="[
                      rubric_character.active_range === 1 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(1, 1, '0-49')"
                  >
                    <a href="javascript:void(0);">0-49</a>
                  </li>
                  <li
                    :class="[
                      rubric_character.active_range === 2 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(2, 1, '50-59')"
                  >
                    <a href="javascript:void(0);">50-59</a>
                  </li>
                  <li
                    :class="[
                      rubric_character.active_range === 3 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(3, 1, '60-69')"
                  >
                    <a href="javascript:void(0);">60-69</a>
                  </li>
                  <li
                    :class="[
                      rubric_character.active_range === 4 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(4, 1, '70-79')"
                  >
                    <a href="javascript:void(0);">70-79</a>
                  </li>
                  <li
                    :class="[
                      rubric_character.active_range === 5 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(5, 1, '80-89')"
                  >
                    <a href="javascript:void(0);">80-89</a>
                  </li>
                  <li
                    :class="[
                      rubric_character.active_range === 6 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(6, 1, '90-100')"
                  >
                    <a href="javascript:void(0);">90-100</a>
                  </li>
                </ul>
                <div class="main-tabsContent">
                  <div class="main-tabsPane">
                    <ul class="iteration-tabs">
                      <li
                        :class="[
                          rubric_character.active_order === 0 ? 'active' : '',
                        ]"
                      >
                        <a
                          @click="
                            setActiveRubric(
                              rubric_character.active_range,
                              0,
                              rubric_character.rangeValue
                            )
                          "
                          >Variation 1</a
                        >
                      </li>
                      <li
                        :class="[
                          rubric_character.active_order === 1 ? 'active' : '',
                        ]"
                      >
                        <a
                          @click="
                            setActiveRubric(
                              rubric_character.active_range,
                              1,
                              rubric_character.rangeValue
                            )
                          "
                          >Variation 2</a
                        >
                      </li>
                      <li
                        :class="[
                          rubric_character.active_order === 2 ? 'active' : '',
                        ]"
                      >
                        <a
                          @click="
                            setActiveRubric(
                              rubric_character.active_range,
                              2,
                              rubric_character.rangeValue
                            )
                          "
                          >Variation 3</a
                        >
                      </li>
                    </ul>
                    <div class="iteration-tabsContent">
                      <div class="iteration-tabsPane">
                        <div class="data-table grading-column-2">
                          <div class="data-head mb-1">
                            <div class="data-col">criteria</div>
                          </div>
                          <div class="data-content">
                            <div
                              class="grading-row"
                              v-for="(
                                character_response, index
                              ) in rubric_character_response"
                              :key="character_response.id"
                            >
                              <div class="grading-col">
                                <div class="grading-box">
                                  <div class="grading-criteria">
                                    <p
                                      class="form-control"
                                      placeholder="Enter Criteria"
                                      :value="
                                        character_response.attributes
                                          .rubric_criteria
                                      "
                                    >
                                      {{
                                        character_response.attributes
                                          .rubric_criteria
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="grading-col">
                                <div class="grading-box">
                                  <div
                                    v-if="
                                      character_response.attributes[
                                        rubric_character.rangeTitle
                                      ][rubric_character.active_order].value
                                    "
                                    class="grading-edit"
                                    @click="
                                      showModel(
                                        'addRubricCharacterResponseModal',
                                        index,
                                        rubric_character.rangeTitle,
                                        character_response.attributes[
                                          rubric_character.rangeTitle
                                        ][rubric_character.active_order].value,
                                        character_response.id,
                                        rubric_character.rangeValue
                                      )
                                    "
                                  >
                                    <p
                                      class="form-control"
                                      placeholder="Enter Criteria"
                                      v-html="
                                        character_response.attributes[
                                          rubric_character.rangeTitle
                                        ][rubric_character.active_order].value
                                      "
                                    ></p>
                                  </div>
                                  <div class="grading-add" v-else>
                                    <a
                                      @click="
                                        showModel(
                                          'addRubricCharacterResponseModal',
                                          index,
                                          rubric_character.rangeTitle,
                                          character_response.attributes[
                                            rubric_character.rangeTitle
                                          ][rubric_character.active_order]
                                            .value,
                                          character_response.id,
                                          rubric_character.rangeValue
                                        )
                                      "
                                      >Add Feedback</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Interim Response start here -->
            <div class="interim-response">
              <div class="main-tabs pt-3">
                <div class="main-title">
                  <h3>Interim Response</h3>
                </div>
                <ul class="iteration-tabs">
                  <li :class="[activeInterim === 1 ? 'active' : '']">
                    <a @click="setAndSaveInterim(1)">Iteration 1</a>
                  </li>
                  <li :class="[activeInterim === 2 ? 'active' : '']">
                    <a @click="setAndSaveInterim(2)">Iteration 2</a>
                  </li>
                  <li :class="[activeInterim === 3 ? 'active' : '']">
                    <a @click="setAndSaveInterim(3)">Iteration 3</a>
                  </li>
                </ul>
                <div class="iteration-tabsContent">
                  <div class="iteration-tabsPane">
                    <vue-editor
                      v-if="isBasicInstructor"
                      v-model="interim_resp[activeInterim]"
                      class="form-control"
                      :disabled="isBasicInstructor"
                    ></vue-editor>
                    <vue-editor
                      v-else
                      v-model="interim_resp[activeInterim]"
                      placeholder="Add a Description"
                      class="form-control"
                    ></vue-editor>
                  </div>
                </div>
                <div class="main-footer-button">
                  <button
                    class="btn primary"
                    @click="saveConfig"
                    v-if="!isBasicInstructor"
                  >
                    Save
                  </button>
                  <div class="mt-4" v-if="selectedLo">
                    <p>
                      Display learner grade and scores:
                      <toggle-button
                        :labels="true"
                        :value="selectedLo.attributes.display_grades"
                        @change="
                          setDisplayGrade(
                            selectedLo.id,
                            !selectedLo.attributes.display_grades
                          )
                        "
                        :disabled="isBasicInstructor"
                      />
                    </p>
                    <p>
                      Learner hold on submission:
                      <toggle-button
                        :labels="true"
                        :value="
                          selectedLo.attributes.learner_hold_on_submission
                        "
                        @change="
                          setLearnerHoldOnSubmission(
                            selectedLo.id,
                            !selectedLo.attributes.learner_hold_on_submission
                          )
                        "
                        :disabled="isBasicInstructor"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <modal
            name="addRubricCharacterResponseModal"
            height="auto"
            :width="700"
            :scrollable="true"
          >
            <div class="modal-content">
              <div class="modal-header">
                <div class="modal-title" id="exampleModalLabel">
                  <h5>Feedback</h5>
                  <p class="addFeedbackTitle">Criteria (Score {{ colName }})</p>
                </div>
              </div>
              <div class="modal-body">
                <form class="modal-form modal-addFeedbackForm">
                  <p v-html="transcript"></p>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  @click="$modal.hide('addRubricCharacterResponseModal')"
                  class="btn default"
                >
                  Cancel
                </button>
              </div>
            </div>
          </modal>
        </div>
      </div>
    </div>
    <modal
      name="addCriteriaWeightModal"
      height="auto"
      :width="450"
      :scrollable="true"
    >
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5 v-if="editable && id == null">Add {{ colName }}</h5>
            <h5 v-if="editable && id !== null">Edit {{ colName }}</h5>
            <h5 v-if="!editable">{{ colName }}</h5>
          </div>
        </div>
        <div class="modal-body">
          <form class="modal-form modal-addFeedbackForm">
            <label>{{ colName }}</label>
            <input
              type="text"
              class="form-control"
              placeholder="Add Criteria"
              v-model="transcript"
              v-if="colName == 'Criteria'"
            />
            <input
              type="number"
              class="form-control"
              placeholder="Add Weight"
              v-model="transcript"
              v-if="colName == 'Weight'"
              min="0"
              max="100"
            />
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="$modal.hide('addCriteriaWeightModal')"
            class="btn default"
          >
            Cancel
          </button>
          <button
            v-if="editable"
            type="button"
            class="btn primary"
            @click="saveCellData('addCriteriaWeightModal')"
          >
            Save
          </button>
        </div>
      </div>
    </modal>
    <modal
      name="addFeedbackModal"
      height="auto"
      :width="700"
      :scrollable="true"
    >
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5 v-if="!editable">Feedback</h5>
            <h5 v-if="editable && id == null">Add Feedback</h5>
            <h5 v-if="editable && id !== null">Edit Feedback</h5>
            <p class="addFeedbackTitle">Criteria (Score {{ colName }})</p>
          </div>
        </div>
        <div class="modal-body">
          <form class="modal-form modal-addFeedbackForm">
            <vue-editor
              v-if="editable"
              id="test"
              :editorToolbar="fullTool"
              v-model="transcript"
            ></vue-editor>
            <p v-html="transcript" v-if="!editable"></p>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="$modal.hide('addFeedbackModal')"
            class="btn default"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn primary"
            @click="saveCellData('addFeedbackModal')"
            v-if="editable"
            :disabled="transcript == null || transcript.length == 0"
          >
            Save
          </button>
        </div>
      </div>
    </modal>
    <modal
      name="deleteRubricModal"
      height="auto"
      :width="350"
      :scrollable="true"
    >
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">
          Are you sure you want to delete?
        </div>
        <div class="modal-footer justify-content-end">
          <button
            type="button"
            @click="$modal.hide('deleteRubricModal')"
            class="btn medium default"
          >
            Cancel
          </button>
          <button
            type="button"
            @click="deleteRubricRow(), $modal.hide('deleteRubricModal')"
            class="btn medium primary"
          >
            Delete
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import menuSidebar from "../components/menuSidebar.vue";
import { VueEditor } from "vue2-editor";
import headerTitle from "../components/headerTitle.vue";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";

export default {
  name: "manageRubric",
  components: {
    backDashboard,
    userLogout,
    menuSidebar,
    VueEditor,
    headerTitle,
  },
  data() {
    return {
      isBasicInstructor: false,
      loList: [],
      module: {},
      rubric: [
        {
          id: null,
          criteria: null,
          weight: null,
          range_1: null,
          range_2: null,
          range_3: null,
          range_4: null,
          range_5: null,
          type: "",
        },
      ],
      enableManageRuberic: false,
      ModuleSelectedName: "",
      modules_all: [],
      search_learner: "",
      sortOrder: "asc",
      sortColumn: "name",
      lo_index: "",
      page: 1,
      distributeWeight: false,
      totalWeight: 0,
      validate: false,
      colName: "",
      invalidWeight: false,
      transcript: "",
      fullTool: [],
      index: 0,
      key: "rubric_1",
      submit: false,
      rowIndex: "",
      id: "",
      loId: "",
      editable: false,
      loIndex: 0,
      rubricType: "",
      loType: "",
      character_affix: {
        range: 3,
        order: 3,
        active_range: 1,
        active_order: 1,
        intro: "intro_range_1",
        conc: "conc_range_1",
      },
      character_response_affix: {
        intro_range_1: [{ order: "", value: "" }],
        intro_range_2: [{ order: "", value: "" }],
        intro_range_3: [{ order: "", value: "" }],
        conc_range_1: [{ order: "", value: "" }],
        conc_range_2: [{ order: "", value: "" }],
        conc_range_3: [{ order: "", value: "" }],
      },
      rubric_character_response: [],
      active_rubric_character: 1,
      rubric_character: {
        range: 6,
        order: 3,
        active_range: 1,
        active_order: 0,
        rangeTitle: "range_1",
        rangeValue: "0-49",
      },
      interim_resp: {
        1: "",
        2: "",
        3: "",
      },
      activeInterim: 1,
      resubmission_limit: 0,
      selectedLo: null,
    };
  },
  methods: {
    getSelectedModules(index) {
      (this.character_response_affix = {
        intro_range_1: [{ order: "", value: "" }],
        intro_range_2: [{ order: "", value: "" }],
        intro_range_3: [{ order: "", value: "" }],
        conc_range_1: [{ order: "", value: "" }],
        conc_range_2: [{ order: "", value: "" }],
        conc_range_3: [{ order: "", value: "" }],
      }),
        (this.rubric_character_response = []),
        (this.resubmission_limit = 0);

      this.interim_resp = {
        1: "",
        2: "",
        3: "",
      };
      this.totalWeight = 0;
      (this.rubric = [
        {
          id: null,
          criteria: null,
          weight: null,
          range_1: null,
          range_2: null,
          range_3: null,
          range_4: null,
          range_5: null,
          type: "",
        },
      ]),
        api
          .getLOList(this.section.id, this.modules_all[index].id)
          .then((res) => {
            console.log(res.data);
            this.loList = res.data.lo_list.filter(
              (val) =>
                val.attributes.objectable_type === "SubmissionLearnObj" ||
                val.attributes.objectable_type === "SubmissionEmailLearnObj"
            );
            if (!this.loList.length) {
              this.enableManageRuberic = false;
            } else {
              this.enableManageRuberic = true;
            }
            this.getRubricData(
              this.loList[0],
              this.loList[0].attributes.learning_object_id,
              this.loList[0].attributes.editable_by_instructor,
              0,
              this.loList[0].attributes.objectable_type
            );
          })
          .catch(() => {});

      this.ModuleSelectedName = this.modules_all[index].attributes.name;
    },
    getMultiELMList() {
      api
        .getMultiELMList(this.section.id)
        .then((res) => {
          this.modules_all = res.data.data.attributes.learn_mods;
          this.ModuleSelectedName = this.modules_all[0].attributes.name;
        })
        .catch(() => {});
    },
    setDisplayGrade(lo_section_id, togggleData) {
      const fb = new FormData();
      fb.append("learning_object_section[display_grades]", togggleData);
      api
        .updateSectionLo(lo_section_id, fb)
        .then((res) => {
          utilFunctionService.showSuccess(commonConfig.appMessages.changeSaved);
          this.selectedLo.attributes.display_grades = res.data.display_grades;
        })
        .catch(() => {});
    },
    setLearnerHoldOnSubmission(lo_section_id, togggleData) {
      const fb = new FormData();
      fb.append(
        "learning_object_section[learner_hold_on_submission]",
        togggleData
      );
      api
        .updateSectionLo(lo_section_id, fb)
        .then((res) => {
          utilFunctionService.showSuccess(commonConfig.appMessages.changeSaved);
          this.selectedLo.attributes.learner_hold_on_submission =
            res.data.learner_hold_on_submission;
        })
        .catch(() => {});
    },
    next() {
      this.submit = true;
      this.rubric.forEach((row) => {
        this.validate = Object.keys(row).some((key) => {
          return row[key] == null;
        });
      });
      if (
        !this.validate &&
        this.totalWeight < commonConfig.setting.weightMinRange
      ) {
        this.invalidWeight = true;
      }
      if (
        !this.validate &&
        this.totalWeight >= commonConfig.setting.weightMinRange &&
        this.totalWeight <= commonConfig.setting.weightMaxRange
      ) {
        if (this.loList.length - 1 !== this.loIndex) {
          this.getRubricData(
            this.loList[this.loIndex + 1],
            this.loList[this.loIndex + 1].attributes.learning_object_id,
            this.loList[this.loIndex + 1].attributes.editable_by_instructor,
            this.loIndex + 1,
            this.loList[this.loIndex + 1].attributes.objectable_type
          );
        } else {
          utilFunctionService.showSuccess(commonConfig.appMessages.changeSaved);
        }
      }
    },
    getLoList() {
      this.section = JSON.parse(localStorage.getItem("section"));
      api
        .getLearnerDetails(
          this.section.id,
          this.page,
          this.search_learner,
          this.sortColumn,
          this.sortOrder,
          this.lo_index
        )
        .then((res) => {
          this.section_details = res.data.section;
          api
            .getLOList(
              this.section.id,
              this.module.attributes.learn_mods[0].id
            )
            .then((res) => {
              this.loList = res.data.lo_list.filter(
                (val) =>
                  val.attributes.objectable_type === "SubmissionLearnObj" ||
                  val.attributes.objectable_type === "SubmissionEmailLearnObj"
              );
              this.getRubricData(
                this.loList[0],
                this.loList[0].attributes.learning_object_id,
                this.loList[0].attributes.editable_by_instructor,
                0,
                this.loList[0].attributes.objectable_type
              );
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    getRubricData(lo, id, editPermission, index, type) {
      this.selectedLo = lo;
      this.loId = id;
      this.editable = editPermission && !this.isBasicInstructor;
      this.loIndex = index;
      this.loType = type;
      api
        .rubricData(this.module.attributes.customer_course_id, id)
        .then((res) => {
          if (res.data.rubrics.length) {
            this.enableManageRuberic = true;
            this.rubric = res.data.rubrics.map((x) => ({
              id: x.id,
              criteria: x.attributes.criteria,
              weight: x.attributes.weight,
              range_1: x.attributes.range_1,
              range_2: x.attributes.range_2,
              range_3: x.attributes.range_3,
              range_4: x.attributes.range_4,
              range_5: x.attributes.range_5,
              range_6: x.attributes.range_6,
              disabled: false,
              type: x.attributes.gradable_type,
            }));
            this.totalWeight = 0;
            this.rubric.map((x) => {
              if (x.weight != null) {
                this.totalWeight = Number(this.totalWeight) + Number(x.weight);
              }
            });
          }
          if (!res.data.rubrics.length) {
            this.enableManageRuberic = false;
          } else {
            this.enableManageRuberic = true;
          }

          // utilFunctionService.hideLoader();
        })
        .catch(() => {
          // utilFunctionService.hideLoader();
        });
      if (this.loType === "SubmissionEmailLearnObj") {
        this.getCharacterConfig(this.module.attributes.customer_course_id, id);
      }
    },
    saveCellData(modal) {
      if (
        this.transcript != null &&
        this.transcript.length > commonConfig.setting.default
      ) {
        this.rubric[this.index][this.key] = this.transcript;
        if (this.id != null) {
          const fb = new FormData();
          fb.append(`rubric[${this.key}]`, this.transcript);
          if (this.rubric[0].type !== "CustomerCourseLo") {
            fb.append(`rubric[parent_id]`, this.id);
          }
          api
            .editRubric(
              this.module.attributes.customer_course_id,
              this.id,
              this.loId,
              fb
            )
            .then(() => {
              if (this.rubric[0].type !== "CustomerCourseLo") {
                this.getRubricData(
                  this.selectedLo,
                  this.loId,
                  this.editable,
                  this.loIndex,
                  this.loType
                );
              }
            });
        } else {
          const fb = new FormData();
          fb.append(`rubric[${this.key}]`, this.transcript);
          api
            .createRubric(
              fb,
              this.module.attributes.customer_course_id,
              this.loId
            )
            .then((x) => {
              this.rubric[this.index]["id"] = x.data.data.id;
              if (this.rubric[0].type !== "CustomerCourseLo") {
                this.getRubricData(
                  this.selectedLo,
                  this.loId,
                  this.editable,
                  this.loIndex,
                  this.loType
                );
              }
            });
        }
        this.totalWeight = 0;
        this.rubric.map((x) => {
          if (x.weight != null) {
            this.totalWeight = Number(this.totalWeight) + Number(x.weight);
          }
        });
        if (
          this.totalWeight >= commonConfig.setting.weightMinRange &&
          this.totalWeight <= commonConfig.setting.weightMaxRange
        ) {
          this.invalidWeight = false;
        }
        this.hide(modal);
      }
    },
    deleteRubric(i, id, rubricType) {
      this.rowIndex = i;
      this.id = id;
      this.rubricType = rubricType;
    },
    deleteRubricRow() {
      if (this.id == null) {
        this.rubric.splice(this.rowIndex, 1);
      } else {
        if (this.rubric[0].type === "CustomerCourseLo") {
          api
            .removeRubric(
              this.module.attributes.customer_course_id,
              this.id,
              this.loId
            )
            .then(() => {
              this.rubric.splice(this.rowIndex, 1);
              utilFunctionService.showSuccess(
                commonConfig.appMessages.deleteRubric
              );
              if (this.distributeWeight) {
                this.distributeWeightInRows();
              }
            });
        } else {
          api
            .removeEmailTypeRubric(
              this.module.attributes.customer_course_id,
              this.id,
              this.loId
            )
            .then(() => {
              this.rubric.splice(this.rowIndex, 1);
              this.getRubricData(
                this.selectedLo,
                this.loId,
                this.editable,
                this.loIndex,
                this.loType
              );
              utilFunctionService.showSuccess(
                commonConfig.appMessages.deleteRubric
              );
              if (this.distributeWeight) {
                this.distributeWeightInRows();
              }
            });
        }
      }
    },
    distributeWeightInRows() {
      this.totalWeight = 0;
      const number = commonConfig.setting.weightMaxRange / this.rubric.length;
      let upTwoDecimalpoints = number
        .toString()
        .match(/^-?\d+(?:\.\d{0,2})?/)[0];
      this.rubric.map((x, i) => {
        this.rubric[i].weight = upTwoDecimalpoints;
      });
      this.rubric.map((x) => {
        if (x.weight != null) {
          this.totalWeight = Number(this.totalWeight) + Number(x.weight);
        }
      });
      if (
        this.totalWeight >= commonConfig.setting.weightMinRange &&
        this.totalWeight <= commonConfig.setting.weightMaxRange
      ) {
        this.invalidWeight = false;
      }
    },
    addCriteria() {
      this.submit = true;
      this.rubric.forEach((row) => {
        this.validate = Object.keys(row).some((k) => {
          return row[k] == null;
        });
      });
      if (!this.validate) {
        this.rubric.push({
          id: null,
          criteria: null,
          weight: null,
          range_1: null,
          range_2: null,
          range_3: null,
          range_4: null,
          range_5: null,
          disabled: false,
        });
        this.show(
          "addCriteriaWeightModal",
          this.rubric.length - 1,
          "criteria",
          "",
          null,
          "Criteria"
        );
        if (this.distributeWeight) {
          this.distributeWeightInRows();
        }
      }
    },
    getTotalWeights() {
      return this.totalWeight.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    },
    show(modal, index, key, value, id, name) {
      this.index = index;
      this.key = key;
      this.transcript = value;
      this.id = id;
      this.colName = name;
      this.$modal.show(modal);
    },
    hide(modal) {
      this.transcript = "";
      this.$modal.hide(modal);
    },
    getCharacterConfig() {
      api
        .characterConfig(this.module.attributes.customer_course_id, this.loId)
        .then((res) => {
          this.character_response_affix = res.data.char_res_affix.attributes;
          this.rubric_character_response = res.data.char_res;
          this.resubmission_limit =
            res.data.resubmission_limit == null
              ? 0
              : res.data.resubmission_limit;
          if (this.rubric_character_response.length) {
            this.rubric_character_response.forEach((ele) => {
              for (const [key, value] of Object.entries(ele.attributes)) {
                if (value.length === 0) {
                  ele.attributes[key].push({ order: 1, value: "" });
                  ele.attributes[key].push({ order: 2, value: "" });
                  ele.attributes[key].push({ order: 3, value: "" });
                }
              }
            });
          }
          this.interim_resp = res.data.interim_resp;
        })
        .catch(() => {});
    },
    setActiveRange(range, order) {
      this.character_affix.active_range = range;
      this.character_affix.active_order = order;
      this.character_affix.intro = "intro_range_" + range;
      this.character_affix.conc = "conc_range_" + range;
    },
    setActiveRubric(range, order, rangeValue) {
      this.rubric_character.active_range = range;
      this.rubric_character.active_order = order;
      this.rubric_character.rangeTitle = "range_" + range;
      this.rubric_character.rangeValue = rangeValue;
    },
    showModel(modal, index, key, value, id, name) {
      this.index = index;
      this.key = key;
      this.transcript = value;
      this.id = id;
      this.colName = name;
      this.$modal.show(modal);
    },
    setAndSaveInterim(activeInterim, data) {
      if (data) {
        this.activeInterim = activeInterim;
        this.interim_resp[1] = data[1] ? data[1] : "";
        this.interim_resp[2] = data[2] ? data[2] : "";
        this.interim_resp[3] = data[3] ? data[3] : "";
      } else {
        this.activeInterim = activeInterim;
        const fd = new FormData();
        fd.append("learning_object_id", this.loId);
        fd.append("interim_resp", JSON.stringify(this.interim_resp));
        api
          .updateInterimResp(this.module.attributes.customer_course_id, fd)
          .then(() => {})
          .catch(() => {});
      }
    },
    saveConfig() {
      this.setAndSaveInterim(this.activeInterim);
      utilFunctionService.showSuccess(
        commonConfig.appMessages.saveInterimResponse
      );
      if (this.loList.length - 1 !== this.loIndex) {
        this.getRubricData(
          this.loList[this.loIndex + 1],
          this.loList[this.loIndex + 1].attributes.learning_object_id,
          this.loList[this.loIndex + 1].attributes.editable_by_instructor,
          this.loIndex + 1,
          this.loList[this.loIndex + 1].attributes.objectable_type
        );
      }
    },
    distributeWeightEqually() {
      this.distributeWeight = true;
      if (this.distributeWeight) {
        this.distributeWeightInRows();
      }
      const data = new FormData();
      data.append("distribute_weight_eq", this.distributeWeight);
      if (this.rubric[0].type === "CustomerCourseLo") {
        api
          .distributeWeight(
            this.module.attributes.customer_course_id,
            this.loId,
            data
          )
          .then(() => {});
      } else {
        api
          .distributeWeightEqually(
            this.module.attributes.customer_course_id,
            this.loId,
            data,
            this.id
          )
          .then(() => {});
        setTimeout(() => {
          this.getRubricData(
            this.selectedLo,
            this.loId,
            this.editable,
            this.loIndex,
            this.loType
          );
        }, 1000);
      }
    },
    setInstructorPermissions() {
      this.instructorInfo = JSON.parse(
        localStorage.getItem("instructor")
      ).users;
      let role = this.instructorInfo.attributes.user_roles[0];
      if (role == "instructor_basic") {
        this.isBasicInstructor = true;
      }
    },
  },
  created() {
    this.section = JSON.parse(localStorage.getItem("section"));
    this.module = this.section;
    this.getLoList();
    this.setInstructorPermissions();
    this.getMultiELMList();
  },
};
</script>

<style lang="scss">
@import "../styles/mixin.scss";

.notice-padding {
  padding: 5em 4em;
}

.active button {
  background: none;
  border: 1px solid #3dbc9e;
  border-radius: 8px;
  padding: 16px;
}

.active button:hover {
  color: #3dbc9e;
}
</style>