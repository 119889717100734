<template>
  <div class="sidebar">
    <div class="logo">
      <router-link to="/active">
        <img
          src="../assets/images/ametros-learning-logo.png"
          alt="ametros logo"
        />
      </router-link>
    </div>
    <div class="navbar">
      <ul>
        <li v-bind:class="[currentPage('/active') ? 'active' : '']">
          <router-link to="/active">
            Active
            <span>Sections</span>
          </router-link>
        </li>
        <li v-bind:class="[currentPage('/completed') ? 'active' : '']">
          <router-link to="/completed">
            Closed
            <span>Sections</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="navbar">
      <ul>
        <li class="navbar-item">
          <a class="navbar-support">
            <em class="icon-headphones"></em>
            <router-link to="/contact"> Support </router-link>
          </a>
          <div
            @click="logOut()"
            class="signOut"
          >
            Sign out
          </div>
        </li>
      </ul>
    </div>
    <div class="footer">
      <router-link to="/profile">
        <div class="footer-image">
          <img
            :src="profile.attachment"
            alt="instructor profile"
            v-if="profile.attachment"
          />
        </div>
      </router-link>
      <div class="footer-content">
        <div class="footer-title">{{ profile.first_name }}</div>
        <p>{{ profile.email }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";

export default {
  name: "dashboardSidebar",
  props: {
    instructorInfo: Object,
    profile: Object,
  },
  methods: {
    currentPage(route) {
      return this.$route.path === route ? true : false;
    },
    logOut() {
      utilFunctionService.removeLocalStorageService("instructor");
      utilFunctionService.removeLocalStorageService("profile_url");
      utilFunctionService.removeLocalStorageService("learnerSubmission");
      utilFunctionService.removeLocalStorageService("section");
      utilFunctionService.removeLocalStorageService("profile");
      utilFunctionService.removeLocalStorageService("module");
      utilFunctionService.removeLocalStorageService("vuex");
      utilFunctionService.removeLocalStorageService("sso_authenticated");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.sidebar {
  .signOut {
    color: $input-error-color;
    @include font-regular;
    cursor: pointer;
    text-decoration: underline;
    @include media-breakpoint(sm) {
      margin: 0 0 0 10px;
    }
    @include media-breakpoint(md) {
      @include position(fixed, null, null, 30px, 20px);
    }
    @include media-breakpoint(xl) {
      @include position(null, null, null, 30px, 40px);
    }
  }
  @include position(fixed, 0, 0, 0, 0);
  width: 100%;
  z-index: 200;
  @include flexProperty(space-between, null, column);
  padding: 20px;
  background-color: $white;
  max-height: 100%;
  @include media-breakpoint(sm) {
    @include flexProperty(flex-start, null, row, wrap);
    max-height: inherit;
    bottom: auto;
  }
  @include media-breakpoint(md) {
    width: 180px;
  }
  @include media-breakpoint(xl) {
    width: 230px;
    padding: 50px 10px 0 40px;
  }
  .logo {
    width: 160px;
    @include media-breakpoint(sm) {
      width: 48px;
      overflow: hidden;
      flex: none;
    }
    a {
      display: block;
      flex: none;
      img {
        @include media-breakpoint(sm) {
          width: 170px;
          flex: none;
          max-width: inherit;
        }
      }
    }
  }

  .navbar {
    @include font-bold;
    margin: auto 0 16px 0;
    padding: 0;
    font-size: 14px;
    @include media-breakpoint(sm) {
      width: 100%;
      font-size: 16px;
    }
    
    ul {
      margin: 0;
      list-style: none;
      @include media-breakpoint(sm) {
        @include flexProperty;
        width: 100%;
      }
    }
    li {
      margin: 0 0 30px;
      @include media-breakpoint(sm) {
        padding: 0 45px 0 0;
        margin: 20px 0 0;
        span {
          display: none;
        }
      }
      &.navbar-item {
        margin: 40px 0 0;
        @include media-breakpoint(sm) {
          @include position(fixed, 0, 0);
          @include flexProperty(null, center);
          margin: 0;
          padding: 20px;
          width: auto;
        }
      }
    }
    a {
      color: $list-color;
      cursor: pointer;
      &:hover {
        color: $black;
      }
    }
    .navbar-support {
      @include font-regular;
      font-size: 15px;
      border-radius: 5px;
      display: inline-block;
      border: solid 1px $primary-button-color;
      background-color: $light-button-color;
      padding: 6px 14px;
      color: $primary-button-color;
      em {
        margin: 3px 5px 0 0;
        font-size: 14px;
      }
      &:hover {
        color: $primary-button-color;
      }
    }
    .active {
      a {
        color: $black;
      }
    }
  }
  .footer {
    padding: 20px 0 60px;
    @include media-breakpoint(sm) {
      @include flexProperty(null, flex-end);
      @include position(fixed, null, null, 0, 0);
      padding: 10px 10px 20px 20px;
      background: $white;
      width: 100%;
      height: 76px;
      box-shadow: rgba(0, 0, 0, 0.07) 0 0 20px;
    }
    .footer-image {
      width: 39px;
      height: 39px;
      cursor: pointer;
      overflow: hidden;
      flex: none;
      border-radius: 100%;
      background: url("../assets/images/image-placeholder.svg") no-repeat;
      background-position: top;
      background-size: cover;
      img {
        @include imageSize;
      }
    }
    .footer-content {
      position: relative;
      @include media-breakpoint(sm) {
        flex: 1;
        margin: 0 0 0 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .footer-title {
      @include font-black;
      font-size: 20px;
      margin: 20px 0 0;
      line-height: 100%;
      @include media-breakpoint(sm) {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .footer-subtitle {
      @include font-medium;
      font-size: 14px;
      line-height: 1.21;
      color: $list-color;
      @include media-breakpoint(sm) {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    p {
      font-size: 14px;
      color: #606060;
      white-space: nowrap;
      max-width: 230px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
