var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-full"},[_c('div',{staticClass:"top-header"},[_c('backDashboard'),_c('headerTitle',{attrs:{"moduleDetails":_vm.section}}),_c('userLogout')],1),_c('div',{staticClass:"site-content"},[_c('menuSidebar',{attrs:{"moduleID":this.modules.id}}),_c('div',{staticClass:"lo-list"},_vm._l((_vm.loList),function(lo,index){return _c('div',{key:lo.id,staticClass:"lo-list-item",class:[lo.attributes.learning_object_id == _vm.loId ? 'active' : '']},[_c('button',{on:{"click":function($event){return _vm.getLoPlagiarismData(
                  lo,
                  lo.attributes.learning_object_id,
                  lo.attributes.editable_by_instructor,
                  index,
                  lo.attributes.objectable_type
                )}}},[_c('span',[_vm._v(_vm._s(lo.attributes.learning_object_order)+".")]),_vm._v(" "+_vm._s(lo.attributes.learning_object_name)+" ")])])}),0),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"main-title"},[_c('h1',[_vm._v("Plagiarism Detection")]),_c('div',{staticClass:"button-right row"},[_c('div',{staticClass:"main-searchForms"},[(_vm.modules_all.length > 1)?_c('div',{staticClass:"search-dropdown"},[_c('button',{staticClass:"btn btn-secondary dropdown-toggle",attrs:{"type":"button","id":"dropdownMenu1","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(_vm._s(_vm.ModuleSelectedName))]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenu1"}},[_c('a',{staticClass:"dropdown-item",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleSort(_vm.sortColumn,_vm.sortOrder,'')}}}),_vm._l((_vm.modules_all),function(mods,index){return _c('a',{key:index,staticClass:"dropdown-item",attrs:{"type":"button"},on:{"click":function($event){return _vm.getSelectedModules(index)}}},[_vm._v(_vm._s(mods.attributes.name))])})],2)]):_vm._e()])])]),_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"lo-view"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"learner-list"},[_c('div',{staticClass:"data-table column-9"},[_c('div',{staticClass:"data-head"},[_c('div',{staticClass:"data-col"},[_vm._v(" Learner "),_c('span',{staticClass:"sorting"},[_c('a',{class:[
                        'desc',
                        _vm.sortColumn === 'name' && _vm.sortOrder === 'asc'
                          ? 'active'
                          : '',
                      ]}),_c('a',{class:[
                        'asc',
                        _vm.sortColumn === 'name' && _vm.sortOrder === 'desc'
                          ? 'active'
                          : '',
                      ]})])]),_c('div',{staticClass:"data-col"},[_vm._v(" Matches this learner's work "),_c('span',{staticClass:"sorting"},[_c('a',{class:[
                        'desc',
                        _vm.sortColumn === 'name' && _vm.sortOrder === 'asc'
                          ? 'active'
                          : '',
                      ]}),_c('a',{class:[
                        'asc',
                        _vm.sortColumn === 'name' && _vm.sortOrder === 'desc'
                          ? 'active'
                          : '',
                      ]})])]),_c('div',{staticClass:"data-col"},[_vm._v(" Percentage Match "),_c('span',{staticClass:"sorting"},[_c('a',{class:[
                        'desc',
                        _vm.sortColumn === 'name' && _vm.sortOrder === 'asc'
                          ? 'active'
                          : '',
                      ]}),_c('a',{class:[
                        'asc',
                        _vm.sortColumn === 'name' && _vm.sortOrder === 'desc'
                          ? 'active'
                          : '',
                      ]})])]),_c('div',{staticClass:"data-col"},[_vm._v(" Reviewed "),_c('span',{staticClass:"sorting"},[_c('a',{class:[
                        'desc',
                        _vm.sortColumn === 'name' && _vm.sortOrder === 'asc'
                          ? 'active'
                          : '',
                      ]}),_c('a',{class:[
                        'asc',
                        _vm.sortColumn === 'name' && _vm.sortOrder === 'desc'
                          ? 'active'
                          : '',
                      ]})])])]),_vm._m(2)])])])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lo-view__title"},[_c('h2',[_vm._v("LO 4: A dialogic LO let's say")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lo-view__settings"},[_c('div',{staticClass:"enable-plagiarism"},[_c('input',{attrs:{"type":"checkbox"}}),_c('p',[_vm._v("Enable plagiarism detection for this learning object")])]),_c('div',{staticClass:"plagiarism-range"},[_c('h3',[_vm._v("Strictness:")]),_c('div',{staticClass:"plagiarism-range__container"},[_c('label',[_vm._v("Less Strict")]),_c('input',{attrs:{"type":"range","min":"0","max":"100"}}),_c('label',[_vm._v("More Strict")])])]),_c('div',{staticClass:"plagiarism-instances"},[_c('h4',[_vm._v("Plagiarism instances detected: 6")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-content"},[_c('div',{staticClass:"data-row"},[_c('div',{staticClass:"data-col"},[_vm._v("Abernathy, Swain")]),_c('div',{staticClass:"data-col"},[_vm._v("Teleos, Bordan")]),_c('div',{staticClass:"data-col"},[_vm._v("99%")]),_c('div',{staticClass:"data-col"},[_vm._v("Yes")])]),_c('div',{staticClass:"data-row"},[_c('div',{staticClass:"data-col"},[_vm._v("Abernathy, Swain")]),_c('div',{staticClass:"data-col"},[_vm._v("Teleos, Bordan")]),_c('div',{staticClass:"data-col"},[_vm._v("99%")]),_c('div',{staticClass:"data-col"},[_vm._v("Yes")])]),_c('div',{staticClass:"data-row"},[_c('div',{staticClass:"data-col"},[_vm._v("Abernathy, Swain")]),_c('div',{staticClass:"data-col"},[_vm._v("Teleos, Bordan")]),_c('div',{staticClass:"data-col"},[_vm._v("99%")]),_c('div',{staticClass:"data-col"},[_vm._v("Yes")])]),_c('div',{staticClass:"data-row"},[_c('div',{staticClass:"data-col"},[_vm._v("Abernathy, Swain")]),_c('div',{staticClass:"data-col"},[_vm._v("Teleos, Bordan")]),_c('div',{staticClass:"data-col"},[_vm._v("99%")]),_c('div',{staticClass:"data-col"},[_vm._v("Yes")])]),_c('div',{staticClass:"data-row"},[_c('div',{staticClass:"data-col"},[_vm._v("Abernathy, Swain")]),_c('div',{staticClass:"data-col"},[_vm._v("Teleos, Bordan")]),_c('div',{staticClass:"data-col"},[_vm._v("99%")]),_c('div',{staticClass:"data-col"},[_vm._v("Yes")])])])
}]

export { render, staticRenderFns }