<template>
  <modal
    name="feedbackResult"
    class="modal-right"
    width="100%"
    height="auto"
    :scrollable="true"
  >
    <div class="modal-content modal-grade-content">
      <button
        type="button"
        class="modal-close"
        @click="$modal.hide('feedbackResult')"
      >
        <em class="icon-close"></em>
      </button>
      <div class="modal-body pt-2">
        <div class="modal-title">
          <h3>Feedback</h3>
          <div class="modal-grade">
            <p>Overall: {{ Math.round(previewData.overall_grade) }}%</p>
          </div>
        </div>
        <div class="mt-4" v-if="feedback">
          <p v-html="feedback"></p>
        </div>
        <div class="modal-grade-items">
          <div
            class="modal-grade-row"
            v-for="data in previewData.data"
            :key="data.id"
          >
            <div class="modal-grade-icon">
              <h4>{{ data.attributes.score }}</h4>
              <span>Score</span>
            </div>
            <div>
              <div class="modal-grade-head">
                <h4>{{ data.attributes.rubric_criteria }}</h4>
                <button
                  v-if="data.attributes.rubric_criteria_desc"
                  :content="data.attributes.rubric_criteria_desc"
                  v-tippy="{ trigger: 'click', arrow: true }"
                  class="info-icon no-style-btn"
                >
                  i
                </button>
                <span>(Weight {{ data.attributes.rubric_weight }}%)</span>
              </div>
              <p v-html="data.attributes.rubric_feedback">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
    name: "FeedbackResultModal",
    props: ["previewData", "feedback"]
}
</script>
