export const commonConfig = {

    routerName: {
      login: '/login',
    },
    httpCodes: {
      unauthorize: 401,
      badReqestError: 400,
      notFound: 404,
      forbidden: 403,
      badRequestError: 400,
      ok: 200,
      internalServerError: 500,
      status: 'ok',
      code: 422,
      badGateway: 502
    },
    apiName: {
      login: '/sign_in',
      logout: '/sign_out'
    },
    baseUrl: {
      user: 'users'
    },
    appMessages: {
      loginSuccess: 'You are successfully logged in',
      logoutSuccess: 'Signout successfully',
      messageSetting: 'Message setting has been updated successfully',
      deleteRubric: 'Rubric deleted successfully',
      deleteRecord : 'Milestone dates has been removed successfully',
      saveRecord : 'Milestone dates has been saved successfully',
      saveInterimResponse: 'Interim response saved successfully',
      sameLoRecord : 'Duplicate learning object can not be saved',
      addLoRecord : 'Add atleast one lo record',
      updateProfile: 'Profile has been updated successfully',
      inCorrectPassword: 'Old password is incorrect',
      addNote: 'Note has been added successfully',
      removeNote: 'Note has been removed successfully',
      removeImage : 'Profile image has been removed successfully',
      submissionDraft : 'Submission has been drafted successfully',
      submissionpublished : 'Submission has been published successfully',
      feedbackDraftSuccessfully: 'Feedback has been drafted successfully. This window will close automatically in 5 seconds.',
      feedbackPublishedSuccessfully: 'Feedback has been published successfully. This window will close automatically in 5 seconds.',
      feedbackSendSuccessfully: 'Feedback has been send successfully',
      changeSaved: 'Changes has been saved successfully',
      contactSuccess: 'Thank You! Your message has been successfully sent',
      studentCSVExportSuccessful: 'Section CSV has been exported',
      studentCSVExportFail: 'A problem has occurred with exporting the section CSV'
    },
    setting :{
      default: 0,
      searchPage : 1,
      weightMinRange: 99.96,
      weightMaxRange: 100
    }
  };
