<template>
  <div class="dashboard-container">
    <dashboardSidebar :instructorInfo="instructorInfo" :profile="profile" />
    <div class="container">
      <div
        :class="[modules.length > 0 ? '' : 'dashboard-no-data', 'dashboard']"
      >
        <div class="dashboard-active-content">
          <div class="dashboard-active-head">
            <div class="d-flex justify-content-between w-100">
              <h2>{{type | upperCase(true)}} Sections</h2>
              <div class="dashboard-active-info">
                <button
                  class="btn reset_btn"
                  @click="resetSearch()"
                  v-if="searching"
                >
                  Reset search
                </button>
                <div
                  class="form-group"
                  v-if="profile.roles.includes('admin-instructor')"
                >
                  <input
                    type="text"
                    class="form-control course-search"
                    placeholder="Search by course name"
                    v-model="searchQuery"
                  />
                  <em @click="searchModules()" class="icon-search"></em>
                </div>
                <h3 v-if="sectionsLength > 0 && sectionsLength < 10 && display">
                  0{{ sectionsLength }}
                </h3>
                <h3
                  v-else-if="
                    sectionsLength > 0 && sectionsLength >= 10 && display
                  "
                >
                  {{ sectionsLength }}
                </h3>
              </div>
            </div>
          </div>
          <div class="dashboard-active-list">
            <div
              class="dashboard-no-text"
              v-if="modules.length === 0 && display"
            >
              <p v-if="type === 'active' || type === 'completed'">
                You don't have any {{ type }} sections.
              </p>
              <p v-else>You don't have any {{ type }} sections</p>
            </div>
            <div v-if="modules.length > 0 && display">
              <VueSlickCarousel
                ref="carousel"
                @beforeChange="checkNext()"
                v-bind="settings"
                class="dashboard-carousel"
                :key="carouselKey"
              >
                <div
                  v-for="item in modules"
                  :key="item.id"
                >
                  <router-link
                    :to="`/learnerCentral/${item.id}`"
                    class="dashboard-active-item"
                    @click="setModuleData(item)"
                  >
                    <div
                      class="dashboard-active-item"
                      v-if="
                        item && item.attributes && item.attributes.learn_mods
                      "
                    >
                      <div class="dashboard-active-img" @click="setModuleData(item)">
                        <img
                          :src="
                            item.attributes.learn_mods[0].attributes.photo_url
                              .large_version
                          "
                          v-if="
                            item.attributes.learn_mods[0].attributes.photo_url
                              .large_version
                          "
                          alt="image"
                        />
                      </div>
                      <div class="dashboard-active-content" @click="setModuleData(item)">
                        <div class="dashboard-active-title">
                          {{ item.attributes.name }}
                        </div>
                        <div class="dashboard-calender" v-if="item.attributes.time_started">
                          <em class="icon-calender"></em>
                          {{ item.attributes.time_started }} -
                          {{ item.attributes.time_completed }}
                        </div>
                        <ul>
                          <li>
                            Learners:
                            <strong>{{
                              item.attributes.user_sections_count
                            }}</strong>
                          </li>
                          <li>
                            Completed:
                            <strong>{{
                              item.attributes.elm_completed_count
                            }}</strong>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Modules:
                            <strong>{{item.attributes.elm_count}}</strong>
                          </li>
                        </ul>
                      </div>
                      <div class="dashboard-badge">
                        <div
                          class="dashboard-badge-item"
                          v-if="item.attributes.message_notifications"
                        >
                          <img src="../assets/images/messages-white.svg" />{{
                            item.attributes.message_notifications
                          }}
                        </div>
                        <div
                          class="dashboard-badge-item"
                          v-if="item.attributes.submission_notifications > 0"
                        >
                          <img src="../assets/images/submission-white.svg" />{{
                            item.attributes.submission_notifications
                          }}
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <template #prevArrow>
                  <div class="custom-arrow"></div>
                </template>
                <template #nextArrow>
                  <div class="custom-arrow" @click="checkNext()"></div>
                </template>
              </VueSlickCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import dashboardSidebar from "../components/dashboardSidebar.vue";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "dashboard-component",
  components: {
    dashboardSidebar,
    VueSlickCarousel,
  },
  filters: {
    upperCase(value, firstCharacter) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (firstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    },
  },
  data() {
    return {
      modules: [],
      type: "active",
      display: false,
      searchQuery: "",
      searching: false,
      renderKey: 0,
      profile: {
        roles: "",
      },
      instructorInfo: {},
      pageNum: 1,
      listPos: 0,
      sectionsLength: 1,
      carouselKey: 1,
      currentSlide: 0,
      settings: {
        initialSlide: 0,
        slidesToShow: 3,
        arrows: true,
        dots: false,
        speed: 200,
        infinite: false,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 320,
            settings: "unslick",
          },
        ],
      },
    };
  },
  methods: {
    // fetch lists
    getList(type) {
      utilFunctionService.showLoader();
      this.display = false;
      this.pageNum = 1;
      this.modules = [];
      api
        .getList(type, this.pageNum)
        .then((res) => {
          utilFunctionService.hideLoader();
          this.modules = res.data.data.data;
          this.sectionsLength = res.data.sectionLength;
          this.display = true;
          console.log(res, "*** res getList");
        })
        .catch((err) => {
          console.log(err);
          localStorage.clear();
        });
    },
    setModuleData(item) {
      console.log("setting module data")
      console.log(item, "item");
      utilFunctionService.setLocalStorageService("section", JSON.stringify(item));
      utilFunctionService.setLocalStorageService("module", JSON.stringify(item));
    },
    getNextList(type) {
      this.pageNum += 1;
      api
        .getList(type, this.pageNum)
        .then((res) => {
          let newModules = res.data.data.data;
          newModules.forEach((mod) => {
            this.modules.push(mod);
          });
          this.settings.initialSlide = this.currentSlide + 1;
          this.carouselKey += 1;
          console.log(res, "*** res getList");
        })
        .catch(() => {});
    },
    getProfile() {
      api
        .getInstructorProfile(this.instructorInfo.id)
        .then((res) => {
          this.profile = res.data.data.attributes;
          utilFunctionService.setLocalStorageService(
            "profile",
            JSON.stringify(this.profile)
          );
          utilFunctionService.setLocalStorageService(
            "profile_url",
            JSON.stringify(this.profile.attachment)
          );
          utilFunctionService.setLocalStorageService(
            "sso_authenticated",
            JSON.stringify(this.profile.sso_authenticated)
          );
        })
        .catch(() => {});
    },
    checkNext() {
      this.currentSlide = this.$refs.carousel.$refs.innerSlider.currentSlide;
      if (
        this.currentSlide === this.modules.length - 10 &&
        this.modules.length >= 15
      ) {
        if (this.searching) {
          this.getNextSearchModules();
        } else {
          this.getNextList(this.type);
        }
      }
    },
    searchModules() {
      this.display = false;
      utilFunctionService.showLoader();
      this.pageNum = 1;
      api.searchModules(this.searchQuery, this.type, this.pageNum).then((res) => {
        utilFunctionService.hideLoader();
        this.searching = true;
        this.modules = [];
        let searchModules = res.data.data.data;
        searchModules.forEach((mod) => {
          this.modules.push(mod);
        });
        this.sectionsLength = res.data.sectionLength
        this.carouselKey += 1;
        this.display = true;
      });
    },
    getNextSearchModules() {
      this.pageNum += 1;
      api.searchModules(this.searchQuery, this.type, this.pageNum).then((res) => {
        let searchModules = res.data.data.data;
        searchModules.forEach((mod) => {
          this.modules.push(mod);
        });
        this.settings.initialSlide = this.currentSlide + 1;
        this.sectionsLength = res.data.sectionLength
        this.carouselKey += 1;
      });
    },
    resetSearch() {
      this.searching = false;
      this.searchQuery = "";
      this.getList(this.type);
    },
  },
  created() {
    utilFunctionService.removeLocalStorageService("section");
    this.display = false;
    this.type = this.$route.path.substr(1);
    this.instructorInfo = JSON.parse(localStorage.getItem("instructor")).users;
    this.getList(this.type);
    this.getProfile();
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.modules = [];
        this.searching = false;
        this.searchQuery = "";
        this.settings.initialSlide = 0;
        this.type = this.$route.path.substr(1);
        this.getList(this.type);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.custom-arrow {
  width: 52px;
  height: 52px;
}

.slick-prev {
  top: auto;
  transform: none;
  bottom: -60px;
  right: 100px;
  left: auto;
  background: transparent url("../assets/images/slide-play.svg") no-repeat;
}

.slick-prev:before {
  opacity: 0;
}

.slick-next {
  top: auto;
  transform: rotate(180deg);
  bottom: -60px;
  right: 15px;
  background: transparent url("../assets/images/slide-play.svg") no-repeat;
}

.slick-next:before {
  opacity: 0;
}

.icon-search:hover {
  font-weight: bold;
  cursor: pointer;
}

.reset_btn {
  transition: .5s all ease-in-out;
  margin-right: 16px;
  background: rgb(250, 250, 250);
}

.reset_btn:hover {
  background: rgb(240, 240, 240);
}
</style>

