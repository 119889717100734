<template>
  <div class="main-content">

    <div class="selection-container">
      
      <select id="moduleselect" class="custom-select" v-if="(this.modules.length > 1)" v-model="selected_module"  @change="selectOption($event)">
        <option disabled value="">Select a module</option>
        <option v-for="module in modules" :key="module.id">{{ module.attributes.name }}</option>
      </select>
      
    </div>

    <div class="summary-container">
      <h2>Summary</h2>
      <div class="summary">
        <p>The report below shows assessment information for learners enrolled in this section. Assessments refer to the feedback that learners receive on the debrief of interaction learning objects. </p>
        <p><span>Total learners in this section: </span>{{ total_learners }}</p>
        <p><span>Reattempts enabled: </span>{{ reattempts_enabled ? "Yes" : "No" }}</p>
        <div v-if="(this.modules.length < 2)">
          <p><span>Module: </span>{{ modules[0].attributes.name }}</p>
        </div>
      </div>


    </div>

    <div class="aggregate-container">
      <h2>Aggregate Data</h2>
      <div class="details-header">
        
          <div class="export-csv-container">
            <button class="btn" @click="downloadAggregateTableData()">Download Aggregate Data</button>
            <!--<button class="settings-btn">CSV export settings</button>-->
          </div>
      </div>
      <div ref="aggregateTable" id="aggregate-data-table"></div>
    </div>

    <div class="details-container">
      <h2>Learner Details</h2>
      <div class="details-header">
        <input class="search-input" type="text" placeholder="Search learners" v-model="searchQuery" @keyup="searchLearner(searchQuery)"/>
          <div class="export-csv-container">
            <button class="btn" @click="downloadTableData()">Download Learner Data</button>
            <!--<button class="settings-btn">CSV export settings</button>-->
          </div>
      </div>
      <div ref="assessmentTable" id="main-data-table"></div>
    </div>
  </div>
</template>
<script>
import api from "../services/api";
import moment from "moment";
import { utilFunctionService } from "@/utils/utils.service";
import Tabulator from "tabulator-tables";
import XLSX from "xlsx";
window.XLSX = XLSX;

export default {
  name: "Assessment-Reporting",
  props: ["section_data"],
  components: {
  
  },
  data() {
    return {
      avg_grades: [],
      options: {
        autoColumns:true,
      },
      date: new Date(),
      course_title: "",
      course_start: "",
      course_end: "",
      searchQuery: "",
      section: "",
      total_learners: '',
      total_learning_objs: '',
      reattempts_disabled: '',
      reattempts_enabled: '',
      modules: [],
      selected_module: '',
      learners: [],
      users: [],
      lo_table_headers: [],
      columns_data: [],
      aggregate_columns_data: [],
      aggregate_assessments: [],
      tabulator: null, 
      tabulator_aggregate: null 
    };
  },
  beforeMount() {
    this.section = JSON.parse(localStorage.getItem("section"));
    this.modules = this.section.attributes.learn_mods;
    if (!this.selected_module || this.selected_module !== this.modules[0].attributes.name) { 
      this.selected_module = this.modules[0].attributes.name;
      this.getLearnerDetails(this.section.id, this.modules[0].id);
    }
  },
  methods: {
    downloadTableData() {
    let spreadsheetName = this.section.attributes.name + " Assessment Report Export.xlsx";
      this.tabulator.download("xlsx", spreadsheetName);
    },
    downloadAggregateTableData() {
    let spreadsheetName = this.section.attributes.name + " Aggregate Assessment Report Export.xlsx";
      this.tabulator_aggregate.download("xlsx", spreadsheetName);
    },
    searchLearner(learnerQuery) {
      this.tabulator.setFilter("learner_name", "like", learnerQuery);
    },
    selectOption(event) {
      this.selected_module = event.target.value;
      let module_id = this.modules.filter(function(elem) {
        if (elem.attributes.name === event.target.value) return elem;
      });
      if (module_id.length > 0) {
        var learn_mod_id = module_id[0].id;
      }
      this.getLearnerDetails(this.section.id, learn_mod_id);
    },
    getLearnerDetails(section_id, module_id) {
      utilFunctionService.showLoader();
      api.getAssessmentReportData(section_id, module_id).then((res) => {
        this.course_title = res.data.data.attributes.course_name;
        console.log(res.data, "*** res.data in getLearnerDetails");
        if (res.data.data.attributes.course_start_date) {
          this.course_start = moment(res.data.data.attributes.course_start_date).format("dddd, MMMM Do YYYY");
        } else {
          this.course_start = "N/A"
        }
        if (res.data.data.attributes.course_end_date) {
          this.course_end = moment(res.data.data.attributes.course_end_date).format("dddd, MMMM Do YYYY");
        } else {
          this.course_end = "N/A"
        }

        this.aggregate_assessments = res.data.data.attributes.aggregate_assessments;

        this.total_learners = res.data.data.attributes.total_learners_count;
        this.reattempts_disabled = res.data.data.attributes.reattempts_disabled;
        if (this.reattempts_disabled == true) {
          this.reattempts_enabled = false;
        } else {
          this.reattempts_enabled = true;
        }

        this.users = res.data.data.attributes.section_learners;

        this.total_learning_objs = res.data.data.attributes.total_learning_objs;
        this.lo_table_headers = res.data.data.attributes.lo_table_headers;


        this.columns_data = [
          {title:"Name", field:"learner_name", frozen: true},
          {title:"Email", field:"learner_email"}, 
        ];

        this.aggregate_columns_data = [];

        for (let i = 0; i < this.total_learning_objs; i++) {
          var learning_object_name = this.lo_table_headers[i].lo_name;
          var lo_skills = this.lo_table_headers[i].lo_skills;

          var skill_names_array = [];
          console.log(lo_skills, "*** lo_skills")
          console.log(lo_skills.total_skills, "*** lo_skills.total_skills")
          for (let k = 0; k < parseInt(lo_skills.total_skills); k++) {
            var skill_names_object = {};
            var skill_assessment_field = "ulo_" + i + "_skill_"+ k + "_assessment";
            skill_names_object["title"] = lo_skills['skill_name_' + k];
            skill_names_object["field"] = skill_assessment_field;
            skill_names_object["width"] = '15%';
            skill_names_object["formatter"] = "textarea";
            skill_names_array.push(skill_names_object);
            console.log(skill_names_array, "*** skill_names_array");
          }

          var aggregate_skill_names_array = [];
          for (let k = 0; k < parseInt(lo_skills.total_skills); k++) {
            var ag_skill_names_object = {};
            var ag_skill_assessment_field = "lo_" + i + "_skill_name_" + k;
            ag_skill_names_object["title"] = lo_skills['skill_name_' + k];
            ag_skill_names_object["field"] = ag_skill_assessment_field;
            console.log(ag_skill_assessment_field, "*** ag_skill_assessment_field");
            ag_skill_names_object["width"] = '15%';
            ag_skill_names_object["formatter"] = "textarea";
            aggregate_skill_names_array.push(ag_skill_names_object);
            console.log(aggregate_skill_names_array, "*** aggregate_skill_names_array");
          }

          var new_data = {
            title: learning_object_name, 
            columns: skill_names_array
          }
          this.columns_data.push(new_data);

          var new_aggregate_data = {
            title: learning_object_name, 
            columns: aggregate_skill_names_array,
            headerTooltip: true
          }
          this.aggregate_columns_data.push(new_aggregate_data);
        }

        this.tabulator = new Tabulator(this.$refs.assessmentTable, {
          data: this.users, 
          autoColumns:false,
          columnHeaderVertAlign:"bottom",
          layout: "fitData",
          layoutColumnsOnNewData: true,
          resizableColumns: true,
          selectable: true,
          renderHorizontal: "virtual",
          height: "600px",
          persistence: {
            sort: true, 
            filter: true, 
            columns: true,
          },
          downloadConfig: {
            columnHeaders: true, 
            columnGroups: true, 
            rowGroups: false, 
            columnCalcs: false, 
            dataTree: true
          },
          columns: this.columns_data
        })

        this.tabulator_aggregate = new Tabulator(this.$refs.aggregateTable, {
          data: this.aggregate_assessments,
          autoColumns: false, 
          columnHeaderVertAlign:"bottom",
          layout: "fitData",
          layoutColumnsOnNewData: true,
          resizableColumns: true,
          renderHorizontal: "virtual",
          height: "200px",
          downloadConfig: {
            columnHeaders: true, 
            columnGroups: true, 
            rowGroups: false, 
            columnCalcs: false, 
            dataTree: true
          },
          columns: this.aggregate_columns_data
        })
        utilFunctionService.hideLoader();
      });
      
      }
  }
};
</script>
<style lang="scss" scoped>


.main-content {
  padding: 0;
  width: 100%;
}
.completion-grid {
  width: 100%;
  height: 500px;
  margin-top: 32px;
}
.selection-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  .custom-select {
    width: 35%;
  }
}
.summary-container {
  margin-bottom: 64px;
  h2 {
    font-family: 'robotobold', sans-serif;
    font-size: 18px;
    text-transform: capitalize;
  }
  .summary {
    margin: 32px 0 0 32px;
    p {
      margin-bottom: 16px;
      span {
        font-weight: bold;
      }
    }
    .summary-learners {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.aggregate-container {
  padding-bottom: 3em;
}
.details-container, .aggregate-container {
  h2 {
    font-family: 'robotobold', sans-serif;
    font-size: 18px;
    text-transform: capitalize;
  }
  h3 {
    font-size: 16px;
    font-family: 'robotobold', sans-serif;
    text-transform: capitalize;
  }
  .details-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    .search-input {
      color: #4a5568;
      padding: 0.5rem 0.5rem;
      border-width: 1px;
      border-radius: 0.25rem;
      background-color: #fff;
      margin-left: 16px;
    }
  }
  .export-csv-container {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }
  .datePickerBtn {
    color: #3182ce;
    padding: 0.5rem;
    border-width: 1px;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #bee3f8;
    background-color: #ebf8ff;
    svg {
      fill: currentColor;
      width: 1rem;
      height: 1rem;
    }
  }
  .datePickerInput {
    color: #4a5568;
    padding: 0.5rem 0.5rem;
    border-width: 1px;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #fff;
  }
}
  .export-csv-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .btn {
      border: 3px solid #3dbc9e;
      background: #fff;
    }
    .settings-btn {
      margin-top: 16px;
      border: none;
      font-weight: bold;
      background: none;
    }
  }

  .tabulator-table {
    .tabulator-row {
      .tabulator-cell {
        white-space: pre-wrap;
        word-break: break-word;
        height: 100px !important;
      }
    }
  }
  .tabulator {
    margin-top: 16px;
    background: #fff;
    white-space: normal !important;
    .tabulator-col-title-holder {
    .tabulator-col-title {
      white-space: normal !important;
    }
  }  
  }
</style>
