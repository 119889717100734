<template>
  <div class="main-content">
    <div class="summary-container">
      <h2>Summary</h2>

      <div class="summary-learners"  v-if="this.total_modules > 1">
        <p><span>Total learners in this section: </span>{{ total_learners }}</p>
        <p><span>Total modules in this section: </span>{{ total_modules }}</p>
        <!--<p><span>Total learners who have completed all modules: </span>{{ total_completed_learners }} [{{ total_completed_percent}}%]</p>-->
      </div>

      <div class="summary-learners"  v-if="this.total_modules === 1">
        <p><span>Total learners in this section: </span>{{ total_learners }}</p>
        <!--<p><span>Total learners who have completed the module: </span>{{ total_completed_learners }} [{{ total_completed_percent}}%]</p>-->
        <p><span>Average grade: </span>{{ modules_summary[0].module_grade_average }}</p>
      </div>
      
      <div class="modules_grading" v-if="this.total_modules > 1">
        <h2>Average Grades</h2>
          <table class="modules_grading_table">
            <thead>
              <th>Module</th>
              <th>Average</th>
            </thead>
            <tbody>
              <tr
                v-for="(module) in modules_summary" 
                :key="module.id"
              >
                <td>{{ module.module_name }}</td>
                <td>{{ module.module_grade_average }}%</td>
              </tr>
            </tbody>
          </table>
          
      </div>
    </div>
    <div class="details-container">
      <h2>Learner Details</h2>
      <div class="details-header">
        <!---<div class="grade-convert">
          <div>
            <input type="checkbox" />
            <label>Convert grade to course equivalent</label>
          </div>
          <span><input type="number" /> %</span>
        </div>-->
        <!-- Turn this into it's own component -->
          
          <input class="search-input" type="text" placeholder="Search learners" v-model="searchQuery" @keyup.enter="searchLearner(searchQuery)"/>
          <div class="export-csv-container">
            <button class="btn" @click="downloadTableData()">Download Learner Data</button>
            <!--<button class="settings-btn">CSV export settings</button>-->
          </div>
      </div>
      <div ref="gradingTable" id="main-data-table"></div>
    </div>
  </div>
</template>
<script>
import api from "../services/api";
import moment from "moment";
import Tabulator from "tabulator-tables";
import { utilFunctionService } from "@/utils/utils.service";
import XLSX from "xlsx";
window.XLSX = XLSX;

export default {
  name: "Grading-Reporting",
  props: ["section_data"],
  components: {
  },
  data() {
    return {
      section: '',
      avg_grades: [],
      options: {
        
      },
      range: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 5)
      },
      searchQuery: "",
      date: new Date(),
      course_title: "",
      course_start: "",
      course_end: "",
      total_learners: '',
      total_modules: '',
      total_completed_learners: '',
      total_completed_percent: '',
      users: [],
      modules: [],
      learners: [],
      columns_data: [], 
      tabulator: null
    };
  },
  beforeMount() {
    this.section = JSON.parse(localStorage.getItem("section"));
    utilFunctionService.showLoader();
    api.getGradingReportData(this.section.id).then((res) => {
      console.log(res.data, "*** res.data in beforeMount");
      this.course_title = res.data.data.attributes.course_name;

      if (res.data.data.attributes.course_start_date) {
        this.course_start = moment(res.data.data.attributes.course_start_date).format("dddd, MMMM Do YYYY");
      } else {
        this.course_start = "N/A"
      }
      if (res.data.data.attributes.course_end_date) {
        this.course_end = moment(res.data.data.attributes.course_end_date).format("dddd, MMMM Do YYYY");
      } else {
        this.course_end = "N/A"
      }

      this.total_learners = res.data.data.attributes.total_learners_count;
      this.total_completed_learners = res.data.data.attributes.total_learners_completed_all_modules;
      this.total_completed_percent = res.data.data.attributes.percent_learners_finished_all_modules;
      this.total_modules = res.data.data.attributes.total_modules;
      console.log(this.total_modules, "*** this.total_modules");
      this.modules_summary = res.data.data.attributes.modules_average_grades;
      this.users = res.data.data.attributes.section_learners;
      console.log(this.users, "*** this.users");
      this.columns_data = [
          {title:"Name", field:"learner_name", frozen: true},
          {title:"Email", field:"learner_email"}, 
          {title:"Overall Grade", field:"learner_overall_grade"}, 
          
        ];
        
      if (this.total_modules > 1) {
        for (let i = 0; i < this.total_modules; i++) {
          var module_name = this.users[i].learner_modules_data[i].attributes.module_name;
          //console.log(module_name, "*** module_name");
          var module_grade = "learner_module_" + (i+1) + "_grade";
          //console.log(module_grade, "*** module_grade");
          var new_data = {title: module_name, 
            columns: [
                {title: "Grade", field: module_grade, width:"20%"}
              ]
            };
          this.columns_data.push(new_data);
      }
        
      }

      //console.log(this.columns_data, "*** this.columns_data");
      if (this.total_modules > 1) {

        this.tabulator = new Tabulator(this.$refs.gradingTable, {
          data: this.users, 
          autoColumns:false,
          columnHeaderVertAlign:"bottom",
          layout: "fitData",
          layoutColumnsOnNewData: true,
          resizableColumns: true,
          renderHorizontal: "virtual",
          height: "600px",
          persistence: {
            sort: true, 
            filter: true, 
            columns: true,
          },
          selectable: true,
          downloadConfig: {
            columnHeaders: true, 
            columnGroups: true, 
            rowGroups: false, 
            columnCalcs: false, 
            dataTree: true
          },
          columns: this.columns_data
        })
      } else {
        this.tabulator = new Tabulator(this.$refs.gradingTable, {
          data: this.users, 
          autoColumns:false,
          columnHeaderVertAlign:"bottom",
          layout: "fitColumns",
          layoutColumnsOnNewData: true,
          resizableColumns: true,
          renderHorizontal: "virtual",
          height: "600px",
          persistence: {
            sort: true, 
            filter: true, 
            columns: true,
          },
          selectable: true,
          downloadConfig: {
            columnHeaders: true, 
            columnGroups: true, 
            rowGroups: false, 
            columnCalcs: false, 
            dataTree: true
          },
          columns: this.columns_data
        })
      }
      utilFunctionService.hideLoader();
    });
},
methods: {
  downloadTableData() {
    let spreadsheetName = this.section.attributes.name + " Grading Report Export.xlsx";
      this.tabulator.download("xlsx", spreadsheetName);
  },
  searchLearner(learnerQuery) {
    this.tabulator.setFilter("learner_name", "like", learnerQuery);
  },
}
};
</script>
<style lang="scss" scoped>
@import "tabulator-tables/dist/css/tabulator.css";

.main-content {
  padding: 0;
  width: 100%;
}
.completion-grid {
  width: 100%;
  height: 500px;
  margin-top: 32px;
}
.summary-container {
  margin-bottom: 64px;

  p {
    margin-bottom: 16px;
    span {
      font-weight: bold;
    }
  }

  h2 {
    font-family: 'robotobold', sans-serif;
    font-size: 18px;
    text-transform: capitalize;
  }

  .summary-learners {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 1em;
  }
  
  .modules_grading {
    padding-top: 3em;
    .modules_grading_table {
      width: 100%;
      padding-top: 1em;
      display: block;
      thead {
        border-bottom: 1px solid #333;
        th {
          padding-bottom: 0.5em;
        }
      }
      tr {
        td {
          padding-right: 1em;
          padding-top: 0.5em;
          width: 20%;
        }
      }
    }
  }
  .summary {
    margin: 2em 0 0 0;
    p {
      margin-bottom: 16px;
      span {
        font-weight: bold;
      }
    }
    
  }
}
.details-container {
  h2 {
    font-family: 'robotobold', sans-serif;
    font-size: 18px;
    text-transform: capitalize;
  }
  h3 {
    font-size: 16px;
    font-family: 'robotobold', sans-serif;
    text-transform: capitalize;
  }
  .details-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 16px;
    .search-input {
      color: #4a5568;
      height: 45px;
      padding: 0.5rem 0.5rem;
      border-width: 1px;
      border-radius: 0.25rem;
      background-color: #fff;
      margin-left: 16px;
    }
  }
  .export-csv-container {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }
  .datePickerBtn {
    color: #3182ce;
    padding: 0.5rem;
    border-width: 1px;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #bee3f8;
    background-color: #ebf8ff;
    svg {
      fill: currentColor;
      width: 1rem;
      height: 1rem;
    }
  }
  .datePickerInput {
    color: #4a5568;
    padding: 0.5rem 0.5rem;
    border-width: 1px;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #fff;
  }
}
.export-csv-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .btn {
    border: 3px solid #3dbc9e;
    background: #fff;
  }
  .settings-btn {
    margin-top: 16px;
    border: none;
    font-weight: bold;
    background: none;
  }
}
.tabulator {
  margin-top: 16px;
  background: #fff;
  white-space: normal !important;
  .tabulator-col-title-holder {
  .tabulator-col-title {
    white-space: normal !important;
  }
}  
}

 
  
.grade-convert {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  input {
    margin-right: 4px;
  }
  span {
    margin-left: 16px;
    input {
      width: 75px;
    }
  }
}
</style>
