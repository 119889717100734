import Vue from 'vue';
import App from './App.vue';
import store from './store';
import VModal from 'vue-js-modal'
import Toasted from 'vue-toasted';
import router from './router/router'
import ReadMore from 'vue-read-more';
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import ToggleButton from 'vue-js-toggle-button'
import { Notifier } from '@airbrake/browser';
import VueTippy, { TippyComponent } from "vue-tippy";
import VCalendar from 'v-calendar';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import ActionCableVue from 'actioncable-vue';

// Add additional themes.
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";
import "tabulator-tables/dist/css/tabulator.css";

// name is optional
Vue.config.productionTip = false
Vue.use(Datetime)
Vue.use(Toasted)
Vue.use(ReadMore)
Vue.use(ToggleButton)
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })
Vue.use(VueTippy);
Vue.component("tippy-component", TippyComponent);
Vue.use(VCalendar);
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: process.env.VUE_APP_ACTION_CABLE_URL,
  connectImmediately: true,
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('en', en);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);


// Airbrake code ..
if (process.env.NODE_ENV === 'production') {
  var airbrake = new Notifier({
    environment: 'production',
    projectId: process.env.VUE_APP_AIRBRAKE_PRODUCT_ID,
    projectKey: process.env.VUE_APP_AIRBRAKE_PRODUCT_KEY
  });
  Vue.config.errorHandler = function (err, vm, info) {
    airbrake.notify({
      error: err,
      params: {info: info}
    });
  }
}
