<template>
  <div>
    <div class="top-header">
      <backDashboard />
      <userLogout />
    </div>
    <div class="login-container profile-container">
      <div class="login-form">
        <div class="form-group">
          <h3>Edit Profile</h3>
          <p>Update your profile details</p>
        </div>
        <div class="form-group pt-1">
          <div @click="$refs.fileInput.click()">
            <div :class="[!profile.attachment ? 'profile-upload' : '']">
              <input
                ref="fileInput"
                type="file"
                @change="onFileSelect"
                style="display: none;"
                accept="image/jpeg"
                class="upload"
                @click="$refs.fileInput.click()"
              />
            </div>
            <div class="profile-image" v-if="profile.attachment" @click="$refs.fileInput.click()">
              <img :src="profile.attachment" />
            </div>
          </div>
          <div class="profile-uploadCaption invalid"  v-if="profile.attachment" @click="uploadImageRemoved">Remove Profile Picture</div>
          <div
              class="profile-uploadCaption"
              v-if="!profile.attachment"
              @click="$refs.fileInput.click()"
          >Upload Profile Picture</div>
        </div>
        <div class="row">
          <div class="col-6 pr-2 form-group">
            <label>First Name</label>
            <input type="text" class="form-control" name="username" v-model="profile.first_name" />
          </div>
          <div class="col-6 pl-2 form-group">
            <label>Last Name</label>
            <input type="text" class="form-control" name="username" v-model="profile.last_name" />
          </div>
        </div>
        <div class="form-group">
          <label>Email Address</label>
          <input type="text" disabled="true" class="form-control" name="username" v-model="profile.email" />
        </div>
        <div class="form-group">
          <a class="reset-link" href="javascript:void(0);" @click="resetPassword">Reset Password</a>
        </div>
        <div v-if="show">
          <div class="form-group">
            <div class="d-flex">
              <label>Current Password</label>
            </div>
            <div class="position-relative">
              <input
                type="password"
                name="password"
                class="form-control"
                @input="oldPassValueChange()"
                v-model="profile.old_password"
              />
              <span class="invalid" v-if="oldPasswordInvalid">Old password is required</span>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex">
              <label>New Password</label>
            </div>
            <div class="position-relative">
              <input
                :type="passwordFieldType"
                name="password"
                @input="newPassValueChange()"
                class="form-control"
                v-model="profile.password"
              />
              <a
                class="forgot-show"
                @click="passwordVisibility"
                v-if="passwordFieldType === 'password'"
              >Show</a>
              <a
                class="forgot-show"
                @click="passwordVisibility"
                v-if="passwordFieldType === 'text'"
              >Hide</a>
              <span class="invalid" v-if="newPasswordInvalid">New password is required</span>
            </div>
          </div>
        </div>
        <div class="messaging-item mb-3">
          <label class="custom-checkbox">
            Send me an email notification when I have a new message
            <input
              type="checkbox"
              name="check1"
              id="check1"
              v-model="profile.new_msg_email_notify"
            />
            <span for="check1" class="checkmark"></span>
          </label>
        </div>
        <div class="messaging-item mb-4">
          <label class="custom-checkbox">
            Send me an email notification when I have a new rubric to evaluate
            <input
              type="checkbox"
              name="check1"
              id="check1"
              v-model="profile.new_rubric_email_notify"
            />
            <span for="check1" class="checkmark"></span>
          </label>
        </div>
        <div class="form-group pt-1">
          <button type="button" class="btn primary" @click="saveProfile">Save</button>
        </div>
      </div>
    </div>
    <modal name="cropperImages" height="auto" :width="600" :scrollable="true">
      <div class="modal-content modal-cropper-content">
        <div class="modal-body">
          <div class="modal-images-cropper">
            <div class="modal-cropper-large" v-if="profile.attachment">
              <cropper
                :src="profile.attachment"
                :stencil-props="{ aspectRatio: 16/9 }"
                ref="cropper"
              ></cropper>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="$modal.hide('cropperImages');imageRemoved()" type="button" class="btn default">Cancel</button>
          <button type="button" @click="crop()" class="btn primary">Save</button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import { Cropper } from "vue-advanced-cropper";

export default {
  name: "edit-profile",
  components: {
    backDashboard,
    userLogout,
    Cropper
  },
  data() {
    return {
      passwordFieldType: "password",
      show: false,
      status: false,
      oldPasswordInvalid: false,
      newPasswordInvalid: false,
      user: {},
      selectedFile: "",
      instructorInfo: {},
      profile: {
        first_name: "",
        last_name: "",
        email: "",
        new_msg_email_notify: false,
        new_rubric_email_notify: false,
        password: "",
        old_password: "",
        attachment: "",
        attachment_name: ""
      }
    };
  },
  methods: {
    oldPassValueChange() {
      if (this.profile.old_password.length > 0) {
        this.oldPasswordInvalid = false;
      }
    },
    newPassValueChange() {
       if (this.profile.password.length > 0) {
        this.newPasswordInvalid = false;
      }
    },
    onFileSelect(event) {
      this.selectedFile = "";
      this.selectedFile = event.target.files[0];
      this.profile.attachment_name = this.selectedFile.name;
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.selectedFile);
      _reader.onload = e => {
        this.profile.attachment = e.target.result;
      };
      this.$modal.show("cropperImages");
    },
    passwordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    resetPassword() {
      this.show = !this.show;
    },
    imageRemoved() {
      this.profile.attachment = null;
      this.selectedFile = null;
      this.status = false;
    },
    uploadImageRemoved() {
      this.profile.attachment = null;
      this.selectedFile = null;
      this.status = false;
      api
          .removeProfileImage(this.instructorInfo.id)
          .then(response => {
            if (response.status === commonConfig.httpCodes.ok) {
                  utilFunctionService.showSuccess(
                  commonConfig.appMessages.removeImage
                );
            }
          })
          .catch(() => {});
    },
    saveProfile() {
      if (this.profile.old_password.length > 0 && this.profile.password.length === 0) {
        this.newPasswordInvalid = true;
      } else if (
        this.profile.old_password.length === 0 &&
        this.profile.password.length > 0
      ) {
        this.oldPasswordInvalid = true;
      } else {
        this.user = {
          first_name: this.profile.first_name,
          last_name: this.profile.last_name,
          new_msg_email_notify: this.profile.new_msg_email_notify,
          new_rubric_email_notify: this.profile.new_rubric_email_notify
        };
        if (this.profile.password.length > 0) {
          this.user = {
            password: this.profile.password,
            old_password: this.profile.old_password
          };
        }
        if (this.status) {
          this.user = {
            attachment: this.profile.attachment,
            attachment_name: this.profile.attachment_name,
            first_name: this.profile.first_name,
            last_name: this.profile.last_name,
            new_msg_email_notify: this.profile.new_msg_email_notify,
            new_rubric_email_notify: this.profile.new_rubric_email_notify
          };
        }
        api
          .updateProfile(this.instructorInfo.id, this.user)
          .then(() => {
            utilFunctionService.showSuccess(
              commonConfig.appMessages.updateProfile
            );
            this.$router.replace({ name: "active" });
          })
          .catch(error => {
            if (error.response.status === 422) {
              utilFunctionService.showerr(commonConfig.appMessages.inCorrectPassword);
            }
          });
      }
    },
    getProfile() {
      api
        .getInstructorProfile(this.instructorInfo.id)
        .then(res => {
          this.profile = res.data.data.attributes;
          this.profile.password = "";
          this.profile.old_password = "";
        })
        .catch(() => {});
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.attachment = canvas.toDataURL();
      this.profile.attachment = this.attachment;
      this.status = true;
      this.$modal.hide("cropperImages");
    }
  },
  created() {
    this.instructorInfo = JSON.parse(localStorage.getItem("instructor")).users;
    this.getProfile();
  }
};
</script>
