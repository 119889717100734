<template>
  <div>
    <div class="learn-submission p-4">
      <div class="learn-table">
        <h4 class="mb-5">
          Submission LO {{ scorable_type === "grading" ? "Grading" : "Email" }}
        </h4>
        <div class="learn-head">
          <div class="learn-col">criteria</div>
          <div class="learn-col">0-49</div>
          <div class="learn-col">50-59</div>
          <div class="learn-col">60-69</div>
          <div class="learn-col">70-79</div>
          <div class="learn-col">80-89</div>
          <div class="learn-col">90-100</div>
          <div class="learn-col">Weight</div>
        </div>
        <div class="learn-data">
          <div
            class="learn-row"
            v-for="(grading, index) in data"
            :key="grading.id"
          >
            <div class="learn-col">
              <div class="learn-criteria px-3">
                <p>{{ grading.rubric_criteria }}</p>
                <button
                  v-if="grading.rubric_criteria_desc"
                  :content="grading.rubric_criteria_desc"
                  v-tippy="{ trigger: 'click', arrow: true }"
                  class="info-icon no-style-btn"
                >
                  i
                </button>
              </div>
            </div>
            <button
              :content="rubric[index] && rubric[index]['range_1']"
              v-tippy="{ arrow: true }"
              class="learn-col no-style-btn"
            >
              <div class="learn-editor" @click="changeStatus(index, 0)">
                <div
                  class="learn-editor-value"
                  v-if="grading.score > -1 && grading.score < 50"
                >
                  <button
                    @click="addRemove(index, -1, 'remove')"
                    class="remove"
                    v-if="grading.score !== 0"
                  >
                    -
                  </button>
                  <div class="learn-form">
                    <input
                      type="number"
                      class="form-editor"
                      v-model="grading.score"
                    />
                  </div>
                  <button
                    @click="addRemove(index, 50, 'add')"
                    class="add"
                    v-if="grading.score !== 49"
                  >
                    +
                  </button>
                </div>
              </div>
            </button>
            <button
              :content="rubric[index] && rubric[index]['range_2']"
              v-tippy="{ arrow: true }"
              class="learn-col no-style-btn"
            >
              <div class="learn-editor" @click="changeStatus(index, 1)">
                <div
                  class="learn-editor-value"
                  v-if="grading.score > 49 && grading.score < 60"
                >
                  <button
                    @click="addRemove(index, 49, 'remove')"
                    class="remove"
                    v-if="grading.score !== 50"
                  >
                    -
                  </button>
                  <div class="learn-form">
                    <input
                      type="number"
                      class="form-editor"
                      v-model="grading.score"
                    />
                  </div>
                  <button
                    @click="addRemove(index, 60, 'add')"
                    class="add"
                    v-if="grading.score !== 59"
                  >
                    +
                  </button>
                </div>
              </div>
            </button>
            <button
              :content="rubric[index] && rubric[index]['range_3']"
              v-tippy="{ arrow: true }"
              class="learn-col no-style-btn"
            >
              <div class="learn-editor" @click="changeStatus(index, 2)">
                <div
                  class="learn-editor-value"
                  v-if="grading.score > 59 && grading.score < 70"
                >
                  <button
                    @click="addRemove(index, 59, 'remove')"
                    class="remove"
                    v-if="grading.score !== 60"
                  >
                    -
                  </button>
                  <div class="learn-form">
                    <input
                      type="number"
                      class="form-editor"
                      v-model="grading.score"
                    />
                  </div>
                  <button
                    @click="addRemove(index, 70, 'add')"
                    class="add"
                    v-if="grading.score !== 69"
                  >
                    +
                  </button>
                </div>
              </div>
            </button>
            <button
              :content="rubric[index] && rubric[index]['range_4']"
              v-tippy="{ arrow: true }"
              class="learn-col no-style-btn"
            >
              <div class="learn-editor" @click="changeStatus(index, 3)">
                <div
                  class="learn-editor-value"
                  v-if="grading.score > 69 && grading.score < 80"
                  @click="changeStatus(4)"
                >
                  <button
                    @click="addRemove(index, 69, 'remove')"
                    class="remove"
                    v-if="grading.score !== 70"
                  >
                    -
                  </button>
                  <div class="learn-form">
                    <input
                      type="number"
                      class="form-editor"
                      v-model="grading.score"
                    />
                  </div>
                  <button
                    @click="addRemove(index, 80, 'add')"
                    class="add"
                    v-if="grading.score !== 79"
                  >
                    +
                  </button>
                </div>
              </div>
            </button>
            <button
              :content="rubric[index] && rubric[index]['range_5']"
              v-tippy="{ arrow: true }"
              class="learn-col no-style-btn"
            >
              <div class="learn-editor" @click="changeStatus(index, 4)">
                <div
                  class="learn-editor-value"
                  v-if="grading.score > 79 && grading.score < 90"
                  @click="changeStatus(5)"
                >
                  <a
                    @click="addRemove(index, 79, 'remove')"
                    class="remove"
                    v-if="grading.score !== 80"
                    >-</a
                  >
                  <div class="learn-form">
                    <input
                      type="number"
                      class="form-editor"
                      v-model="grading.score"
                    />
                  </div>
                  <a
                    @click="addRemove(index, 90, 'add')"
                    class="add"
                    v-if="grading.score !== 89"
                    >+</a
                  >
                </div>
              </div>
            </button>
            <button
              :content="rubric[index] && rubric[index]['range_6']"
              v-tippy="{ arrow: true }"
              class="learn-col no-style-btn"
            >
              <div class="learn-editor" @click="changeStatus(index, 5)">
                <div
                  class="learn-editor-value"
                  v-if="grading.score > 89 && grading.score < 101"
                  @click="changeStatus(5)"
                >
                  <a
                    @click="addRemove(index, 89, 'remove')"
                    class="remove"
                    v-if="grading.score !== 90"
                    >-</a
                  >
                  <div class="learn-form">
                    <input
                      type="number"
                      class="form-editor"
                      v-model="grading.score"
                    />
                  </div>
                  <a
                    @click="addRemove(index, 101, 'add')"
                    class="add"
                    v-if="grading.score !== 100"
                    >+</a
                  >
                </div>
              </div>
            </button>
            <div class="learn-col">
              <div class="learn-weight">
                <input
                  type="text"
                  class="form-weight"
                  readonly
                  :value="grading.rubric_weight"
                />
              </div>
            </div>
          </div>
          <div class="learn-row">
            <div class="learn-total">Grade: {{ Math.round(grades) }}%</div>
          </div>
        </div>
      </div>
      <div class="learn-feedbacks submissionFeedback">
        <h4>Add comments (Optional)</h4>
        <div class="learn-feedbackEditor">
          <vue-editor
            v-model="feedback"
            placeholder="Add your comments here"
            class="form-control"
          ></vue-editor>
        </div>
        <div class="learn-feedbackBtn">
          <button @click="preview()" class="btn primary outline">
            Preview
          </button>
          <button
            v-if="status !== 'published'"
            @click="sendFeedback('draft')"
            class="btn primary outline"
          >
            Save as Draft
          </button>
          <button @click="sendFeedback('published')" class="btn primary">
            {{
              status === "published" && changeRubric
                ? "Send Feedback"
                : status === "published" && !changeRubric
                ? "Feedback Sent"
                : "Send Feedback"
            }}
          </button>
        </div>
      </div>
    </div>
    <feedback-result-modal
      :previewData="previewData"
      :feedback="feedback"
    ></feedback-result-modal>
  </div>
</template>
<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { VueEditor } from "vue2-editor";
import { commonConfig } from "@/utils/commonConfig";
import feedbackResultModal from "@/components/feedbackResultModal";

export default {
  name: "learnerSubmission",
  components: {
    VueEditor,
    feedbackResultModal,
  },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      learnerSubmission: {},
      module: {},
      data: [],
      status: "",
      grades: "",
      feedback: "",
      scorable_type: "",
      previewData: {
        overall_grade: "",
        data: [],
      },
      changeRubric: false,
      rubric: [
        {
          id: null,
          criteria: null,
          range_1: null,
          range_2: null,
          range_3: null,
          range_4: null,
          range_5: null,
        },
      ],
    };
  },
  methods: {
    getRubricData() {
      this.section = JSON.parse(localStorage.getItem("section"));
      this.module = this.section;
      api
        .rubricData(
          this.module.attributes.customer_course_id,
          this.learnerSubmission.learning_object_id
        )
        .then((res) => {
          if (res.data.rubrics.length) {
            this.rubric = res.data.rubrics.map((x) => ({
              id: x.id,
              criteria: x.attributes.criteria,
              range_1: this.stripHtml(x.attributes.range_1),
              range_2: this.stripHtml(x.attributes.range_2),
              range_3: this.stripHtml(x.attributes.range_3),
              range_4: this.stripHtml(x.attributes.range_4),
              range_5: this.stripHtml(x.attributes.range_5),
              range_6: this.stripHtml(x.attributes.range_6),
            }));
          }
        });
    },
    stripHtml(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    getLearnerSubmissiondata() {
      utilFunctionService.showLoader();
      this.learnerSubmission = JSON.parse(
        localStorage.getItem("learnerSubmission")
      );
      this.scorable_type = this.learnerSubmission.scorable_type;
      api
        .getLearnerSubmissiondata(
          this.learnerSubmission.scorable_type,
          this.learnerSubmission.scorable_id,
          this.learnerSubmission.customer_course_id,
          this.learnerSubmission.learning_object_id
        )
        .then((res) => {
          this.data = [];
          let gradings = res.data.gradings ? res.data.gradings.data : [];
          this.data = [];
          if (gradings.length > 0) {
            for (let i = 0; i < gradings.length; i++) {
              gradings[i].attributes.id = gradings[i].id;
              this.data.push(gradings[i].attributes);
            }
          }
          this.feedback = res.data.feedback ? res.data.feedback : "";
          this.grades = res.data.grades ? res.data.grades : "";
          this.status = res.data.status;
          utilFunctionService.hideLoader();
        })
        .catch(() => {});
    },
    addRemove(index, range, type) {
      if (type === "add") {
        this.data[index].score = this.data[index].score + 1;
      } else {
        this.data[index].score = this.data[index].score - 1;
      }
      this.setGrade();
    },
    setGrade() {
      this.grades = 0;
      this.data.forEach((element) => {
        let percent = (element.score * element.rubric_weight) / 100;
        this.grades = this.grades + percent;
      });
      this.grades = this.grades.toFixed(2);
    },
    changeStatus(type, dataRange) {
      this.changeRubric = true;
      if (dataRange === 0)
        this.data[type].score =
          this.data[type].score > 0 && this.data[type].score < 50
            ? this.data[type].score
            : 24;
      if (dataRange === 1)
        this.data[type].score =
          this.data[type].score > 49 && this.data[type].score < 60
            ? this.data[type].score
            : 54;
      if (dataRange === 2)
        this.data[type].score =
          this.data[type].score > 59 && this.data[type].score < 70
            ? this.data[type].score
            : 64;
      if (dataRange === 3)
        this.data[type].score =
          this.data[type].score > 69 && this.data[type].score < 80
            ? this.data[type].score
            : 74;
      if (dataRange === 4)
        this.data[type].score =
          this.data[type].score > 79 && this.data[type].score < 90
            ? this.data[type].score
            : 84;
      if (dataRange === 5)
        this.data[type].score =
          this.data[type].score > 89 && this.data[type].score < 101
            ? this.data[type].score
            : 94;
      this.setGrade();
    },
    readSubmissionNotification() {
      const _fd = new FormData();
      let parentWindow = window.opener;
      const windowOrigin = window.location.origin;
      _fd.append("user_learn_obj_id", this.learnerSubmission.loId);
      api
        .readSubmissionCount(_fd)
        .then(() => {
          utilFunctionService.showSuccess(
            commonConfig.appMessages.feedbackPublishedSuccessfully
          );
          parentWindow.postMessage("GradeSaved", windowOrigin)
          setTimeout(() => {
            window.open("", "_self").close();
          }, 5000);
        })
        .catch();
    },
    sendFeedback(type, preview) {
      utilFunctionService.showLoader();
      let parentWindow = window.opener;
      const fb = new FormData();
      const windowOrigin = window.location.origin;
      fb.append("scorable_type", this.learnerSubmission.scorable_type);
      fb.append("scorable_id", this.learnerSubmission.scorable_id);
      fb.append("scorable[feedback]", this.feedback);
      fb.append("scorable[status]", type);
      for (let i = 0; i < this.data.length; i++) {
        fb.append(
          "scorable[submission_gradings_attributes][][id]",
          this.data[i].id
        );
        fb.append(
          "scorable[submission_gradings_attributes][][score]",
          this.data[i].score
        );
      }
      api
        .saveLearnerSubmissiondata(fb)
        .then((res) => {
          if (res.status === 200 && type === "published" && !preview) {
            // this.readSubmissionNotification();
            utilFunctionService.showSuccess(
              commonConfig.appMessages.feedbackPublishedSuccessfully
            );
            parentWindow.postMessage("GradeSaved", windowOrigin)
            setTimeout(() => {
              window.open("", "_self").close();
            }, 5000);
          }
          if (res.status === 200 && type === "draft" && !preview) {
            utilFunctionService.showSuccess(
              commonConfig.appMessages.feedbackDraftSuccessfully
            );
            parentWindow.postMessage("GradeSaved", windowOrigin)
            setTimeout(() => {
              window.open("", "_self").close();
            }, 5000);
          }
          if (preview) {
            api
              .getPreview(
                this.learnerSubmission.scorable_type,
                this.learnerSubmission.scorable_id
              )
              .then((res) => {
                if (this.learnerSubmission.scorable_type === "email") {
                  this.previewData.overall_grade =
                    res.data.data.attributes.overall_grade;
                  this.previewData.data =
                    res.data.data.attributes.submission_gradings;
                } else {
                  this.previewData = res.data;
                }
                this.$modal.show("feedbackResult");
              });
          }
          utilFunctionService.hideLoader();
          this.getLearnerSubmissiondata();
        })
        .catch(() => {});
    },
    preview() {
      if (this.status === "published") this.sendFeedback("published", true);
      else this.sendFeedback("draft", true);
    },
  },
  created() {
    this.getLearnerSubmissiondata();
    this.getRubricData();
  },
};
</script>
<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.no-style-btn {
  border: none;
  outline: none;
}
.no-style-btn:focus {
  border: none;
  outline: none;
}
.learn-editor-value button {
  width: 20px;
  height: 20px;
  background: none;
  border: none;
}
</style>  
