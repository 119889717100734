<template>
  <div class="container-full">
    <div class="top-header">
      <backDashboard />
      <headerTitle :moduleDetails="modules" />
      <userLogout />
    </div>
   <div class="site-content">
      <menuSidebar />
      <div class="main-content" v-if="!grading_enabled">
        <div class="main-title">

          <h3>Learner Assessments Report</h3>
        </div>
        <div class="mt-5">
          <p>Grading has not been enabled for this section.</p>
        </div>
      </div>
      <div class="main-content" v-if="grading_enabled">
       <div class="main-title align-items-center">
          <h3>Learner Assessments Report</h3>
          <div class="main-searchForms">
              <button class="btn primary" @click="downloadGradeCsv()">Download CSV</button>
          </div>
        </div>
        <div class="data-table column-5">
          <div class="data-head">
            <div class="data-col">
              Last Name
            </div>
            <div class="data-col" >
              First Name
            </div>
            <div class="data-col" >
              Start Date
            </div>
            <div class="data-col" >
              End Date
            </div>
            <div class="data-col">
              ELM Grade
            </div>
          </div>
          <div class="data-content">
            <div class="data-row" v-for="(learner) in learner_list" :key="learner.id">    
              <div class="data-col">
               {{learner.attributes.last_name}}
              </div>
              <div class="data-col"> 
                {{learner.attributes.first_name}}
              </div>
              <div class="data-col"> 
                 {{learner.attributes.start_date}}
              </div>
              <div class="data-col"> 
                 {{learner.attributes.end_date}}
              </div>
              <div class="data-col" v-if="learner.attributes.elm_grade">
                  {{learner.attributes.elm_grade}}
              </div>
            </div>
          </div> 
      </div>
      
      <!-- <div class="mx-5 my-5" v-if="enableReportMenu">
        <h6 class="my-3">Total Learners in Section: {{totalLearnersCount}}</h6>
        <h6> Completed Module: {{learnersCompletedCount}} | {{learnersCompletedPercentage}}%</h6>
        <div>
        <div class="my-3">
          <button class="btn primary" @click="downloadAllCsv()">Download all CSV</button>
        </div>
        <div class="my-3">
          <button class="btn primary" @click="downloadAggregateDialogicCsv()">Download aggregate dialogic CSV</button>
        </div>
        <div class="my-3">
          <button class="btn primary" @click="downloadAggregateCodingCsv()">Download aggregate coding CSV</button>
        </div>
         <div class="my-3">
          <button class="btn primary" @click="downloadAggregateQuizCsv()">Download aggregate Quiz CSV</button>
        </div>
      </div> -->
      </div>
      
    </div>
  </div>

</template>
<script>
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import menuSidebar from "../components/menuSidebar.vue";
import headerTitle from "../components/headerTitle.vue";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";

export default {
  name: "reports-component",
  components: {
    backDashboard,
    userLogout,
    menuSidebar,
    headerTitle
  },
  data() {
      return {
        learner_list: [],
        enableReportMenu: true, 
        grading_enabled: false,
        modules: [],
        instructorInfo: {},
        profile: {
          roles: "",
        }
      }
  },
  methods: {
    getLearnerGrades() {
        utilFunctionService.showLoader();
        api
          .getLearnerGrades(
            this.modules.id
          )
          .then(res => {
            //console.log(res.data.data, "*** getLearnerGrades res.data.data");
            this.learner_list = res.data.data;
            //console.log('**this.learner_list', this.learner_list)
            this.searchStatus = false;
            utilFunctionService.hideLoader();
          })
          .catch(() => {});
    },

    getSectionLoDetails() {
        utilFunctionService.showLoader();
        api
          .getSectionLoDetails(
            this.modules.id
          )
          .then(res => {
            console.log(res.data.data, "getSectionLoDetails res.data.data");
            let response = res.data.data;
            this.totalLearnersCount = response.attributes.total_learners_in_section;
            this.learnersCompletedCount = response.attributes.section_completed_learners;
            this.learnersCompletedPercentage = response.attributes.section_completed_learner_percentage;
            this.lo_list = response.attributes.user_learning_object_details;
            console.log('**this.lo_list', this.lo_list)
            utilFunctionService.hideLoader();
            this.searchStatus = false;
          })
          .catch(() => {});
    },
    getProfile() {
      api
        .getInstructorProfile(this.instructorInfo.id)
        .then((res) => {
          this.profile = res.data.data.attributes;
          utilFunctionService.setLocalStorageService(
            "profile_url",
            JSON.stringify(this.profile.attachment)
          );
          utilFunctionService.setLocalStorageService(
            "sso_authenticated",
            JSON.stringify(this.profile.sso_authenticated)
          );
        })
        .catch(() => {});
        
    },

    downloadGradeCsv() {
      utilFunctionService.showLoader();
       api
          .getLearnersGradeCsv(
            this.modules.id
          )
          .then(response => {
              let res = response.data.csv_data;
              let fileName = response.data.filename;
              const url = window.URL.createObjectURL(new Blob([res], {type: 'text/csv'}))
              var link = document.createElement('a')
              link.href = url
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              utilFunctionService.hideLoader();
          })
           .catch(() => {
             utilFunctionService.hideLoader();
           });
    }
  },
  created() {
    this.modules = JSON.parse(localStorage.getItem("module"));
    console.log(this.modules, "*** this.modules");
    this.grading_enabled = this.modules.attributes.learn_mods[0].attributes.enable_grading;
    //this.section = JSON.parse(localStorage.getItem("section"));
    //this.modules = this.section;
    //this.getSectionLoDetails();
    //this.enableReportMenu = this.modules && this.modules.attributes.enable_csv_reports;
    this.instructorInfo = JSON.parse(localStorage.getItem("instructor")).users;
    this.getLearnerGrades();
    this.getProfile();
  }
};
</script>
