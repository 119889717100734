<template>
  <div>
    <header>
      <ul class="nav nav-tabs">
        <li
          class="nav-item"
          v-for="tab in tabs"
          :key="tab"
          :class="{
            'tab-active': activeTab === tab,
          }"
          @click="switchTab(tab)"
        >
          <slot :name="tabHeadSlotName(tab)">{{ tab }} </slot>
        </li>
      </ul>
    </header>
    <main class="card-body">
      <div class="tab-panel">
        <slot :name="tabPanelSlotName"> </slot>
      </div>
    </main>
  </div>
</template>

<script>

export default {
  props: {
    initialTab: String,
    tabs: Array,
  },
  data() {
    return {
      activeTab: this.initialTab,
    };
  },
  computed: {
    tabPanelSlotName() {
      return `tab-panel-${this.activeTab}`;
    },
  },
  methods: {
    tabHeadSlotName(tabName) {
      return `tab-head-${tabName}`;
    },

    switchTab(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 32px 0;
}
.nav-item {
	display: block;
	padding: 0.5rem 1rem;
	text-decoration: none;
	text-transform: capitalize;
  transition: all ease-in-out .1s;
  &:hover {
    color: #ffffff;
    background-color: #3dbc9e;
    font-weight: bold;
    cursor: pointer;
  }
}

.tab-active {
	color: #ffffff;
	background-color: #3dbc9e;
	font-weight: bold;
}
</style>