<template>
  <div class="container-full">
    <div class="top-header">
      <backDashboard />
      <headerTitle :moduleDetails="section"/>
      <userLogout />
    </div>
    <div class="site-content">
      <menuSidebar />
      <div class="main-content pt-4 mt-2">
        <div class="main-title align-items-center">
          <div class="d-flex justify-content-between w-100">
            <h2>Instructor Resource Videos</h2>
            <div class="dashboard-active-info d-flex align-items-center">
              <button
                class="btn reset_btn"
                @click="resetSearch()"
                v-if="searching"
              >
                Reset search
              </button>
              <div class="form-group d-flex align-items-center resource-search">
                <input
                  type="text"
                  class="form-control course-search"
                  placeholder="Search by video title"
                  v-model="searchQuery"
                />
                <em
                  @click="searchInstructorResourceVideos()"
                  class="icon-search"
                ></em>
              </div>
            </div>
          </div>
        </div>
        <video-Resources-Dashboard
          :videos="instructorResources"
        ></video-Resources-Dashboard>
      </div>
    </div>
  </div>
</template>
<script>
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import menuSidebar from "../components/menuSidebar.vue";
import api from "../services/api";
import headerTitle from "../components/headerTitle.vue";
import { utilFunctionService } from "@/utils/utils.service";
import videoResourcesDashboard from "@/components/videoResourcesDashboard.vue";

export default {
  name: "learnerView",
  components: {
    backDashboard,
    userLogout,
    menuSidebar,
    headerTitle,
    videoResourcesDashboard,
  },
  data() {
    return {
      instructorResources: [],
      searching: false,
      searchQuery: "",
      section: ''
    };
  },
  methods: {
    searchInstructorResourceVideos() {
      utilFunctionService.showLoader();
      api.searchInstructorResourceVideos(this.searchQuery).then((res) => {
        utilFunctionService.hideLoader();
        this.instructorResources = res.data.data;
        this.searching = true;
      });
    },
    getResourceVideos() {
      utilFunctionService.showLoader();
      api.getInstructorResourceVideos().then((res) => {
        this.instructorResources = res.data.data;
        utilFunctionService.hideLoader();
      });
    },
    resetSearch() {
      this.searching = false;
      this.searchQuery = "";
      this.getResourceVideos();
    },
  },
  created() {
    this.section = JSON.parse(localStorage.getItem("section"));
    this.getResourceVideos();
  },
};
</script>


<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";

.resource-search {
  background: #ffffff;
  padding: 0 16px;
  margin: 0;
}

.main-title {
  margin-bottom: 32px;
}

</style>
