const state = () => ({
    userHasBeenGraded: false
})

// getters
const getters = {
    hasUserBeenGraded: (getters) => {
        return getters.userHasBeenGraded;
    }
}

// mutations
const mutations = {
    updateUserGradeStatus(state, userGrade) {
        state.userHasBeenGraded = userGrade
    }
}
const actions = {
    setUserGradeStatus({commit}, userGrade) {
        commit('updateUserGradeStatus', userGrade)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}