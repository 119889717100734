<template>
  <div class="container-full">
    <div class="top-header">
      <backDashboard />
      <headerTitle :moduleDetails="section" />
      <userLogout />
    </div>
    <div class="site-content">
      <menuSidebar :moduleID="this.section_details.id" />
      <div class="main-content pt-4">
        <div class="main-title align-items-center">
          <h3>Learner Central</h3>
          <div class="main-searchForms">

            <!-- Modules dropdown Start -->

            <div class="search-dropdown" v-if="modules_all.length > 1">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">{{ currentModule.attributes.name }}</button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                <a class="dropdown-item" type="button" @click="toggleSort(sortColumn, sortOrder, '')"></a>
                <a v-for="(mods, index) in modules_all" :key="index" class="dropdown-item" type="button"
                  @click="getSelectedModules(index)">
                  {{ mods.attributes.name }}
                </a>
              </div>
            </div>

            <!-- Modules dropdown End -->

            <div class="search-dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">{{ lo_index ? section_lo_list[lo_index - 1].name :
                'All' }}</button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <a v-if="lo_index" class="dropdown-item" type="button"
                  @click="toggleSort(sortColumn, sortOrder, '')">All</a>
                <a v-for="(section, index) in section_lo_list" :key="section.id" class="dropdown-item" type="button"
                  @click="toggleSort(sortColumn, sortOrder, index + 1); lastFeedback()">{{ index + 1 }}- {{ section.name
                  }}</a>
              </div>
            </div>
            <div class="sendAll-feedback" v-if="learner_list.length != 0 &&
              lo_index &&
              (section_list[lo_index - 1].objectable_type === 'SubmissionLearnObj'
                || section_list[lo_index - 1].objectable_type === 'SubmissionEmailLearnObj')">
              <button @click="sendAllFeedback">Send all feedback</button>
              <div class="sendAll-feedback" v-if="learner_list.length != 0 &&
                lo_index &&
                (section_lo_list[lo_index - 1].objectable_type === 'SubmissionLearnObj'
                  || section_lo_list[lo_index - 1].objectable_type === 'SubmissionEmailLearnObj')">
                <button @click="sendAllFeedback" type="button" aria-haspopup="true" aria-expanded="false"
                  class="btn primary mr-3">Send all feedback</button>
                <p v-if="lastFeedbackDate">Last feedback as on {{ lastFeedbackDate }}</p>
              </div>-->
            </div>
            <div class="search-bar">
              <input type="text" aria-describedby="search_learner" placeholder="Search learners"
                v-model="search_learner" v-on:input="search" />
              <button>
                <em class="icon-search"></em>
              </button>
            </div>
          </div>
        </div>
        <div :class="data_col_class" v-if="totalPages.length > 0">
          <div class="data-head">
            <div class="data-col" @click="toggleSorting('name', sorting)" style="cursor:pointer">
              Name
              <span class="sorting">
                <a :class="['desc', sortColumn === 'name' && sortOrder === 'asc' ? 'active' : '']"></a>
                <a :class="['asc', sortColumn === 'name' && sortOrder === 'desc' ? 'active' : '']"></a>
              </span>
            </div>
            <!--<div class="data-col">
             Space for plagiarism icons 
          </div>-->
            <div class="data-col" @click="toggleSorting('progress', sorting)" style="cursor:pointer">
              Progress
              <span class="sorting">
                <a :class="['desc', sortColumn === 'progress' && sortOrder === 'asc' ? 'active' : '']"></a>
                <a :class="['asc', sortColumn === 'progress' && sortOrder === 'desc' ? 'active' : '']"></a>
              </span>
            </div>
            <div class="data-col" @click="toggleSorting('completed', sorting)" style="cursor:pointer">
              Completed
              <span class="sorting">
                <a :class="['desc', sortColumn === 'completed' && sortOrder === 'asc' ? 'active' : '']"></a>
                <a :class="['asc', sortColumn === 'completed' && sortOrder === 'desc' ? 'active' : '']"></a>
              </span>
            </div>
            <div class="data-col" @click="toggleSorting('student_id', sorting)" style="cursor:pointer"
              v-if="section.attributes.learner_id_on_registration">
              {{ section.attributes.learner_id_registration_label || 'Student ID' }}
              <span class="sorting">
                <a :class="['desc', sortColumn === 'student_id' && sortOrder === 'asc' ? 'active' : '']"></a>
                <a :class="['asc', sortColumn === 'student_id' && sortOrder === 'desc' ? 'active' : '']"></a>
              </span>
            </div>
            <div class="data-col" @click="toggleSorting('submission', sorting)" style="cursor:pointer">
              Submission
              <span class="sorting">
                <a :class="['desc', sortColumn === 'submission' && sortOrder === 'asc' ? 'active' : '']"></a>
                <a :class="['asc', sortColumn === 'submission' && sortOrder === 'desc' ? 'active' : '']"></a>
              </span>
            </div>
            <div class="data-col" @click="toggleSorting('messages', sorting)" style="cursor:pointer">
              Messages
              <span class="sorting">
                <a :class="['desc', sortColumn === 'messages' && sortOrder === 'asc' ? 'active' : '']"></a>
                <a :class="['asc', sortColumn === 'messages' && sortOrder === 'desc' ? 'active' : '']"></a>
              </span>
            </div>
            <div class="data-col" v-if="enable_grading && profile.roles.includes('admin-instructor')">
              Grade
            </div>
            <div class="data-col data-col-action" v-if="enable_grading && profile.roles.includes('admin-instructor')">
            </div>
          </div>
          <div class="data-content">
            <div class="data-row" v-for="(learner) in learner_list" :key="learner.id">
              <div class="data-col">
                <router-link :to="`/learnerCentralLo/${learner.id}`">
                  <div class="data-imagePhoto">
                    <div class="data-image">
                      <img v-if="learner.attributes.user.attributes.profile_url"
                        :src="learner.attributes.user.attributes.profile_url" alt
                        onload="this.parentNode.style.opacity=1;" />
                    </div>
                    <div class="data-imageTitle">
                      {{ learner.attributes.user.attributes.last_name }},
                      {{ learner.attributes.user.attributes.first_name }}
                    </div>
                    <plagiarism-icon :amount="learner.attributes.total_plagiarism_count"
                      v-if="learner.attributes.total_plagiarism_count > 0" />
                  </div>
                </router-link>
              </div>

              <div class="data-col">
                <div class="data-progress">
                  <div class="progress">
                    <div :class="['progress-bar', learner.attributes.complete_percentage < 25 ? 'bg-danger' : '']"
                      role="progressbar" :style="{ width: [learner.attributes.complete_percentage + '%'] }"
                      aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div :class="['progress-count']" v-if="learner.attributes.complete_percentage < 100">
                    {{ learner.attributes.complete_percentage }}%</div>
                  <div class="progress-count" v-else>
                    <img src="../assets/images/check-circle.png" alt="check circle" />
                  </div>
                </div>
              </div>
              <div class="data-col">
                <div class="data-status">
                  {{ learner.attributes.completed_count }}/{{ learner.attributes.active_user_learn_objs_count }}</div>
              </div>
              <div class="data-col" v-if="section.attributes.learner_id_on_registration">
                <div>{{ learner.attributes.id_for_learner_customer }}</div>
              </div>

              <div class="data-col">
                <router-link :to="`/learnerCentralLo/${learner.id}`">
                  <div :class="['data-statusMessage',
                    learner.attributes.submission_notifications === 0 ||
                      learner.attributes.submission_notifications == null ? 'disabled' : '']">
                    <img src="../assets/images/submission-green.svg" alt="submission" />
                    <span>{{ learner.attributes.submission_notifications }}</span>
                  </div>
                </router-link>
              </div>
              <div class="data-col">
                <router-link :to="`/learnerCentralLo/${learner.id}`">
                  <div
                    :class="['data-statusMessage',
                      learner.attributes.message_notifications === 0 || learner.attributes.message_notifications == null ? 'disabled' : '']">
                    <img src="../assets/images/message-green.svg" alt="message" />
                    <span>{{ learner.attributes.message_notifications }}</span>
                  </div>
                </router-link>
              </div>
              <!-- {{learner.id}} -->
              <div class="data-col"
                v-if="enable_grading && learner.attributes.completed && profile.roles.includes('admin-instructor')">
                <a @click="$modal.show('GradingSetupModal', {
                  userSectionID: learner.id,
                  user_first_name: learner.attributes.user.attributes.first_name,
                  user_last_name: learner.attributes.user.attributes.last_name
                })">{{ learner.attributes.elm_grade }}</a>
              </div>
              <div class="data-col"
                v-if="enable_grading && !learner.attributes.completed && profile.roles.includes('admin-instructor')">
                In Progress
              </div>
              <div class="data-col data-col-action"
                v-if="enable_grading && learner.attributes.completed && profile.roles.includes('admin-instructor')">
                <div class="view-grading-report">
                  <a @click="$modal.show('GradingSetupModal', {
                    userSectionID: learner.id,
                    user_first_name: learner.attributes.user.attributes.first_name,
                    user_last_name: learner.attributes.user.attributes.last_name
                  })">View Grading Report</a>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div class="data-pagination" v-if="totalPages.length > 1">
          <div class="results">Showing {{ page }} of {{ totalPages.length }}</div>
          <ul>
            <li class="prev" v-if="page !== 1">
              <a @click="prevPage">
                <em class="icon-prev"></em>
              </a>
            </li>
            <li v-for="pages in totalPages.slice(page - 1, page + 3)" :key="pages"
              :class="[parseInt(pages) === parseInt(page) ? 'active' : '']">
              <a @click="getLearnerDetails(1, pages)">{{ pages }}</a>
            </li>
            <li class="next" v-if="page < totalPages.length">
              <a @click="nextPage">
                <em class="icon-next"></em>
              </a>
            </li>
          </ul>
        </div>
        <div class="data-no-result" v-if="learner_list.length === 0 && !searchStatus && display">
          <h3>There’s nothing here!</h3>
          <p>You have no Learner in the system yet.</p>
        </div>
        <div class="data-no-result" v-if="learner_list.length === 0 && searchStatus">
          <h3>No results found</h3>
          <p>Try using a different keyword</p>
        </div>
      </div>
      <!-- grading setup modal starts here -->
      <gradeDetailsView />
      <!-- grading modal start end-->
    </div>
  </div>
</template>
<script>
import menuSidebar from "../components/menuSidebar.vue";
import headerTitle from "../components/headerTitle.vue";
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import plagiarismIcon from "../components/plagiarismIcon.vue";
import gradeDetailsView from "../components/gradeDetailsView.vue";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import { mapState } from 'vuex';

export default {
  name: "learnerCentral",
  components: {
    backDashboard,
    userLogout,
    menuSidebar,
    headerTitle,
    plagiarismIcon,
    gradeDetailsView
  },
  data() {
    return {
      modules_all: [],
      learner_list: [],
      section_lo_list: [],
      section_details: {
        id: ''
      },
      section: null,
      totalPages: [],
      instructorInfo: [],
      page: 1,
      profile: [],
      total_learner: "",
      search_learner: "",
      sorting: "desc",
      sortOrder: "asc",
      sortColumn: "name",
      searchStatus: false,
      display: false,
      lo_index: "",
      lastFeedbackDate: "",
      modules: {
        id: ''
      },
      data_col_class: '',
      enable_grading: '',
      user_first_name: '',
      user_last_name: '',
      learn_mod_name: '',
      overall_grade_label: '',
      overall_grade_percentage: '',
      learnerSelectedIndex: [],
      showGradeModal: false
    };
  },
  computed: mapState({
    currentModule: state => state.learnerCentralStore.currentModule
  }),
  methods: {
    getSelectedModules(index) {
      this.currentModule.index = index
      this.lo_index = 0
      let section_lo_list_temp = []
      api
        .getLearnerDetails(
          this.section.id,
          this.page,
          this.search_learner,
          this.sortColumn,
          this.sortOrder,
          this.lo_index,
          this.modules_all[this.currentModule.index].id
        )
        .then(res => {
          console.log(res, "*** res in getLearnerDetails");
          this.display = true;
          this.learner_list = res.data.data;
          this.section_details = res.data.section;
          api
            .getSectionLo(this.section.id)
            .then(res => {
              this.section_lo_list_temp = []
              for (let i = 0; i < res.data.lo_list.length; i++) {
                if (res.data.lo_list[i].learn_mod_id === parseInt(this.modules_all[index].id)) {
                  section_lo_list_temp.push(res.data.lo_list[i])
                }
              }
            })
            .catch(() => { });
          this.searchStatus = false;
          this.total_learner = res.data.filtered_count;
          this.totalPages = [];
          if (res.data.data.length > 0)
            for (let ind = 1; ind <= res.data.total_pages; ind++) {
              this.totalPages.push(ind);
            }
        })
        .catch(() => { });
      this.section_lo_list = section_lo_list_temp
      this.selectModuleName(this.modules_all[index], this.currentModule.index)
    },

    showGradingModal() {
      this.$modal.show("GradingSetupModal");
    },
    selectModuleName(module, learnModId) {
      module["index"] = learnModId;
      this.$store.dispatch('learnerCentralStore/setCurrentModule', module)
    },
    sendAllFeedback() {
      utilFunctionService.showLoader();
      const _fb = new FormData();
      _fb.append("section_id", this.section.id);
      _fb.append("learning_object_id", this.section_lo_list[this.lo_index - 1].id)
      api
        .sendFeedback(_fb)
        .then((res) => {
          if (res.status === 200) {
            utilFunctionService.showSuccess(
              commonConfig.appMessages.feedbackSendSuccessfully
            );
            this.lastFeedback();
          }
          utilFunctionService.hideLoader();
        })
        .catch(() => { })
    },
    getLearnerDetails(id, page) {
      this.page = page;
      utilFunctionService.showLoader();
      api
        .getLearnerDetails(
          this.section.id,
          page,
          this.search_learner,
          this.sortColumn,
          this.sortOrder,
          this.lo_index,
          this.modules_all[this.currentModule.index].id
        )
        .then(res => {
          this.display = true;
          this.learner_list = res.data.data;
          this.section_details = res.data.section;
          this.searchStatus = false;
          this.total_learner = res.data.filtered_count;
          this.totalPages = [];
          if (res.data.data.length > 0) {
            for (let ind = 1; ind <= res.data.total_pages; ind++) {
              this.totalPages.push(ind);
            }
          }
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });

    },
    getLearnerDetailsSectionLo(id, page) {
      this.page = page;
      utilFunctionService.showLoader();
      api
        .getLearnerDetails(
          id,
          page,
          this.search_learner,
          this.sortColumn,
          this.sortOrder,
          this.lo_index,
          this.modules_all[this.currentModule.index].id
        )
        .then(res => {
          this.display = true;
          this.learner_list = res.data.data;
          this.section_details = res.data.section;
          this.selectModuleName(this.section_details.attributes.learn_mod.attributes, this.currentModule.index);
          utilFunctionService.hideLoader();
          api
            .getSectionLo(this.section.id)
            .then(res => {
              this.section_lo_list = []
              for (let i = 0; i < res.data.lo_list.length; i++) {
                if (res.data.lo_list[i].learn_mod_id === parseInt(this.section_details.attributes.learn_mod.id)) {
                  this.section_lo_list.push(res.data.lo_list[i])
                }
              }
            })
            .catch(() => { });
          this.searchStatus = false;
          this.total_learner = res.data.filtered_count;
          this.totalPages = [];
          if (res.data.data.length > 0)
            for (let ind = 1; ind <= res.data.total_pages; ind++) {
              this.totalPages.push(ind);
            }
        })
        .catch(() => { });
    },
    lastFeedback() {
      api
        .lastSendAllFeedbackDate(this.section.id, this.section_lo_list[this.lo_index - 1].id)
        .then((res) => {
          this.lastFeedbackDate = res.data.sent_at;
        })
        .catch(() => { });
    },
    getLoList() {
      api
        .getSectionLo(this.section.id)
        .then(res => {
          this.section_lo_list = res.data.lo_list;
        })
        .catch(() => { });
    },
    getProfile() {
      console.log(this.instructorInfo.id, "*** this.instructorInfo.id");
      api
        .getInstructorProfile(this.instructorInfo.id)
        .then((res) => {
          this.profile = res.data.data.attributes;
          console.log(this.profile, "*** this.profile");
          this.checkRole();
        })
        .catch(() => { });
    },
    checkRole() {
      if (this.profile.roles.includes("admin-instructor")) {
        console.log("*** hit admin-instructor in getProfile");
        this.data_col_class = "data-table column-6"
      } else {
        console.log("*** hit else in getProfile");
        this.data_col_class = "data-table column-5-default"
      }
    },
    getMultiELMList() {
      api
        .getMultiELMList(this.section.id)
        .then(res => {
          let learn_mods = res.data.data.attributes.learn_mods
          learn_mods.sort(function (a, b) {
            return a.id - b.id;
          });
          this.modules_all = learn_mods
          if (
            this.currentModule &&
            Object.keys(this.currentModule).length === 0 &&
            Object.getPrototypeOf(this.currentModule) === Object.prototype
          ) {
            this.selectModuleName(this.modules_all[0], 0);
          }
          utilFunctionService.showLoader();
          api
            .getLearnerDetails(
              this.section.id,
              this.page,
              this.search_learner,
              this.sortColumn,
              this.sortOrder,
              this.lo_index,
              this.modules_all[this.currentModule.index].id
            )
            .then(res => {
              console.log(res.data, "*** res.data in getMultiELMList");
              this.display = true;
              this.learner_list = res.data.data;
              this.section_details = res.data.section;
              utilFunctionService.hideLoader();
              api
                .getSectionLo(this.section.id)
                .then(res => {
                  this.section_lo_list = []
                  for (let i = 0; i < res.data.lo_list.length; i++) {
                    if (res.data.lo_list[i].learn_mod_id === parseInt(this.section_details.attributes.learn_mod.id)) {
                      this.section_lo_list.push(res.data.lo_list[i])
                    }
                  }
                })
                .catch(() => { });
              this.searchStatus = false;
              this.total_learner = res.data.filtered_count;
              this.totalPages = [];
              if (res.data.data.length > 0)
                for (let ind = 1; ind <= res.data.total_pages; ind++) {
                  this.totalPages.push(ind);
                }
            })
            .catch(() => { });
        })
        .catch(() => { });
    },
    search() {
      api
        .getLearnerDetails(
          this.section.id,
          commonConfig.setting.searchPage,
          this.search_learner,
          this.sortColumn,
          this.sortOrder,
          this.lo_index,
          this.modules_all[this.currentModule.index].id
        )
        .then(res => {
          this.searchStatus = true;
          this.learner_list = res.data.data;
          this.total_learner = res.data.filtered_count;
          this.totalPages = [];
          if (res.data.data.length > 0)
            for (let ind = 1; ind <= res.data.total_pages; ind++) {
              this.totalPages.push(ind);
            }
        })
        .catch();
    },
    nextPage() {
      this.page = this.page + 1;
      this.getLearnerDetails(1, this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getLearnerDetails(1, this.page);
    },
    toggleSort(colName, sortType, index) {
      let _colName = colName;
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.sortColumn = _colName;
      this.lo_index = index ? index : "";
      this.getLearnerDetails(this.section.id, this.page);
    },
    toggleSorting(colName, sortType) {
      this.sortOrder = sortType;
      this.sorting = this.sortOrder == 'asc' ? 'desc' : 'asc';
      this.sortColumn = colName;
      this.columnSorting = this.sortColumn;
      this.getLearnerDetails(this.section.id, this.page);
    },
  },
  created() {
    setTimeout(() => {
      this.section = JSON.parse(localStorage.getItem("section"));
      this.module = this.section

      this.modules = JSON.parse(localStorage.getItem("module"));
      let learn_mod_attr = this.modules.attributes.learn_mods[0].attributes;
      this.enable_grading = learn_mod_attr.enable_grading;
      this.instructorInfo = JSON.parse(localStorage.getItem("instructor")).users;
      this.getProfile();
      this.getMultiELMList();
    }, 1000)
  }
};
</script>

<style lang="scss" scoped>
.plagiarismIcon {
  width: 20px;
  height: 20px;
}

.square {
  position: absolute;
  width: 20px;
  height: 20px;
  background: red;
}

.square2 {
  transform: rotate(45deg);
}

.plagiarismCount {
  position: absolute;
  color: white;
  font-weight: bold;
  left: 20px;
}
</style>
