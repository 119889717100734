<template>
  <modal
    name="resetLOConfirmModal"
    class="modal-bottomScreen"
    width="100%"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <div class="modal-content modal-grade-content">
      <button
        type="button"
        class="modal-close"
        @click="$modal.hide('resetLOConfirmModal')"
      >
        <em class="icon-close"></em>
      </button>
      <div class="modal-body mx-auto">
        <div class="modal-title">
          <h1 class="mb-4">Are you sure? This action cannot be reversed.</h1>
        </div>
        <div>
          <h2 class="mb-4">The student's work will be deleted permanently.</h2>
          <div class="d-flex flex-row justify-content-between">
            <button class="btn btn-danger" @click="resetTask">
              Confirm Reset Task
            </button>
            <button class="btn primary" @click="$modal.hide('resetLOConfirmModal')"
              >Close Overlay</button
            >
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import api from "@/services/api";
export default {
  name: "ResetLoConfirmModal",
  data() {
    return {
      cardType: "",
      loDetails: {}
    }
  },
  methods: {
    beforeOpen(event) {
      this.cardType = event.params.card_type;
      this.loDetails = event.params.loDetails;
    },
    resetTask() {
      let loID;
      if (this.cardType === "dialogic") {
        loID = this.loDetails.attributes.current_evaluation_id;
        api
          .resetDialogic(loID)
          .then(() => {
            this.$modal.hide("resetLOConfirmModal");
            const firstName =
              this.loDetails.attributes.user_section.attributes.user.attributes
                .first_name;
            const lastName =
              this.loDetails.attributes.user_section.attributes.user.attributes
                .last_name;
            utilFunctionService.showSuccess(
              `This task has been reset. A notification email has been sent to ${firstName} ${lastName}`
            );
            location.reload();
          })
          .catch(() => {
            console.log("An error has occurred");
          });
      } else if (this.cardType === "email") {
        loID = this.loDetails.attributes.current_evaluation_id;
        api
          .resetEmail(loID)
          .then(() => {
            this.$modal.hide("resetLOConfirmModal");
            const firstName =
              this.loDetails.attributes.user_section.attributes.user.attributes
                .first_name;
            const lastName =
              this.loDetails.attributes.user_section.attributes.user.attributes
                .last_name;
            utilFunctionService.showSuccess(
              `This task has been reset. A notification email has been sent to ${firstName} ${lastName}`
            );
            location.reload();
          })
          .catch(() => {
            console.log("An error has occurred");
          });
      } else if (this.cardType === "quiz") {
        loID = this.loDetails.attributes.current_evaluation_id;
        api
          .resetQuiz(loID)
          .then(() => {
            this.$modal.hide("resetLOConfirmModal");
            const firstName =
              this.loDetails.attributes.user_section.attributes.user.attributes
                .first_name;
            const lastName =
              this.loDetails.attributes.user_section.attributes.user.attributes
                .last_name;
            utilFunctionService.showSuccess(
              `This task has been reset. A notification email has been sent to ${firstName} ${lastName}`
            );
            location.reload();
          })
          .catch(() => {
            console.log("An error has occurred");
          });
      } else if (this.cardType === "submission_email") {
        loID = this.loDetails.id;
        api
          .resetEmailSubmission(loID)
          .then(() => {
            this.$modal.hide("resetLOConfirmModal");
            const firstName =
              this.loDetails.attributes.user_section.attributes.user.attributes
                .first_name;
            const lastName =
              this.loDetails.attributes.user_section.attributes.user.attributes
                .last_name;
            utilFunctionService.showSuccess(
              `This task has been reset. A notification email has been sent to ${firstName} ${lastName}`
            );
            location.reload();
          })
          .catch(() => {
            console.log("An error has occurred");
          });
      } else if (this.cardType === "submission") {
        loID = this.loDetails.id;
        api
          .resetSubmission(loID)
          .then(() => {
            this.$modal.hide("resetLOConfirmModal");
            const firstName =
              this.loDetails.attributes.user_section.attributes.user.attributes
                .first_name;
            const lastName =
              this.loDetails.attributes.user_section.attributes.user.attributes
                .last_name;
            utilFunctionService.showSuccess(
              `This task has been reset. A notification email has been sent to ${firstName} ${lastName}`
            );
            location.reload();
          })
          .catch(() => {
            console.log("An error has occurred");
          });
      }
    },
  },
};
</script>
