<template>
  <div class="top-userLogout">
    <div class="dropdown">
      <div class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="!profileImage">
      </div>
       <div class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="profileImage">
         <img :src="profileImage">
      </div>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <router-link class="dropdown-item" to="/profile">Profile</router-link>
        <a class="dropdown-item" href="javascript:void(0);" v-if="!sso_authenticated" @click="logOut">Log Out</a>
      </div>
    </div>
  </div>
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";

export default {
  name: "userLogout",
  data() {
    return {
      profileImage: '',
      sso_authenticated: false
    };
  },
  methods: {
    logOut() {
       utilFunctionService.removeLocalStorageService("instructor");
       this.$router.push({ name: "login" });
     }
  },
  created() {
    this.profileImage = JSON.parse(localStorage.getItem('profile_url'));
    this.sso_authenticated = JSON.parse(localStorage.getItem('sso_authenticated'));
  }
};
</script>
