const state = () => ({
    currentModule: {
    }
})

// getters
const getters = {
    getCurrentModule: () => {
        return state.currentModule;
    }
}

// mutations
const mutations = {
    updateCurrentModule(state, moduleName) {
        state.currentModule = moduleName
    }
}
const actions = {
    setCurrentModule({commit}, moduleName) {
        commit('updateCurrentModule', moduleName)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}