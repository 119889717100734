<template>
  <div id="login" class="login-container" v-if="!this.$route.query.token">
    <loginSidebar />
    <div class="login-right">
      <div class="login-form">
        <ValidationObserver ref="form">
          <form @keyup.enter="submitForm">
            <div class="form-group">
              <h3>Log In to your account</h3>
              <p>Enter your details below</p>
            </div>
            <div class="form-group">
              <label>Email Address</label>
              <ValidationProvider name="Email address" rules="required" v-slot="{ errors }">
                <input type="text" @input="valueChange()" class="form-control" name="username" v-model="input.username" />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                >{{ errors[0].replace("The ", "" )}}</span>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <div class="d-flex">
                <label>Password</label>
                <router-link to="/forgotPassword" class="ml-auto forgot-link" href="javascript:void(0);">Forgot password?</router-link>
              </div>
              <div class="position-relative">
              <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                 <input
                  :type="passwordFieldType"
                  name="password"
                  class="form-control"
                  @input="valueChange()"
                  v-model="input.password"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                >{{ errors[0].replace("The ", "" )}}</span>
              </ValidationProvider>
                <a
                  class="forgot-show"
                  @click="switchVisibility"
                  v-if="passwordFieldType === 'password'"
                >Show</a>
                <a
                  class="forgot-show"
                  @click="switchVisibility"
                  v-if="passwordFieldType === 'text'"
                >Hide</a>
                <span class="invalid" v-if="invalidCred">Invalid Credentials</span>
              </div>
            </div>
            <div class="form-group pt-1 text-right">
              <button type="button" class="btn primary login-btn" @click="login()">Log In</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import loginSidebar from "../components/loginSidebar.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { commonConfig } from "@/utils/commonConfig";

export default {
  name: "Login-component",
  components: {
    ValidationProvider,
    ValidationObserver,
    loginSidebar
  },
  data() {
    return {
      input: {
        username: "",
        password: ""
      },
      isActive: "",
      invalidCred: false,
      passwordFieldType: "password"
    };
  },
   created() {
     if (this.$route.query && this.$route.query.id && this.$route.query.token != null){
        this.ltiUserId = this.$route.query.id;
        let token = this.$route.query.token;
         const _fb = new FormData();
         _fb.append("user[id]", this.ltiUserId);
         _fb.append("user[launch_token]", token);
           api
        .loginLtiUser(_fb)
        .then(res => {
          const result = JSON.stringify(res["data"]);
          utilFunctionService.setLocalStorageService("instructor", result);
          this.$router.replace({ name: 'active'});
        })
     }
  },
  methods: {
    submitForm() {
      this.login();
    },
    valueChange() {
      this.invalidCred = false;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    login() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const user = {
            email: this.input.username,
            password: this.input.password
          };
          api
            .loginUser(user)
            .then(res => {
              utilFunctionService.showSuccess(
                  commonConfig.appMessages.loginSuccess
              );
              utilFunctionService.setLocalStorageService(
                "instructor",
                JSON.stringify(res.data)
              );
              if (this.$route.query.name === 'learnerCentralLo' && this.$route.query.id !== 'undefined' && this.$route.query.loId !== 'undefined' && this.$route.query.moduleId !== 'undefined') {
                this.$router.replace({ name: "learnerCentralLo",
                params: {
                    id: `${this.$route.query.id}`,
                    loId: `${this.$route.query.loId}`,
                    moduleId: `${this.$route.query.moduleId}`
                  }
                });
                } else if (this.$route.query && this.$route.query.name) {
                  this.$router.replace({ name: `${this.$route.query.name}`});
                } else {
                  this.$router.replace({ name: 'active'});
                }
            })
            .catch(() => {
              this.invalidCred = true;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.login-btn {
  max-width: 200px;
}
</style>