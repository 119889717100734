var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-container"},[_c('dashboardSidebar',{attrs:{"instructorInfo":_vm.instructorInfo,"profile":_vm.profile}}),_c('div',{staticClass:"container"},[_c('div',{class:[_vm.modules.length > 0 ? '' : 'dashboard-no-data', 'dashboard']},[_c('div',{staticClass:"dashboard-active-content"},[_c('div',{staticClass:"dashboard-active-head"},[_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('h2',[_vm._v(_vm._s(_vm._f("upperCase")(_vm.type,true))+" Sections")]),_c('div',{staticClass:"dashboard-active-info"},[(_vm.searching)?_c('button',{staticClass:"btn reset_btn",on:{"click":function($event){return _vm.resetSearch()}}},[_vm._v(" Reset search ")]):_vm._e(),(_vm.profile.roles.includes('admin-instructor'))?_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"form-control course-search",attrs:{"type":"text","placeholder":"Search by course name"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value}}}),_c('em',{staticClass:"icon-search",on:{"click":function($event){return _vm.searchModules()}}})]):_vm._e(),(_vm.sectionsLength > 0 && _vm.sectionsLength < 10 && _vm.display)?_c('h3',[_vm._v(" 0"+_vm._s(_vm.sectionsLength)+" ")]):(
                  _vm.sectionsLength > 0 && _vm.sectionsLength >= 10 && _vm.display
                )?_c('h3',[_vm._v(" "+_vm._s(_vm.sectionsLength)+" ")]):_vm._e()])])]),_c('div',{staticClass:"dashboard-active-list"},[(_vm.modules.length === 0 && _vm.display)?_c('div',{staticClass:"dashboard-no-text"},[(_vm.type === 'active' || _vm.type === 'completed')?_c('p',[_vm._v(" You don't have any "+_vm._s(_vm.type)+" sections. ")]):_c('p',[_vm._v("You don't have any "+_vm._s(_vm.type)+" sections")])]):_vm._e(),(_vm.modules.length > 0 && _vm.display)?_c('div',[_c('VueSlickCarousel',_vm._b({key:_vm.carouselKey,ref:"carousel",staticClass:"dashboard-carousel",on:{"beforeChange":function($event){return _vm.checkNext()}},scopedSlots:_vm._u([{key:"prevArrow",fn:function(){return [_c('div',{staticClass:"custom-arrow"})]},proxy:true},{key:"nextArrow",fn:function(){return [_c('div',{staticClass:"custom-arrow",on:{"click":function($event){return _vm.checkNext()}}})]},proxy:true}],null,false,462491101)},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.modules),function(item){return _c('div',{key:item.id},[_c('router-link',{staticClass:"dashboard-active-item",attrs:{"to":`/learnerCentral/${item.id}`},on:{"click":function($event){return _vm.setModuleData(item)}}},[(
                      item && item.attributes && item.attributes.learn_mods
                    )?_c('div',{staticClass:"dashboard-active-item"},[_c('div',{staticClass:"dashboard-active-img",on:{"click":function($event){return _vm.setModuleData(item)}}},[(
                          item.attributes.learn_mods[0].attributes.photo_url
                            .large_version
                        )?_c('img',{attrs:{"src":item.attributes.learn_mods[0].attributes.photo_url
                            .large_version,"alt":"image"}}):_vm._e()]),_c('div',{staticClass:"dashboard-active-content",on:{"click":function($event){return _vm.setModuleData(item)}}},[_c('div',{staticClass:"dashboard-active-title"},[_vm._v(" "+_vm._s(item.attributes.name)+" ")]),(item.attributes.time_started)?_c('div',{staticClass:"dashboard-calender"},[_c('em',{staticClass:"icon-calender"}),_vm._v(" "+_vm._s(item.attributes.time_started)+" - "+_vm._s(item.attributes.time_completed)+" ")]):_vm._e(),_c('ul',[_c('li',[_vm._v(" Learners: "),_c('strong',[_vm._v(_vm._s(item.attributes.user_sections_count))])]),_c('li',[_vm._v(" Completed: "),_c('strong',[_vm._v(_vm._s(item.attributes.elm_completed_count))])])]),_c('ul',[_c('li',[_vm._v(" Modules: "),_c('strong',[_vm._v(_vm._s(item.attributes.elm_count))])])])]),_c('div',{staticClass:"dashboard-badge"},[(item.attributes.message_notifications)?_c('div',{staticClass:"dashboard-badge-item"},[_c('img',{attrs:{"src":require("../assets/images/messages-white.svg")}}),_vm._v(_vm._s(item.attributes.message_notifications)+" ")]):_vm._e(),(item.attributes.submission_notifications > 0)?_c('div',{staticClass:"dashboard-badge-item"},[_c('img',{attrs:{"src":require("../assets/images/submission-white.svg")}}),_vm._v(_vm._s(item.attributes.submission_notifications)+" ")]):_vm._e()])]):_vm._e()])],1)}),0)],1):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }