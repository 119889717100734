<template>
  <div id="register" class="register-container">
    <dashboardSidebar :instructorInfo="instructorInfo" :profile="profile" />
    <div class="register-form">
      <h1>Support</h1>
      <ValidationObserver ref="form">
        <form class="w-100">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <ValidationProvider
                  name="Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    name="name"
                    disabled
                    class="form-control"
                    v-model="input.name"
                    placeholder="Enter Name"
                    :class="[isActive && errors[0] ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span
                  >
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <ValidationProvider
                  name="Email"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    name="email"
                    disabled
                    class="form-control"
                    v-model="input.email"
                    placeholder="Enter email"
                    :class="[isActive && errors[0] ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span
                  >
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="form-group">
            <ValidationProvider
              name="Module Name"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                class="form-control"
                type="text"
                placeholder="Enter Module"
                v-model="input.moduleName"
                :class="[isActive && errors[0] ? 'invalid' : '']"
              />
              <span
                :class="[isActive ? 'invalid' : '']"
                :style="[isActive ? { display: 'block' } : { display: 'none' }]"
                v-if="errors[0]"
                >{{ errors[0].replace("The ", "") }}</span
              >
            </ValidationProvider>
          </div>

          <div class="form-group textarea-group contact-us">
            <ValidationProvider
              name="Message"
              rules="required"
              v-slot="{ errors }"
            >
              <vue-editor
                name="description"
                placeholder="Add Message"
                :editorToolbar="fullTool"
                v-model="input.description"
              ></vue-editor>
              <span
                :class="[isActive ? 'invalid' : '']"
                :style="[isActive ? { display: 'block' } : { display: 'none' }]"
                v-if="errors[0]"
                >{{ errors[0].replace("The ", "") }}</span
              >
            </ValidationProvider>
          </div>
          <div class="submit-button">
            <div class="text-right">
              <button
                type="button"
                class="btn outline-primary"
                v-on:click="cancel()"
              >
                Cancel
              </button>
            </div>
            <div class="text-right">
              <button
                type="button"
                class="btn primary"
                v-on:click="contactUs()"
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>

    <modal name="cancelModal" height="auto" :width="550" :scrollable="true">
      <div class="modal-content modal">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Are you sure you want to cancel?</h5>
          </div>
        </div>
        <div class="modal-footer">
          <div class="data-delete-dropdown">
            <div class="data-popper-footer">
              <a class="btn default" @click="cancelClick()">Cancel</a>
              <a class="btn primary" @click="okClick()">Ok</a>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import api from "../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import dashboardSidebar from "@/components/dashboardSidebar.vue";
import { VueEditor } from "vue2-editor";

export default {
  name: "ContactUs",
  components: {
    dashboardSidebar,
    VueEditor,
    ValidationProvider,
    ValidationObserver,
  },
  props: [],
  data() {
    return {
      input: {
        name: "",
        email: "",
        moduleName: "",
        description: "",
      },
      url: process.env.VUE_APP_URL,
      isActive: "",
      showError: false,
      fullTool: [],
      instructorInfo: "",
      profile: ""
    };
  },
  created() {
    if (utilFunctionService.getLocalStorageService("instructor")) {
      const user_data =
        utilFunctionService.getLocalStorageService("instructor").users
          .attributes;
      this.instructorInfo = JSON.parse(localStorage.getItem("instructor")).users;
      this.input.email = user_data.email;
      if (user_data.last_name !== null) {
        this.input.name = `${user_data.first_name} ${user_data.last_name}`;
      } else {
        this.input.name = `${user_data.first_name}`;
      }
    }
  },
  methods: {
    cancel() {
      this.$modal.show("cancelModal");
    },
    cancelClick() {
      this.$modal.hide("cancelModal");
    },
    okClick() {
      this.$modal.hide("cancelModal");
      this.$router.replace({ name: "active" });
    },
    contactUs() {
      this.$refs.form.validate().then((success) => {
        this.isActive = true;
        if (success) {
          const contact_data = {
            module_name: this.input.moduleName,
            message: this.input.description,
            user_type: "instructor",
          };
          api
            .postContact(contact_data)
            .then((res) => {
              if (res && res.data) {
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.contactSuccess
                );
                setTimeout(() => {
                  this.$router.replace({ name: "active" });
                }, 2000);
              }
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                this.showError = true;
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.register-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  background-color: $white;
  @include media-breakpoint(sm) {
    background-color: $white;
  }
  .register-logo {
    width: 180px;
    margin: 0 auto;
    padding-bottom: 2em;
  }
  .register-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    border: 0;
    padding: 138px 50px 40px 50px;
    @include media-breakpoint(md) {
      width: 760px;
      background-color: $white;
      padding: 138px 50px 40px 50px;
      border: 0;
    }
    .form-group {
      input {
        height: 60px;
        border: 0;
        border-radius: 2px;
      }
      .form-control {
        background-color: #f2f8f6;
        &.form-control:disabled,
        .form-control[readonly] {
          background-color: #e9ecef;
          opacity: 1;
        }
      }
    }
  }
  .left-align {
    text-align: left;
  }
  h1 {
    @include font-black;
    font-size: 39px;
    line-height: 1.36;
    margin: 0 0 30px;
    text-align: left;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
  .textarea-group {
    margin-bottom: 40px;
  }
  .ql-snow {
    background-color: #cccccc !important;
  }
  .submit-button {
    display: flex;
    justify-content: flex-end;
    .text-right {
      .btn {
        @include font-bold;
        height: 50px;
        font-size: 14px;
        line-height: normal;
        padding: 10px;
        min-width: 130px;
      }
      .outline-primary {
        color: #848484;
      }
    }
  }
  .modal {
    max-width: 497px;
    margin: 0 auto;
    max-height: 750px;
    overflow-y: auto;
    padding: 40px;
    @include media-breakpoint(sm) {
      padding: 10px;
    }
    .modal-header {
      @include font-bold;
      line-header: 0.95;
      font-size: 21px;
      margin-bottom: 24px;
      text-align: center;
      h5 {
        @include font-black;
        font-size: 18px;
        line-height: 1.4;
      }
    }
    .modal-footer {
      padding: 20px 0 6px 10px;
      border: 0;
      padding: 0;
      .data-delete-dropdown {
        margin: 0;
        .data-popper-footer {
          display: flex;
          align-items: center;
          .btn {
            @include font-bold;
            font-size: 14px;
            height: 45px;
            padding: 10px 8px;
          }
          .default {
            min-width: 80px;
            margin-right: 20px;
            border: 0;
            color: #848484;
          }
          .primary {
            min-width: 100px;
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
  .modal-content {
    max-width: 290px;
    padding: 30px;
  }
}
.contact-us {
  .quillWrapper {
    .ql-snow {
      background-color: #f2f8f6;
    }
  }
}
</style>
