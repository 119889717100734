<template>
  <div class="dashboard-active-list">
    <div class="dashboard-no-text" v-if="videos.length === 0">
      <p>You don't have any instructor resources videos</p>
    </div>
    <div class="video-grid" v-else>
      <div class="dashboard-video-item" @click="openVideo(video)" v-for="video in videos" :key="video.id">
        <div class="dashboard-active-img">
          <img
            :src="video.wistia_thumbnail_url"
            v-if="video.wistia_thumbnail_url"
            alt="image"
          />
        </div>
        <div class="dashboard-active-content dashboard-active-video">
          <div class="dashboard-active-title">
            {{ video.title }}
          </div>
        </div>
      </div>
    </div>
    <video-modal></video-modal>
  </div>
</template>
<script>
import videoModal from "../components/modals/videoModal.vue";
export default {
  name: "VideoResourcesDashboard",
  props: ["videos"],
  components: {
    videoModal,
  },
  methods: {
    openVideo(video) {
      this.$modal.show("videoModal", {
        video: video,
      });
    },
  },
  mounted() {
    this.videos.forEach((video) => {
      let videoID = video.id.toString();
      if (this.$route.params.video_id === videoID) {
        this.openVideo(video);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.video-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.dashboard-video-item {
  display: block;
  width: 30%;
  margin-bottom: 32px;
  &:hover {
    cursor: pointer;
  }
}
.dashboard-active-video {
  background: $black;
  .dashboard-active-title {
    color: $white;
    font-size: 18px;
    padding: 16px;
    font-weight: bold;
  }
}
</style>
