<template>
  <modal
    name="writeAssessModal"
    class="modal-bottomScreen modal-writeBottomScreen"
    width="100%"
    height="auto"
    :scrollable="true"
    @opened="afterOpen"
    @closed="afterClose"
  >
    <div class="modal-content modal-write-assessment">
      <div class="modal-body m-auto">

        <div class="writeAssess-container">
          <div class="writeAssess-header">
            <h1>Writing Assessment: Coherence</h1>
            <button
              type="button"
              class="modal-close"
              @click="$modal.hide('writeAssessModal')"
            >
              <em class="icon-close"></em>
            </button>
          </div>
          <div class="writeAssess-sidebar">
            <div>
              <p>
                Coherence in writing is the logical bridge between words, sentences, and paragraphs. Coherent writing uses devices to connect ideas within each sentence and paragraph. Main ideas and meaning can be difficult for the reader to follow if the writing lacks coherence. 
              </p>
            </div>
            <div class="writeAssess-scores">
              <h2>Overall Score: {{ parseFloat(writing_assessment.attributes.score).toFixed(2) }}</h2>
              <h2>Sentence Score: {{ parseFloat(writing_assessment.attributes.score_concept).toFixed(2) }}</h2>
              <h2>Document Score: {{ parseFloat(writing_assessment.attributes.score_doc2vec).toFixed(2) }}</h2>
            </div>
            <div class="writeAssess-buttons">
              <button class="btn primary" @click="releaseToLearner()">
                {{
                  released_to_learner ? "Learner can view" : "Release to learner"
                }}
              </button>
              <button class="btn primary" @click="markReviewed()">
                {{ is_reviewed ? "Reviewed" : "Mark as reviewed" }}
              </button>
            </div>
          </div>
          <div class="writeAssess-body" v-html="assessed_writing" ref="assessed_writing_element">
            <writing-assessment-popup
              v-if="showPopup"
              :targetSentence="targetSentence"
            ></writing-assessment-popup>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import writingAssessmentPopup from "./writingAssessmentPopup.vue";
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
export default {
  components: { writingAssessmentPopup },
  name: "WritingAssessmentModal",
  props: {
    writing_assessment: Object
  },
  data() {
    return {
      showPopup: false,
      targetSentence: "",
      allText: "",
      id: 123,
      type: "writingAssessmentFeedback",
      overall_coherence: '',
      is_reviewed: '',
      released_to_learner: '', 
      assessed_writing: ""
    };
  },
  
  methods: {
    toggleMarking(event) {
      if (this.showPopup) {
        this.targetSentence = "";
        this.showPopup = false;
      } else {
        this.targetSentence = event.target.innerText;
        this.showPopup = true;
      }
    },
    markNotIncoherent(id) {
      console.log(id);
      const fd = new FormData();
      fd.append(
        "writing_assessment_sentence[instructor_flagged]", true
      );
      api 
        .updateWritingAssessment(id, fd)
        .then (() =>  {
          // remove highlight on sentence and change scores
        });
    },
		releaseToLearner() {
			console.log("*** hit releaseToLearner");
      const fd = new FormData();
      fd.append(
        "user_learn_obj[show_writing_assessment]", true
      );
      api
        .updateUserLearnObj(this.$parent.loDetails.id, this.$route.params.id, fd)
        .then(() => {
          this.released_to_learner = true;
          this.$parent.loDetails.attributes.show_writing_assessment = true;
          utilFunctionService.showSuccess(
              `This writing assessment is now available for the learner to view`
            );
        })

		},
    markReviewed() {
			console.log("*** hit markReviewed");
      const fd = new FormData();
      fd.append(
        "writing_assessment[instructor_reviewed]", true
      );
      api
        .updateWritingAssessment(this.$parent.loDetails.attributes.writing_assessment.id, fd)
        .then(() => {
          this.is_reviewed = true;
          this.$parent.loDetails.attributes.writing_assessment.attributes.instructor_reviewed = true;
          utilFunctionService.showSuccess(
              `This writing assessment has been marked as reviewed`
            );
        })

		},
    afterClose() {
      this.assessed_writing = "";
    },
    setupEventListeners() {
      this.$nextTick(() => {
        console.log(this.$refs);
        document.querySelectorAll(".highlight").forEach(item => {
          console.log("*** event listener added");
          console.log(item)
          item.addEventListener("click", this.toggleMarking);
        });
      });
    },
    afterOpen() {
      //const writeAssessBody = document.querySelector(".writeAssess-body");
			const writeAssessContainer = document.querySelector(".writeAssess-container");

      //let assessed_writing_div = document.createElement("div");
      this.released_to_learner = this.$parent.loDetails.attributes.show_writing_assessment;
      this.is_reviewed = this.$parent.loDetails.attributes.writing_assessment.attributes.instructor_reviewed;
      //console.log(this.release_to_learner, "*** this.release_to_learner in afterOpen()");

      this.writing_assessment.attributes.sentences.forEach((sentence) => {
        //console.log(sentence, "*** sentence");
        
        if (sentence.attributes.coherence == true) {
          //let node = document.createTextNode(sentence.attributes.sentence);
          //assessed_writing_div.appendChild(node);
          let replacer = sentence.attributes.sentence.replace("<p><br></p>","");
          this.assessed_writing = this.assessed_writing + " " + replacer
        } else {
          //let spanNode = document.createElement("span");
          //spanNode.setAttribute("class", "highlight");
          //let node = document.createTextNode(sentence.attributes.sentence);
          //spanNode.addEventListener("click", this.toggleMarking)
          //spanNode.appendChild(node);
          //assessed_writing_div.appendChild(spanNode);
          let replacer = sentence.attributes.sentence.replace("<p><br></p>","");
          this.assessed_writing = this.assessed_writing + "<span class='highlight' data-sentence-number='" + sentence.attributes.sentence_order + "'> " + replacer + "</span>"
        }

      });
      //writeAssessBody.appendChild(assessed_writing_div);
			writeAssessContainer.style.display = "flex";
      this.setupEventListeners();
      
    },
   
  },
};
</script>

<style lang="scss" scoped>
.modal-write-assessment {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 90vh;
  padding: 0 32px;
  overflow: scroll;

  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
 .vm--modal {
    position: absolute;
    bottom: 0px;
    top: unset !important;
  }

.writeAssess-container {
  display: none;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  flex-wrap: wrap;

  .writeAssess-header {
    width: 100%;
    margin-top: 1rem;
    h1 {
      font-size: 1.8rem;
      margin-bottom: 3rem;
    }
  }

  .writeAssess-sidebar {
    width: 25%;
    
    h2 {
      font-size: 16px;
      margin-bottom: 16px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
    }

    .writeAssess-scores {
      margin: 3rem 0;
    }

    .writeAssess-buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      button {
        margin-bottom: 1.6rem;
      }
    }
  }
  .writeAssess-body {
    display: flex;
		width: 75%;
    flex-direction: column;
    padding: 0 9rem;
    position: relative;
  }
}
</style>