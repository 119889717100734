<template>
      <div class="main-content">
          <div class="centre-align">
            <h3 class="coming-soon-title">Coming soon</h3>
            <div class="mt-4 txt-font">
               <p>This section will be launching soon.</p>
            </div>
        </div>
     </div>
</template>
<script>
    export default {
    name: "comingSoon",
    data() {
        return {
        };
    }
    };
</script>
<style scoped lang="scss">
    @import "../styles/variables.scss";
    @import "../styles/mixin.scss";
    .centre-align {
          text-align: center;
          padding-top: 160px;
    }
    .txt-font {
         font-size: 18px;
    }
    .coming-soon-title {
        //@include font-bold;
        //font-family: 'Dancing Script', cursive;
        font-size: 30px;
        //text-transform: capitalize;
  }
</style>
