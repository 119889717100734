<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
  @import "./styles/style.scss";
  .vm--overlay {
    background: rgba(0, 0, 0, 0.6) !important;
  }
  .vm--modal {
    background-color: transparent !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
</style>
