var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loList.length > 0)?_c('div',{staticClass:"main-subMenubar pt-0"},[_c('div',{staticClass:"learn-sidebar"},[_c('div',{staticClass:"learn-sidebar-line"}),_c('ul',{staticClass:"learn-sidebar-items"},_vm._l((_vm.loList),function(lo,index){return _c('li',{key:lo.id,staticClass:"learn-sidebar-item",class:[
          'learn-sidebar-item',
          lo.attributes.complete
            ? _vm.loDetails.id === lo.id
              ? 'item-completed'
              : 'item-complete'
            : _vm.loDetails.id === lo.id
            ? 'item-progress'
            : '',
        ]},[_c('div',{staticClass:"learn-sidebar-icon"}),_c('div',{staticClass:"learn-sidebar-content"},[_c('button',{on:{"click":function($event){return _vm.getloDetails(lo, index)}}},[_c('span',{staticClass:"learn-sidebar-number"},[_vm._v("0"+_vm._s(index + 1))]),(lo.attributes.complete)?_c('div',{staticClass:"learn-sidebar-status"},[_vm._v(" Completed "),_vm._m(0,true)]):_vm._e(),(
                !lo.attributes.complete && _vm.loDetails && _vm.loDetails.id == lo.id
              )?_c('div',{staticClass:"learn-sidebar-status"},[_vm._v(" IN PROGRESS "),_vm._m(1,true)]):_vm._e(),_c('div',{staticClass:"learn-sidebar-title"},[_vm._v(" "+_vm._s(lo.attributes.learning_object.attributes.name)+" "),(lo.attributes.plagiarism_detected === true)?_c('span',[_c('plagiarism-icon',{attrs:{"amount":lo.attributes.plagiarism_count}})],1):_vm._e()])]),_c('div',{staticClass:"main-menuNotification"},[(lo.attributes.message_notifications > 0)?_c('span',{staticClass:"notify-icon message"}):_vm._e(),(lo.attributes.submission_notifications > 0)?_c('span',{staticClass:"notify-icon submission"}):_vm._e()])])])}),0)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('em',{staticClass:"icon-caret-right"},[_c('img',{attrs:{"src":require("../assets/images/icon-caret-black.svg"),"alt":"carrot"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('em',{staticClass:"icon-caret-right"},[_c('img',{attrs:{"src":require("../assets/images/icon-caret-green.svg"),"alt":"right caret"}})])
}]

export { render, staticRenderFns }