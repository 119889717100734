<template>
  <div id="loginSidebar" class="login-left">
      <div class="login-logo">
        <img src="../assets/images/ametros-learning-logo-white.png" alt="logo">
      </div>
      <div class="login-info">
        <p>Building competency and confidence through online <strong>experiential learning.</strong></p>
      </div>
    </div>
</template>
<script>

export default {
  name: "loginSidebar",
  data() {
    return {
      url: process.env.VUE_APP_URL
    };
  },
  methods: {

  }
};
</script>

