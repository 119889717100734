<template>
  <div class="container-full">
    <div class="top-header">
      <backDashboard />
      <headerTitle :moduleDetails="moduleData" />
      <userLogout />
    </div>
    <div class="site-content">
      <menuSidebar />
      <div class="main-content">
        <div class="main-title">
          <h3>Default automatic message response to learners</h3>
        </div>
        <div class="messaging-section">
          <vue-editor v-model="defaultMsg"></vue-editor>
          <div class="messaging-row">
            <div class="messaging-checkbox">
              <div class="messaging-item">
                <label class="custom-checkbox">Enable learner orignated messages
                  <input type="checkbox" v-model="enableLearnerMsg" name="check1" id="check1">
                  <span for="check1" class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="messaging-btn">
              <button class="btn normal" @click="clear">Clear</button>
              <button class="btn primary" @click="saveMsgSetting">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerTitle from "../components/headerTitle.vue"
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import menuSidebar from "../components/menuSidebar.vue";
import { VueEditor } from "vue2-editor";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";

export default {
  name: "messaging-component",
  components: {
    backDashboard,
    userLogout,
    menuSidebar,
    VueEditor,
    headerTitle
  },
  data() {
    return {
      defaultMsg: '',
      enableLearnerMsg : false,
      moduleData: {}
    };
  },
  methods: {
    getDefaultResponse() {
      utilFunctionService.showLoader();
      this.moduleData = JSON.parse(localStorage.getItem('section'));
      api
        .getResponse(this.moduleData.id)
        .then((res) => {
          utilFunctionService.hideLoader();
          this.defaultMsg = res.data.data.attributes.sub_auto_response_override;
          this.enableLearnerMsg = res.data.data.attributes.learner_org_msg;
        })
        .catch(() => {})
    },
    clear() {
      this.defaultMsg = '';
    },
    saveMsgSetting() {
       const fd = new FormData();
         fd.append("section[sub_auto_response_override]", this.defaultMsg);
         fd.append(
           "section[learner_org_msg]",
           this.enableLearnerMsg
         );
      api
        .updateMsgSetting(this.moduleData.id, fd)
        .then(() => {
           utilFunctionService.showSuccess(commonConfig.appMessages.messageSetting);
        })
        .catch(() => {})
    }
  },
  created() {
    this.getDefaultResponse();
  }
};
</script>
