import Vue from 'vue'
import Router from 'vue-router'
import login from '../views/Login'
import dashboard from '../views/dashboard'
import resetPassword from '../views/resetPassword'
import forgotPassword from '../views/forgotPassword'
import createNewPassword from '../views/createNewPassword'
import milestoneDates from '../views/milestoneDates'
import manageRubric from '../views/manageRubric'
import editProfile from '../views/editProfile'
import isLoggedin from './middleware/isLoggedIn'
import middlewarePipeline from './middlewarePipeline'
import messaging from '../views/messaging'
import learnerCentralLo from '../views/learnerCentralLo'
import learnerCentral from '../views/learnerCentral'
import learnerView from '../views/learnerView'
import resourcesView from "../views/resourcesView"
import submissionLo from '../views/submissionLo'
import learnerSubmission from '../views/learnerSubmission'
import auth from './middleware/auth'
import moduleGuide from '../views/moduleGuide'
import supportMaterials from '../views/moduleGuide'
import reports from '../views/reports'
import uwReports from '../views/reportsUw.vue'
import plagiarism from '../views/plagiarism'
import exportAssessments from '../views/exportAssessments'
import ContactUs from '../views/contactUs'
import reportingView from '../views/reportingView'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/login',
			name: 'login',
			component: login,
			meta: {
				middleware: [
					isLoggedin
				]
			},
			alias: '/'
		},
		{
			path: '/contact',
			name: 'contact',
			component: ContactUs,
			meta: {
				middleware: [
					auth
				]
			},
			alias: '/'
		},
		{
			path: "/active",
			name: "active",
			component: dashboard,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/completed",
			name: "completed",
			component: dashboard,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/resetPassword",
			name: "reset password",
			component: resetPassword
		},
		{
			path: '/password_reset/:token',
			name: 'password_reset',
			component: createNewPassword

		},
		{
			path: "/forgotPassword",
			name: "forgot password",
			component: forgotPassword
		},
		{
			path: "/createNewPassword",
			name: "new password",
			component: createNewPassword
		},
		{
			path: "/profile",
			name: "edit profile",
			component: editProfile,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/milestoneDates",
			name: "milestone dates",
			component: milestoneDates,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/manageRubric",
			name: "manage rubric",
			component: manageRubric,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/submissionLo",
			name: "submission Lo",
			component: submissionLo,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/learnerView",
			name: "learner view",
			component: learnerView,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/resourcesView",
			name: "Resources view",
			component: resourcesView,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/reportingView",
			name: "Reporting view",
			component: reportingView,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/messaging",
			name: "messaging",
			component: messaging,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/learnerCentralLo/:id/:loId?/:moduleId?",
			name: "learnerCentralLo",
			component: learnerCentralLo,
			meta: {
				dynamicRoute: true,
				middleware: [
					auth
				]
			}
		},
		{
			path: "/learnerCentral/:id?",
			name: "learner Central",
			component: learnerCentral,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/learnerSubmission/:id",
			name: "learnerSubmission",
			component: learnerSubmission,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/moduleGuide",
			name: "module guide",
			component: moduleGuide,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/supportMaterials",
			name: "support materials",
			component: supportMaterials,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/reports",
			name: "reports",
			component: reports,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/plagiarism",
			name: "plagiarism",
			component: plagiarism,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/exportAssessments",
			name: "export assessments",
			component: exportAssessments,
			meta: {
				middleware: [
					auth
				]
			}
		},
		{
			path: "/coding-reports",
			name: "coding-Reports",
			component: uwReports,
			meta: {
				middleware: [
					auth
				]
			}
		}
	],
	linkActiveClass: "active"
})

router.beforeEach((to, from, next) => {
    if (to.name === "login" && to.query.id && to.query.token) {
        localStorage.clear();
    }
	if (!to.meta.middleware) {
		return next()
	}
	const middleware = to.meta.middleware

	const context = {
		to,
		from,
		next
	}

	return middleware[0]({
		...context,
		next: middlewarePipeline(context, middleware, 1)
	})

})

export default router
