<template>
  <div class="plagiarismIcon">
    <div class="square"></div>
    <div class="square square2"></div>
    <div class="plagiarismCount">{{amount}}</div>
  </div>
</template>

<script>
export default {
  name: "PlagiarismIcon",
  props: ["amount"],
};
</script>

<style lang="scss" scoped>
.plagiarismIcon {
  width: 20px;
  height: 20px;
  padding-left: 1em;
}
.square {
  position: absolute;
  width: 20px;
  height: 20px;
  background: red;
}
.square2 {
  transform: rotate(45deg);
}

.plagiarismCount {
  position: absolute;
  color: white;
  font-weight: bold;
  z-index: 5;
  margin-left: 5px;
}
</style>