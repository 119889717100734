<template>
  <div class="writingAssessmentPopup--container">
    <button type="button" class="modal-close" @click="closePopup()">
      <em class="icon-close"></em>
    </button>
    <div class="container--body">
      <p>{{ targetSentence }}</p>
    </div>
    <div class="container--btn">
      <button class="btn primary" @click="markNotIncoherent">
        Mark as not incoherent
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "WritingAssessmentPopup",
  props: ["targetSentence"],
  methods: {
    markNotIncoherent(id) {
      this.$parent.$parent.markNotIncoherent(id);
    },
    closePopup() {
      this.$parent.$parent.toggleMarking();
    },
  },
};
</script>

<style lang="scss" scoped>
.writingAssessmentPopup--container {
  background: #ffffff;
  width: 500px;
  height: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  -webkit-box-shadow: 10px 10px 28px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px -17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px -17px rgba(0, 0, 0, 0.75);
  .container--body {
		margin: 0 auto;
    p {
      font-size: 14px;
    }
  }
}
</style>