<template>
  <div class="main-subMenubar pt-0" v-if="loList.length > 0">
    <div class="learn-sidebar">
      <div class="learn-sidebar-line"></div>
      <ul class="learn-sidebar-items">
        <li
          v-for="(lo, index) in loList"
          :key="lo.id"
          class="learn-sidebar-item"
          :class="[
            'learn-sidebar-item',
            lo.attributes.complete
              ? loDetails.id === lo.id
                ? 'item-completed'
                : 'item-complete'
              : loDetails.id === lo.id
              ? 'item-progress'
              : '',
          ]"
        >
          <div class="learn-sidebar-icon"></div>
          <div class="learn-sidebar-content">
            <button
              @click="getloDetails(lo, index)"
            >
              <span class="learn-sidebar-number">0{{ index + 1 }}</span>
              <div class="learn-sidebar-status" v-if="lo.attributes.complete">
                Completed
                <em class="icon-caret-right">
                  <img
                    src="../assets/images/icon-caret-black.svg"
                    alt="carrot"
                  />
                </em>
              </div>
              <div
                class="learn-sidebar-status"
                v-if="
                  !lo.attributes.complete && loDetails && loDetails.id == lo.id
                "
              >
                IN PROGRESS
                <em class="icon-caret-right">
                  <img
                    src="../assets/images/icon-caret-green.svg"
                    alt="right caret"
                  />
                </em>
              </div>
              <div class="learn-sidebar-title">
                {{ lo.attributes.learning_object.attributes.name }}
                <span v-if="lo.attributes.plagiarism_detected === true"><plagiarism-icon :amount="lo.attributes.plagiarism_count" /></span>
              </div>
            </button>
            <div class="main-menuNotification">
              <span
                class="notify-icon message"
                v-if="lo.attributes.message_notifications > 0"
              ></span>
              <span
                class="notify-icon submission"
                v-if="lo.attributes.submission_notifications > 0"
              ></span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import plagiarismIcon from "../components/plagiarismIcon.vue";
export default {
  name: "learner-central-side-menu",
  props: ["loList", "loDetails"],
  components: {
    plagiarismIcon
  },
  methods: {
      getloDetails(lo, index) {
        this.$parent.getloDetails(lo, index);
      }
  }
};
</script>

<style lang="scss" scoped>
.learn-sidebar-content {
    button {
        border: none;
        background: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
    }
}
</style>