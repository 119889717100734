<template>
  <div class="content">
    <div class="row">
      <h2>Here is your performance in this Module:</h2>
    </div>
    <div v-for="finalDebriefItem in finalDebrief.data" :key="finalDebriefItem.id">
      <div class="row">
        <div>
          <h3 v-if="!['submission', 'submission_email'].includes(finalDebriefItem.card_type)">
            {{ finalDebriefItem.name }}
          </h3>
          <!-- LLM components go here -->
          <llmChatFinalFeedback
            v-if="finalDebriefItem.card_type === 'llm_chat'"
            :llmChatFeedbackItem="finalDebriefItem"
          />
          <llmEmailFinalFeedback
            v-if="finalDebriefItem.card_type === 'llm_email'"
            :llmEmailFeedbackItem="finalDebriefItem"
          />
          <llmDialogicFinalFeedback
            v-if="finalDebriefItem.card_type === 'llm_dialogic'"
            :llmDialogicFeedbackItem="finalDebriefItem"
          />
          <div v-if="finalDebriefItem.card_type === 'dialogic'">
            <div v-if="finalDebriefItem.reattempts_present">  
              <div class="attempts-tabs">
                <button
                  v-for="attempt in finalDebriefItem.total_attempts_used_on_lo"
                  :class="
                    attempt === 1
                      ? `attempt-tab active-reattempt-tab ${[finalDebriefItem.lo_id]}`
                      : `attempt-tab ${[finalDebriefItem.lo_id]}`
                  "
                  :id="finalDebriefItem.data[attempt - 1].id"
                  :key="attempt"
                  :data-loid="finalDebriefItem.lo_id"
                  @click="changeReattemptTab(finalDebriefItem.data[attempt - 1].id)"
                >
                  Attempt {{ (finalDebriefItem.total_attempts_used_on_lo - attempt) + 1}}
                </button>
              </div>
              <div
                v-for="attempt in finalDebriefItem.total_attempts_used_on_lo"
                :key="attempt"
                :id="'details-' + finalDebriefItem.data[attempt - 1].id"
                :class="
                  attempt === 1
                    ? 'active-details details-ulo' +
                      [finalDebriefItem.data[attempt - 1].id] +
                      ' details-lo-' +
                      [finalDebriefItem.lo_id]
                    : 'details-hide details-ulo-' +
                      [finalDebriefItem.data[attempt - 1].id] +
                      ' details-lo-' +
                      [finalDebriefItem.lo_id]
                "
              >
                <div
                  v-if="
                    finalDebriefItem.data[attempt - 1] &&
                    finalDebriefItem.data[attempt - 1].attributes &&
                    finalDebriefItem.data[attempt - 1].attributes
                      .dialogic_debrief_evaluation
                  "
                >
                  <div
                    v-for="(dialogicList, key) in finalDebriefItem.data[attempt - 1]
                      .attributes.dialogic_debrief_evaluation"
                    :key="dialogicList.id"
                    class="dialogic-final-feedback-item"
                  >
                    <div
                      v-for="dialogic in finalDebriefItem.data[attempt - 1].attributes
                        .dialogic_debrief_evaluation[key]"
                      :key="dialogic.id"
                    >
                      <h5>{{ dialogic.attributes.question_concept }}</h5>
                      <div class="item">
                        <div
                          class="feedback-icon item-image"
                          v-if="
                            dialogic.attributes.key_topic_missed &&
                            !finalDebriefItem.data[attempt - 1].attributes
                              .hide_assessment_label
                          "
                        ></div>
                        <div
                          class="feedback-icon item-image"
                          v-else-if="
                            !finalDebriefItem.data[attempt - 1].attributes
                              .hide_assessment_label
                          "
                        >
                          <img
                            v-if="
                              dialogic.attributes.assessment_icon_url
                            "
                            :src="
                              dialogic.attributes.assessment_icon_url
                            "
                            :alt="
                              dialogic.attributes.assessment_label
                            "
                          />
                        </div>
                        <div class="item-content">
                          <div
                            v-if="
                              dialogic.attributes.key_topic_missed
                            "
                          >
                            <div
                              class="label"
                              v-if="
                                !finalDebriefItem.data[attempt - 1].attributes
                                  .hide_assessment_label
                              "
                            >
                              Missed
                            </div>
                          </div>
                          <div v-else>
                            <div
                              class="label"
                              v-if="
                                !finalDebriefItem.data[attempt - 1].attributes
                                  .hide_assessment_label
                              "
                            >
                              {{
                                dialogic.attributes.assessment_label
                              }}
                            </div>
                          </div>
                          <!-- <h4 v-if="dialogic.attributes.topic_label">
                            {{ dialogic.attributes.topic_label }}
                          </h4> -->
                          <p
                            v-html="
                              dialogic.attributes.debrief_received
                            "
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="item">
                    <div
                      class="item-image feedback-icon"
                      v-if="finalDebriefItem.assesement && !finalDebriefItem.hide_assessment_label"
                    >
                      <img
                        v-if="finalDebriefItem.assesement.attributes.assessment_icon_url"
                        :src="finalDebriefItem.assesement.attributes.assessment_icon_url"
                        :alt="finalDebriefItem.assesement.attributes.assessment_label"
                      />
                    </div>
                    <div class="item-content" v-if="finalDebriefItem.assesement">
                      <div class="label" v-if="!finalDebriefItem.hide_assessment_label">
                        <h4>
                          {{
                            finalDebriefItem.assesement.attributes.assessment_label
                              ? finalDebriefItem.assesement.attributes.assessment_label
                              : "Missed"
                          }}
                        </h4>
                      </div>
                      <p
                        v-html="
                          finalDebriefItem.assesement.attributes.feedback
                            ? finalDebriefItem.assesement.attributes.feedback
                            : ''
                        "
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                v-if="
                  finalDebriefItem.data &&
                  finalDebriefItem.data.attributes &&
                  finalDebriefItem.data.attributes.dialogic_debrief_evaluation
                "
              >
                <div
                  v-for="(dialogicList, key) in finalDebriefItem.data.attributes
                    .dialogic_debrief_evaluation"
                  :key="dialogicList.id"
                  class="dialogic-final-feedback-item"
                >
                  <div
                    v-for="dialogic in finalDebriefItem.data.attributes
                      .dialogic_debrief_evaluation[key]"
                    :key="dialogic.id"
                  >
                    <p class="feedback-item-title">
                      {{ dialogic.attributes.question_concept }}
                    </p>
                    <div class="item">
                      <div
                        class="feedback-icon item-image"
                        v-if="
                          dialogic.attributes.key_topic_missed &&
                          !finalDebriefItem.data.attributes.hide_assessment_label
                        "
                      ></div>
                      <div
                        class="feedback-icon item-image"
                        v-else-if="
                          !finalDebriefItem.data.attributes.hide_assessment_label
                        "
                      >
                        <img
                          v-if="
                            dialogic.attributes.assessment_icon_url
                          "
                          :src="
                            dialogic.attributes.assessment_icon_url
                          "
                          :alt="dialogic.attributes.assessment_label"
                        />
                      </div>
                      <div class="item-content">
                        <div
                          v-if="dialogic.attributes.key_topic_missed"
                        >
                          <div
                            class="label"
                            v-if="
                              !finalDebriefItem.data.attributes
                                .hide_assessment_label
                            "
                          >
                            Missed
                          </div>
                        </div>
                        <div v-else>
                          <div
                            class="label"
                            v-if="
                              !finalDebriefItem.data.attributes
                                .hide_assessment_label
                            "
                          >
                            {{ dialogic.attributes.assessment_label }}
                          </div>
                        </div>
                        <h4 v-if="dialogic.attributes.topic_label">
                          {{ dialogic.attributes.topic_label }}
                        </h4>
                        <p
                          v-html="
                            dialogic.attributes.debrief_received
                          "
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="item">
                  <div
                    class="item-image feedback-icon"
                    v-if="
                      finalDebriefItem.assessment && !finalDebriefItem.hide_assessment_label
                    "
                  >
                    <img
                      v-if="
                        finalDebriefItem.assessment.attributes.assessment_icon_url
                      "
                      :src="
                        finalDebriefItem.assessment.attributes.assessment_icon_url
                      "
                      :alt="
                        finalDebriefItem.assessment.attributes.assessment_label
                      "
                    />
                  </div>
                  <div class="item-content" v-if="finalDebriefItem.assessment">
                    <div
                      class="label"
                      v-if="!finalDebriefItem.hide_assessment_label"
                    >
                      <h4>
                        {{
                          finalDebriefItem.assessment.attributes.assessment_label
                            ? finalDebriefItem.assessment.attributes
                                .assessment_label
                            : "Missed"
                        }}
                      </h4>
                    </div>
                    <p
                      v-html="
                        finalDebriefItem.assessment.attributes.feedback
                          ? finalDebriefItem.assessment.attributes.feedback
                          : ''
                      "
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="finalDebriefItem.card_type === 'submission_email'">
            <div class="grade-score-head">
              <h3>{{ finalDebriefItem.name }}</h3>
              <h4
                v-if="finalDebriefItem.display_grade && finalDebriefItem.published"
                class="grade-score"
              >
                Grade: {{ Math.round(finalDebriefItem.overall_grade) }}
              </h4>
              <h4
                v-if="finalDebriefItem.display_grade && !finalDebriefItem.published"
                class="grade-score"
              >
                Grade: Not Yet Graded
              </h4>
            </div>
            <div v-if="finalDebriefItem.data">
              <div
                v-for="submission in finalDebriefItem.data.attributes.submission_gradings"
                :key="submission.id"
              >
                <div class="item">
                  <div class="item-image item-score">
                    {{ submission.attributes.score }}%
                  </div>
                  <div class="item-content">
                    <h4 class="label item-label">
                      {{ submission.attributes.rubric_criteria }}
                    </h4>
                    <div class="item-score-head">
                      Weight {{ submission.attributes.rubric_weight }}%
                    </div>
                    <p v-html="submission.attributes.rubric_feedback"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="finalDebriefItem.card_type === 'submission'">
            <div class="grade-score-head">
              <h3>{{ finalDebriefItem.name }}</h3>
              <h4
                v-if="finalDebriefItem.display_grade && finalDebriefItem.published"
                class="grade-score"
              >
                Grade: {{ Math.round(finalDebriefItem.overall_grade) }}
              </h4>
              <h4
                v-if="finalDebriefItem.display_grade && !finalDebriefItem.published"
                class="grade-score"
              >
                Grade: Not Yet Graded
              </h4>
            </div>
            <div v-for="submission in finalDebriefItem.data" :key="submission.id">
              <div class="item">
                <div class="item-image item-score">
                  {{ submission.attributes.score }}%
                  <!--<div>Score</div>-->
                </div>
                <div class="item-content">
                  <h4 class="label item-label">
                    {{ submission.attributes.rubric_criteria }}
                  </h4>
                  <div class="item-score-head">
                    Weight {{ submission.attributes.rubric_weight }}%
                  </div>
                  <p v-html="submission.attributes.rubric_feedback"></p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="finalDebriefItem.card_type === 'quiz'">
            <div class="item">
              <div
                class="item-image feedback-icon"
                v-if="
                  finalDebriefItem &&
                  finalDebriefItem.assesement &&
                  finalDebriefItem.assesement.attributes.assessment_icon_url
                "
              >
                <img
                  v-if="finalDebriefItem.assesement.attributes.assessment_icon_url"
                  :src="finalDebriefItem.assesement.attributes.assessment_icon_url"
                  :alt="finalDebriefItem.assesement.attributes.assessment_label"
                />
              </div>
              <div class="item-content">
                <div class="item-content" v-if="finalDebriefItem">
                  <p v-html="finalDebriefItem.feedback ? 'Score:' + finalDebriefItem.feedback : ''"></p>
                  <p
                    v-if="finalDebriefItem.assesement && finalDebriefItem.assesement.attributes"
                    v-html="finalDebriefItem.assesement.attributes.feedback"
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="finalDebriefItem.card_type === 'email'">
            <div v-if="finalDebriefItem.reattempts_present"> 
              <div class="attempts-tabs">
                <button
                  v-for="attempt in finalDebriefItem.total_attempts_used_on_lo"
                  :class="
                    attempt === 1
                      ? `attempt-tab active-reattempt-tab ${[finalDebriefItem.lo_id]}`
                      : `attempt-tab ${[finalDebriefItem.lo_id]}`
                  "
                  :id="finalDebriefItem.data[attempt - 1].id"
                  :key="attempt"
                  :data-loid="finalDebriefItem.lo_id"
                  @click="changeReattemptTab(finalDebriefItem.data[attempt - 1].id)"
                >
                  Attempt {{ (finalDebriefItem.total_attempts_used_on_lo - attempt) + 1}}
                </button>
              </div>
              <div
                v-for="attempt in finalDebriefItem.total_attempts_used_on_lo"
                :key="attempt"
                :id="'details-' + finalDebriefItem.data[attempt - 1].id"
                :class="
                  attempt === 1
                    ? 'active-details details-ulo' +
                      [finalDebriefItem.data[attempt - 1].id] +
                      ' details-lo-' +
                      [finalDebriefItem.lo_id]
                    : 'details-hide details-ulo-' +
                      [finalDebriefItem.data[attempt - 1].id] +
                      ' details-lo-' +
                      [finalDebriefItem.lo_id]
                "
              >
                <div
                    v-if="
                      finalDebriefItem.data[attempt - 1] &&
                      finalDebriefItem.data[attempt - 1].attributes &&
                      finalDebriefItem.data[attempt - 1].attributes
                        .learner_debrief_items.length > 0
                    "
                  >

                    <div
                      v-for="email in finalDebriefItem.data[attempt - 1].attributes
                        .learner_debrief_items"
                      :key="email.id"
                    >
                    <div class="item">
                      <div
                        class="item-image feedback-icon"
                        v-if="!finalDebriefItem.data[attempt - 1].attributes.hide_assessment_label"
                      >
                        <img
                          :src="
                            email.attributes.email_assessment_item.attributes
                              .assessment_icon_url
                          "
                          :alt="
                            email.attributes.email_assessment_item.attributes
                              .assessment_label
                          "
                        />
                      </div>
                      <div class="item-content">
                        <div
                          class="label"
                          v-if="!finalDebriefItem.data[attempt - 1].attributes.hide_assessment_label"
                        >
                          <h4>
                            {{
                              email.attributes.email_assessment_item.attributes
                                .assessment_label
                            }}
                          </h4>
                        </div>
                        <h4>{{ email.attributes.global_skill.name }}</h4>
                        <p v-html="email.attributes.debrief_content"></p>
                      </div>
                    </div>
                    </div>

                </div>
              </div>
            </div>
            <div v-else>
  
              <div v-if="finalDebriefItem.data">
                <div
                  v-if="
                    finalDebriefItem.data.attributes &&
                    finalDebriefItem.data.attributes.learner_debrief_items &&
                    finalDebriefItem.data.attributes.learner_debrief_items.length > 0
                  "
                >
                  <div
                    v-for="email in finalDebriefItem.data.attributes.learner_debrief_items"
                    :key="email.id"
                  >
                    <div class="item">
                      <div
                        class="item-image feedback-icon"
                        v-if="!finalDebriefItem.data.attributes.hide_assessment_label"
                      >
                        <img
                          :src="
                            email.attributes.email_assessment_item.attributes
                              .assessment_icon_url
                          "
                          :alt="
                            email.attributes.email_assessment_item.attributes
                              .assessment_label
                          "
                        />
                      </div>
                      <div class="item-content">
                        <div
                          class="label"
                          v-if="!finalDebriefItem.data.attributes.hide_assessment_label"
                        >
                          <h4>
                            {{
                              email.attributes.email_assessment_item.attributes
                                .assessment_label
                            }}
                          </h4>
                        </div>
                        <h4>{{ email.attributes.global_skill.name }}</h4>
                        <p v-html="email.attributes.debrief_content"></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    finalDebriefItem.data.attributes &&
                    finalDebriefItem.data.attributes.user_email_assessment_items &&
                    finalDebriefItem.data.attributes.user_email_assessment_items.length > 0
                  "
                >
                  <div
                    v-for="email in finalDebriefItem.data.attributes
                      .user_email_assessment_items"
                    :key="email.id"
                  >
                    <div class="item">
                      <div
                        class="feedback-icon item-image"
                        v-if="!finalDebriefItem.data.attributes.hide_assessment_label"
                      >
                        <img
                          :src="
                            email.attributes.email_assessment_item.attributes
                              .assessment_icon_url
                          "
                          :alt="
                            email.attributes.email_assessment_item.attributes
                              .assessment_label
                          "
                        />
                      </div>
                      <div
                        class="item-content"
                        v-if="!finalDebriefItem.data.attributes.hide_assessment_label"
                      >
                        <div class="label">
                          {{
                            email.attributes.email_assessment_item.attributes
                              .assessment_label
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="item">
                <div
                  class="item-image"
                  v-if="finalDebriefItem.assesement && !finalDebriefItem.hide_assessment_label"
                >
                  <img
                    v-if="finalDebriefItem.assesement.attributes.assessment_icon_url"
                    :src="finalDebriefItem.assesement.attributes.assessment_icon_url"
                    :alt="finalDebriefItem.assesement.attributes.assessment_label"
                  />
                </div>
                <div class="item-content" v-if="finalDebriefItem.assesement">
                  <div class="label" v-if="!finalDebriefItem.hide_assessment_label">
                    {{
                      finalDebriefItem.assesement.attributes.assessment_label
                        ? finalDebriefItem.assesement.attributes.assessment_label
                        : "Missed"
                    }}
                  </div>
                  <p
                    v-html="
                      finalDebriefItem.assesement.attributes.feedback
                        ? finalDebriefItem.assesement.attributes.feedback
                        : ''
                    "
                  ></p>
                </div>
              </div>
            </div>      
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import llmChatFinalFeedback from "../components/finalFeedbackComponents/llmChatFinalFeedback.vue";
import llmDialogicFinalFeedback from "../components/finalFeedbackComponents/llmDialogicFinalFeedback.vue";
import llmEmailFinalFeedback from "../components/finalFeedbackComponents/llmEmailFinalFeedback.vue";

export default {
  name: "finalFeedback",
  props: ["finalDebrief"],
  components: {
    llmChatFinalFeedback,
    llmDialogicFinalFeedback,
    llmEmailFinalFeedback
  },
  data() {
    return {};
  },
  methods: {
    changeReattemptTab(ulo_id) {
      let active_tab = document.getElementById(ulo_id);
      let active_details = document.getElementById("details-" + ulo_id);
      let loid = event.target.getAttribute("data-loid");
      let other_tabs = document.getElementsByClassName(loid);
      let other_details = document.getElementsByClassName("details-lo-" + loid);
      for (var j = 0; j < other_tabs.length; j++) {
        other_tabs[j].classList.remove("active-reattempt-tab");
      }
      active_tab.classList.add("active-reattempt-tab");

      for (var k = 0; k < other_tabs.length; k++) {
        other_details[k].classList.remove("active-details");
        other_details[k].classList.add("details-hide");
      }

      active_details.classList.add("active-details");
      active_details.classList.remove("details-hide");
    },
  },
  created() {},
};
</script>
<style scoped lang="scss">
body {
  font-family: "mulilight";
  color: black;
  font-size: 16px;
}
.container {
  width: 100%;
}
.logo {
  width: 100%;
  text-align: right;
}
.logo img {
  outline: 0;
}
h1 {
  font-family: "robotoblack";
  font-size: 40px;
  line-height: 100%;
  margin: 30px 0;
}
h2 {
  font-family: "robotoblack";
  font-size: 20px;
  margin: 50px 0 10px;
}
h3 {
  font-family: "robotomedium";
  font-size: 20px;
}
h4 {
  font-family: "robotoregular";
  font-size: 16px;
  margin: 0 0 5px;
}
h5 {
  font-size: 16px;
  font-family: "robotomedium";
  margin: 14px 0 0;
}
.row {
  padding: 40px 0;
  border-bottom: #eaeaea 1px solid;
  float: left;
  width: 100%;
}
.row:last-child {
  border-bottom: 0;
}
.item {
  margin: 20px 0 0;
  float: left;
  width: 100%;
}
.item-image {
  float: left;
  width: 78px;
  height: 78px;
  margin: 0 25px 0 0;
}
.item-image img {
  max-width: 100%;
}
.item-content {
  float: left;
  margin: 3px 0 0;
  width: 600px;
}
.label {
  font-weight: bold;
  font-family: "robotoblack";
  font-size: 13px;
  line-height: 16px;
  display: block;
  text-transform: uppercase;
  color: black;
}
.grade-score-head {
  float: left;
  width: 100%;
}
.grade-score-head h3 {
  float: left;
}
.grade-score-head p {
  float: right;
  font-family: "robotoblack";
}
.item-score {
  background-color: #3dbc9e;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding-top: 22px;
}
.item-score {
  h3 {
    font-family: "robotomedium";
    font-size: 20px;
    line-height: 100%;
    color: #fff;
    margin: 12px 0 0;
  }
}
.grade-score {
  font-family: "robotomedium" !important;
  float: right;
  margin-top: 5px;
}
.item-score-head {
  font-family: "robotomedium" !important;
  margin-bottom: 5px;
}
.item-label {
  color: #3dbc9e;
  font-size: 14px;
  margin: 0;
}

.details-hide {
  display: none;
}
.active-details {
  display: block;
}

.attempts-tabs {
  display: flex;
  padding-left: 0;
  padding-top: 2em;
  .attempt-tab {
    border: 1px solid #ccc;
    padding: 1em;
    display: inline-flex;
    margin-right: 1em;
    cursor: pointer;
  }
  .active-reattempt-tab {
    background: #3dbc9e;
    color: white;
  }
}
.dialogic-final-feedback-item {
  margin-bottom: 3em;
  display: block;
  float: left;
  width: 100%;

  .item {
    margin: 10px 0 0;
  }
  .feedback-item-title {
    font-size: 16px;
    font-weight: bold;
    margin: 14px 0 0;
    color: #000000;
  }
}
</style>

