<template>
  <div class="container-full">
    <div class="top-header">
      <backDashboard />
      <headerTitle :moduleDetails="section" />
      <userLogout />
    </div>
    <div class="site-content">
      <menuSidebar />
      <div class="main-subMenubar">
        <ul>
          <li
            v-for="(lo, index) in loList"
            :key="lo.id"
            :class="[lo.id == loDetails.id ? 'active' : '']"
          >
            <a @click="getLoData(lo, index)">
              <span>{{ index + 1 }}. </span>
              {{ lo.attributes.name }}
              <span class="note-icon" v-if="showNoteIcon(lo)"></span>
            </a>
          </li>
        </ul>
      </div>
      <div class="main-content pt-4 mt-2 ">
        <div class="main-title align-items-center ">

          <h3>Learner View</h3>

                <!-- Modules dropdown Start -->
                <div class="button-right row">
                  <div class="main-searchForms">
                    <div class="search-dropdown" v-if="modules_all.length > 1">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenu1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >{{ModuleSelectedName}}</button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                        <a
                          class="dropdown-item"
                          type="button"
                          @click="toggleSort(sortColumn,sortOrder,'')"
                        ></a>
                        <a
                          v-for="(mods,index) in modules_all"
                          :key="index"
                          class="dropdown-item"
                          type="button"
                          @click="getSelectedModules(index)"
                        >{{mods.attributes.name}}</a>
                      </div>
                    </div>
                  </div>

                  <!-- Modules dropdown End -->

                  <div class="learn-note note-provisioning">
                    <div class="note-dropdown">
                      <button class="btn primary medium dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{showNote ? 'Edit Note' : 'Add Note'}}
                      </button>
                      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                        <div class="head">
                          <h4>{{showNote ? 'Edit Note' : 'Add Note'}}</h4>
                          <a href="javascript:void(0);">
                            <em class="icon-close"></em>
                          </a>
                        </div>
                        <div class="content">
                          <textarea class="form-control" rows="4" v-model="notes"></textarea>
                        </div>
                        <div class="footer">
                          <button class="btn danger" @click="removeNotes" v-if="showNote">Remove Note</button>
                          <button class="btn primary" @click="saveNotes" v-if="notes && notes.length ">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             
        </div>
          <div class="learn-notes my-3" v-if="showNote">
            <h5><img src="../assets/images/icon-yellownote.svg" alt="yellow">Note</h5>
            <p v-html="notes"></p>
          </div>
        <div class="learner-section" v-if="loDetails.attributes">
          <h1>{{ loDetails.attributes.name }}</h1>
          <p v-html="loDetails.attributes.description"></p>
          <div
            class="learn-users"
            v-if="loDetails.attributes.card_type === 'submission_email'"
          >
            <div
              class="learn-userItem"
              v-for="character in loDetails.attributes.card_detail.attributes
                .to_character"
              :key="'toCharacter' + character.id"
            >
              <div class="learn-label">To</div>
              <div class="learn-userImage">
                <div class="learn-image">
                  <img
                    :src="character.attributes.char_photo_url.thumbnail"
                    v-if="character.attributes.char_photo_url.thumbnail"
                    alt="image"
                  />
                </div>
                <div class="learn-title">
                  <h5>{{ character.attributes.char_full_name }}</h5>
                  <p>{{ character.attributes.world_role }}</p>
                </div>
              </div>
            </div>
            <div
              class="learn-userItem"
              v-for="character in loDetails.attributes.card_detail.attributes
                .cc_characters"
              :key="character.id"
            >
              <div class="learn-label">CC</div>
              <div class="learn-userImage">
                <div class="learn-image">
                  <img
                    :src="character.attributes.char_photo_url.thumbnail"
                    v-if="character.attributes.char_photo_url.thumbnail"
                    alt="image"
                  />
                </div>
                <div class="learn-title">
                  <h5>{{ character.attributes.char_full_name }}</h5>
                  <p>{{ character.attributes.world_role }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="introduction"
            v-if="loDetails.attributes.card_type === 'video'"
          >
            <div class="introduction-video">
              <div
                class="wistia_responsive_wrapper data-video"
                style="
                  height: 100%;
                  left: 0;
                  position: absolute;
                  top: 0;
                  width: 100%;
                "
              >
                <iframe
                  :src="
                    preIframUrl +
                    loDetails.attributes.card_detail.attributes.global_video
                      .attributes.wistia_code +
                    postIframUrl
                  "
                  title="Video.mp4"
                  class="wistia_embed"
                  name="wistia_embed"
                  allowtransparency="true"
                  scrolling="no"
                  allowfullscreen
                  mozallowfullscreen
                  webkitallowfullscreen
                  oallowfullscreen
                  msallowfullscreen
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
              <div class="introduction-titles">
                <div class="introduction-intro">
                  <div class="introduction-time">
                    {{
                      loDetails.attributes.card_detail.attributes.global_video
                        .attributes.duration
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="intro-transcript">
              <h5>Transcript:</h5>
              <read-more
                more-str="Read more"
                :text="
                  loDetails.attributes.card_detail.attributes.global_video
                    .attributes.transcript
                "
                link="#"
                less-str="Read less"
                :max-chars="400"
              ></read-more>
            </div>
          </div>
          <div
            class="introduction"
            v-if="loDetails.attributes.card_type === 'slide'"
          >
            <carousel
              :margin="0"
              :nav="true"
              :navText="[]"
              :autoplay="false"
              :autoHeight="false"
              :items="1"
              :dots="false"
              :loop="true"
            >
              <div
                v-for="(img, i) in loDetails.attributes.card_detail.attributes
                  .slider_images"
                :key="img.id"
              >
                <div
                  v-if="loDetails.attributes.card_detail.attributes.has_caption"
                  class="introduction-video-text"
                >
                  {{ img.attributes.caption }}
                </div>
                <div class="introduction-video">
                  <img
                    :src="img.attributes.resource_url.large_version"
                    alt="mask"
                    class="intro-slide-video"
                  />
                  <div class="introduction-intro-bottom">
                    <div class="introduction-intro-count">
                      {{ i + 1 }}/{{
                        loDetails.attributes.card_detail.attributes
                          .slider_images.length
                      }}
                    </div>
                  </div>
                </div>
                <div class="introduction-progress dashboard-progress-bar">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="{
                        width: [
                          ((i + 1) * 100) /
                            loDetails.attributes.card_detail.attributes
                              .slider_images.length +
                            '%',
                        ],
                      }"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </carousel>
          </div>
          <div v-if="loDetails.attributes.card_type === 'file'">
            <div class="modal-content">
              <div class="modal-body">
                <div class="modal-header modal-print-header mb-4">
                  <div class="modal-print-row">
                    <div class="modal-print-nav">
                      <div class="data-results">
                        Page {{ page }} of {{ numPages }}
                      </div>
                      <div aria-label="PDF page navigation">
                        <ul class="pagination pdf-pagination">
                          <li class="page-item" v-if="page !== 1">
                            <a
                              class="page-link"
                              aria-label="Previous"
                              @click="page--"
                            >
                              &lt;
                            </a>
                          </li>

                          <li class="page-item" v-if="page < numPages">
                            <a
                              class="page-link"
                              aria-label="Next"
                              @click="page++"
                            >
                              &gt;
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="modal-print-right">
                      <button class="btn-focus loader" @click="rotate += 90">
                        &#x27F3;
                      </button>
                      <button class="btn-focus rotate" @click="rotate -= 90">
                        Rotate
                      </button>
                    </div>
                  </div>
                </div>
                <div style="width: 100%; align-items: center">
                  <div
                    v-if="loadedRatio > 0 && loadedRatio < 1"
                    style="
                      background-color: green;
                      color: white;
                      text-align: center;
                    "
                    :style="{ width: loadedRatio * 100 + '%' }"
                  >
                    {{ Math.floor(loadedRatio * 100) }}%
                  </div>
                  <pdf
                    ref="pdfRef"
                    :source="
                      loDetails.attributes.card_detail.attributes.resource_url
                    "
                    :page="page"
                    :rotate="rotate"
                    @num-pages="numPages = $event"
                    @link-clicked="page = $event"
                    @rendered="handleDocumentRender"
                  ></pdf>
                </div>
              </div>
            </div>
          </div>
          <div
            class="learn-users"
            v-if="loDetails.attributes.card_type == 'email'"
          >
            <div
              class="learn-userItem"
              v-for="character in loDetails.attributes.card_detail.attributes
                .to_characters"
              :key="'toCharacter' + character.id"
            >
              <div class="learn-label">To</div>
              <div class="learn-userImage">
                <div class="learn-image">
                  <img
                    :src="character.attributes.char_photo_url.thumbnail"
                    v-if="character.attributes.char_photo_url.thumbnail"
                    alt="image"
                  />
                </div>
                <div class="learn-title">
                  <h5>{{ character.attributes.char_full_name }}</h5>
                  <p>{{ character.attributes.world_role }}</p>
                </div>
              </div>
            </div>
            <div
              class="learn-userItem"
              v-for="character in loDetails.attributes.card_detail.attributes
                .cc_characters"
              :key="character.id"
            >
              <div class="learn-label">CC</div>
              <div class="learn-userImage">
                <div class="learn-image">
                  <img
                    :src="character.attributes.char_photo_url.thumbnail"
                    v-if="character.attributes.char_photo_url.thumbnail"
                    alt="image"
                  />
                </div>
                <div class="learn-title">
                  <h5>{{ character.attributes.char_full_name }}</h5>
                  <p>{{ character.attributes.world_role }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              loDetails.attributes.card_type == 'email' &&
              loDetails.attributes.learning_object_type == 'plot_point'
            "
          >
            <h4>
              Subject: {{ loDetails.attributes.card_detail.attributes.title }}
            </h4>
            <div class="learn-paragraph">
              <p
                v-html="loDetails.attributes.card_detail.attributes.email_body"
              ></p>
            </div>
          </div>

          <div
            v-if="
              loDetails.attributes.card_type == 'text'
            "
          >
            <div class="learn-paragraph">
              <p
                v-html="loDetails.attributes.card_detail.attributes.description"
              ></p>
            </div>
          </div>
          <div
            class="learn-object"
            v-if="
              loDetails.attributes.card_type == 'email' &&
              loDetails.attributes.learning_object_type == 'interaction'
            "
          >
            <div class="learn-object-editor">
              <div class="form-group">
                <input
                  type="text"
                  :disabled="true"
                  class="form-control"
                  placeholder="Add Subject"
                />
              </div>
              <div class="form-group">
                <vue-editor
                  class="editor-control disable"
                  :editorToolbar="fullToolbar"
                ></vue-editor>
              </div>
            </div>
          </div>
          <div
            class="learn-users"
            v-if="loDetails.attributes.card_type == 'chat'"
          >
            <div class="learn-userItem">
              <div class="learn-userImage">
                <div class="learn-image">
                  <img
                    :src="
                      loDetails.attributes.card_detail.attributes
                        .chat_character[0].attributes.char_photo_url.thumbnail
                    "
                    v-if="
                      loDetails.attributes.card_detail.attributes
                        .chat_character[0].attributes.char_photo_url.thumbnail
                    "
                    alt="image"
                  />
                </div>
                <div class="learn-title">
                  <h5>
                    {{
                      loDetails.attributes.card_detail.attributes
                        .chat_character[0].attributes.char_full_name
                    }}
                  </h5>
                  <p>
                    {{
                      loDetails.attributes.card_detail.attributes
                        .chat_character[0].attributes.world_role
                    }}({{
                      loDetails.attributes.card_detail.attributes
                        .chat_character[0].attributes.org_name
                    }})
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="learn-users question-users"
            v-if="
              quizQuestion.length > 0 &&
              loDetails.attributes.card_type === 'quiz'
            "
          >
            <div class="question-results">
              {{ quizQuestion.length }} Questions
            </div>
            <div
              class="question-item"
              v-for="(question, index) in quizQuestion"
              :key="question.id"
            >
              <h4>
                <span>{{ index + 1 }}. </span>
                {{ question.attributes.question }}
              </h4>
              <div
                class="question-checkbox"
                v-if="question.attributes.question_type == 'multiple_choice'"
              >
                <ul>
                  <li
                    v-for="ques in question.attributes.mcq_options"
                    :key="ques.id"
                  >
                    <label class="custom-checkbox">
                      {{ ques.attributes.option }}
                      <span for="check1" class="checkmark circle"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="learn-users question-users"
            v-if="
              dialogicQuestion.length > 0 &&
              loDetails.attributes.card_type === 'dialogic'
            "
          >
            <div
              class="question-item"
              v-for="(question, ind) in dialogicQuestion"
              :key="question.id"
            >
              <h4
                v-for="(que, index) in question.attributes.variations"
                :key="que.id"
              >
                <span> {{ ind + 1 }}. {{ index + 1 }}. </span>
                {{ que.attributes.question }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import menuSidebar from "../components/menuSidebar.vue";
import api from "../services/api";
import headerTitle from "../components/headerTitle.vue";
import carousel from "vue-owl-carousel";
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import { VueEditor } from "vue2-editor";

export default {
  name: "learnerView",
  components: {
    backDashboard,
    userLogout,
    menuSidebar,
    headerTitle,
    VueEditor,
    carousel,
    pdf,
  },
  data() {
    return {
      ModuleSelectedName: "",
      modules_all: [],
      search_learner: "",
      sortOrder: "asc",
      sortColumn: "name",
      lo_index: "",
      loList: [],
      loDetails: {},
      loId: "",
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      showNote: false,
      rotate: 0,
      editNote: "",
      notes: "",
      notesIDs: [],
      section: '',
      sections: [
        {
          attributes: {
            sticky_note: "",
          },
        },
      ],
      index: 0,
      quizQuestion: [],
      dialogicQuestion: [],
      fullToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  methods: {
    handleDocumentRender() {
      this.numPages = this.$refs.pdfRef.pageCount
    },
    getSelectedModules(index){
      utilFunctionService.showLoader();
      api
        .learningObjLists(this.modules_all[index].id, this.section.id)
        .then(res => {
          utilFunctionService.hideLoader();
          this.loList = res.data.active;
          this.sections = res.data.section_details;
          this.loDetails = this.loList[0];
          if (this.sections.length) {
            let index = this.sections.findIndex((section) => parseInt(section.attributes.learning_object_id) === parseInt(this.loDetails.id))
            if (index !== -1) {
              this.notes = this.sections[index]['attributes']['sticky_note'];
              this.showNote = true;
           }
         }
         this.quizDialogicloDetails()
        })
        .catch(() => {});
      utilFunctionService.hideLoader();
      
      this.ModuleSelectedName = this.modules_all[index].attributes.name
    },
    getMultiELMList() {
        api
          .getMultiELMList(this.section.id)
          .then(res => {
            this.modules_all = res.data.data.attributes.learn_mods
            this.ModuleSelectedName = this.modules_all[0].attributes.name
          })
          .catch(() => {});
    },
    getLoList() {
      utilFunctionService.showLoader();
      this.section = JSON.parse(localStorage.getItem("section"));
      api
        .learningObjLists(this.section.attributes.learn_mods[0].id, this.section.id)
        .then(res => {
          utilFunctionService.hideLoader();
          console.log(res.data.active, "*** res.data.active in getLoList learningObjLists");
          console.log(res.data.section_details, "*** res.data.section_details in getLoList learningObjLists");
          this.loList = res.data.active;
          this.sections = res.data.section_details;
          this.loDetails = this.loList[0];
          if (this.sections.length) {
            let index = this.sections.findIndex((section) => 
              section.attributes.learning_object_id ===
                parseInt(this.loDetails.id)
            );
            console.log(this.sections[index]["attributes"]["sticky_note"]);
            if (index !== -1 && this.sections[index]["attributes"]["sticky_note"]) {
              this.notes = this.sections[index]["attributes"]["sticky_note"];
              this.showNote = true;
            } else {
              this.showNote = false;
            }
          }
          this.quizDialogicloDetails();
        })
        .catch(() => {});
    },
    quizDialogicloDetails() {
      if (this.loDetails.attributes.card_type === "quiz") {
        this.quizQuestions();
      }
      if (this.loDetails.attributes.card_type === "dialogic") {
        this.dialogicQuestions();
      }
    },
    getLoData(lo, i) {
      this.loDetails = lo;
      this.index = i;
      this.notes = "";
      this.quizQuestion = [];
      if (this.sections.length) {
        let index = this.sections.findIndex(
          (section) => 
            parseInt(section.attributes.learning_object_id) === parseInt(lo.id)
        );
        if (index !== -1 && this.sections[index]['attributes']['sticky_note']) {
          this.notes = this.sections[index]['attributes']['sticky_note'];
          this.showNote = true;
        } else {
          this.showNote = false;
        }
      }
      this.quizDialogicloDetails();
    },
    dialogicQuestions() {
      api
        .getDialogicQuestions(this.loDetails.attributes.objectable_id)
        .then((result) => {
          this.dialogicQuestion = result.data.data;
        })
        .catch(() => {});
    },
    quizQuestions() {
      api
        .getQuizQuestions(this.loDetails.attributes.objectable_id)
        .then((result) => {
          this.quizQuestion = result.data.data;
        })
        .catch(() => {});
    },
    removeNotes() {
      if (this.sections.length > 0) {
        let index = this.sections.findIndex(
          (section) =>
            parseInt(section.attributes.learning_object_id) ===
            parseInt(this.loDetails.id)
        );
        if (index !== -1) {
          const fd = new FormData();
          const id = this.sections[index].id;
          fd.append("section[learning_object_sections_attributes][][id]", id);
          fd.append(
            "section[learning_object_sections_attributes][][_destroy]",
            true
          );
          api
            .deleteNotes(this.module.id, fd)
            .then(() => {
              this.getLoList();
              this.notes = "";
              this.showNote = false;
              utilFunctionService.showSuccess(
                commonConfig.appMessages.removeNote
              );
            })
            .catch(() => {});
        }
      }
    },
    saveNotes() {
      if (this.sections.length > 0) {
        let index = this.sections.findIndex(
          (section) =>
            parseInt(section.attributes.learning_object_id) ===
            parseInt(this.loDetails.id)
        );
        if (index !== -1) {
          const id = this.sections[index].id;
          const fd = new FormData();
          fd.append(
            "section[learning_object_sections_attributes][][sticky_note]",
            this.notes
          );
          fd.append("section[learning_object_sections_attributes][][id]", id);
          api
            .updateNotes(this.module.id, fd)
            .then((res) => {
              this.sections = res.data.data.attributes.learning_object_sections;
              utilFunctionService.showSuccess(commonConfig.appMessages.addNote);
              this.showNote = true;
            })
            .catch(() => {});
        } else {
          this.createNote();
        }
      } else {
        this.createNote();
      }
    },
    createNote() {
      const fd = new FormData();
      fd.append(
        "section[learning_object_sections_attributes][][sticky_note]",
        this.notes
      );
      fd.append(
        "section[learning_object_sections_attributes][][learning_object_id]",
        this.loDetails.id
      );
      api
        .stickyNotesOnLearnerView(this.module.id, fd)
        .then((res) => {
          this.sections = res.data.data.attributes.learning_object_sections;
          this.showNote = true;
        })
        .catch(() => {});
    },
    showNoteIcon(lo) {
      let index = this.sections.findIndex(
        (section) => 
          parseInt(section.attributes.learning_object_id) === parseInt(lo.id)
      );
      if (index !== -1 && this.sections[index]['attributes']['sticky_note']) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    console.log("*** hit created in learnerView.vue");
    this.section = JSON.parse(localStorage.getItem("section"));
    this.module = this.section
    this.getLoList();
    this.getMultiELMList();
  }
};
</script>


<style lang="scss">
  @import "../styles/variables.scss";
  @import "../styles/mixin.scss";

  .button-right {
    float: right;

  }
  .note-provisioning{
    margin: 5px;
    margin-right: 12px;
  }

  .note-icon {
    width: 24px;
    height: 24px;
    display: block;
    border-radius: 100%;
    margin-left: 10px;
    border: 1px solid #3dbc9e;
    background: #fff url("../assets/images/pencil.svg") no-repeat;
    background-size: 12px;
    background-position: center;
  }
</style>
