<template>
    <modal name="GradingSetupModal" height="auto" :width="1000" :scrollable="true" @before-open="beforeOpen">
      <div class="hide-modal" @click="$modal.hide('GradingSetupModal')"></div>
        <div class="modal-content modal-default">
          <div div class="data-content" >
            <div class="data-table data-column-8">
              <div class="data-head">
                <div class="data-col">
                  <h4>{{user_last_name}}, {{user_first_name}}</h4>
                </div>
                <div class="col-2">
                </div>
                <div class="col-2">
                </div>
               
              </div>

              <div class="data-items">
                <div class="data-row" >
                  <div class="col-5">
                    <div class="modal-title" >
                      <h4>{{learn_mod_name}}</h4>
                    </div>
                  </div>
                  
                  <div class="col-1">
                  </div>
                  <div class="col-1">
                  </div> 

                  <div class="col-2">
                    <div  >
                      <div >
                        <h5 >ELM Grade</h5>
                      </div>
                    </div>
                  </div>

                  <div class="col-2">
                    <span class="pr-4 "> 
                        <div 
                            v-html="overall_grade_percentage"
                            class="input-grading-elm"
                        >
                        </div>
                    </span>
                        <span class="pr-4 "> 
                          <div 
                            v-html="overall_grade_label"
                            class="input-grading-elm"
                            
                            placeholder="Moderate"
                            ></div>
                        </span>
                  </div>

                  

                </div>
              </div>


            </div>
          </div>

          <div class="modal-body question-modal-body-grading" v-for="(task ,index) of learnerSelectedIndex" :key=index>
            <div class="row" v-if="task.info.card_type === 'quiz'">
                  <div class="col-12" >
                    <div class="learner-grading-item">
                      <div class="row d-inline-flex" style="float: right;">
                        <div class="col-5">
                          <span>{{task.info.name}}</span>
                          <div class="row d-inline-flex" style="float: right;">

                            <div class="custom-span-style-grade-overall" >
                              <div >
                                <label >Overall Assessment</label>
                              
                                <!-- <span >Overall Assessment</span> -->
                              </div>
                            </div> 

                            <span class="pr-4 "> 
                                <div placeholder=""
                                  v-html="task.info.overall_assessment_percentage"
                                    ></div>
                                </span>
                              <span class="pr-4 "> 
                                <label class="input-grading-label">{{task.info.overall_assessment_label}}</label>
                                <!-- <div placeholder="mastery"
                                  class="input-grading-label"
                                  
                                    
                                    ></div> -->
                                </span>
                                  
                                <!-- <span>{{task.info.card_type}}</span> -->

                          </div>
                        </div>
                        <div class="col-3" >
                          <div class="row d-inline-flex" style="float: left;" v-for="(question,index2) of task.questions" :key=index2>

                                <div class="custom-span-style-grade-overall" >
                                  <div >
                                    <label >Question {{index2 + 1}}</label>
                                    <!-- <span >Question {{index2 + 1}}</span> -->
                                  </div>
                                </div> 

                                <span class="pr-4 " v-if="question.correct"> 
                                    <div placeholder="correct"
                                        class="custom-input-style-grade-number2"
                                        
                                        ></div>
                                    </span>
                                <span class="pr-4 " v-if="!question.correct"> 
                                    <div placeholder="incorrect"
                                        class="custom-input-style-grade-number2"
                                        
                                        ></div>
                                    </span> 

                          </div>

                        </div>
                        <div class="col-4">
                          <div class="row d-inline-flex" style="float: right;">

                                <div class="custom-span-style-grade-overall" >
                                  <div >
                                    <label >Overall Weight</label>
                                    <!-- <span >Overall Weight</span> -->
                                  </div>
                                </div> 

                                <span class="pr-4 "> 
                                    <div placeholder=""
                                    v-html="task.info.ilo_weight"
                                        class="custom-input-style-grade-number2"
                                        
                                        
                                        ></div>
                                    </span>

                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            <div class="row" v-if="task.info.card_type === 'dialogic'">
              <div class="col-12" >
                <div class="learner-grading-item">
                  <div class="row d-inline-flex" style="float: right;">
                    <div class="col-5">
                      <span>{{task.info.name}}</span>
                      <div class="row d-inline-flex" style="float: right;">

                            <div class="custom-span-style-grade-overall" >
                              <div >
                                <label >Overall Assessment</label>
                              
                                <!-- <span >Overall Assessment</span> -->
                              </div>
                            </div> 

                            <span class="pr-4 "> 
                                    <div placeholder=""
                                      v-html="task.info.overall_assessment_percentage"
                                        
                                        ></div>
                                    </span>
                                  <span class="pr-4 "> 
                                    <label class="input-grading-label">{{task.info.overall_assessment_label}}</label>
                                    <!-- <div placeholder="mastery"
                                      class="input-grading-label"
                                      
                                        
                                        ></div> -->
                                    </span>
                              
                            <!-- <span>{{task.info.card_type}}</span> -->

                      </div>
                    </div>
              
                    <div class="col-3" v-if="task.info.card_type === 'dialogic'">
                      <div class="row d-inline-flex" style="float: right;" v-for="(question,index2) of task.questions" :key=index2>

                            <div class="custom-span-style-grade-overall" >
                              <div >
                                <label >{{question.concept}}</label>
                              
                                <!-- <span >{{question.concept}}</span> -->
                              </div>
                            </div> 

                            <span class="pr-4 "> 
                                <div placeholder=""
                                    class="custom-input-style-grade-number2"
                                    
                                    v-html="question.grade"
                                      ></div>
                                </span>

                      </div>

                    </div>

                    <div class="col-4">
                          <div class="row d-inline-flex" style="float: right;">

                                <div class="custom-span-style-grade-overall" >
                                  <div >
                                    <label >Overall Weight</label>
                                    <!-- <span >Overall Weight</span> -->
                                  </div>
                                </div> 

                                <span class="pr-4 "> 
                                    <div placeholder=""
                                    v-html="task.info.ilo_weight"
                                        class="custom-input-style-grade-number2"
                                        
                                        
                                        ></div>
                                    </span>

                          </div>
                          
                        </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="task.info.card_type === 'email'">
              <div class="col-12" >
                <div class="learner-grading-item">
                  <div class="row d-inline-flex" style="float: right;">
                    <div class="col-5">
                      <span>{{task.info.name}}</span>
                      <div class="row d-inline-flex" style="float: right;">

                            <div class="custom-span-style-grade-overall" >
                              <div >
                                <label >Overall Assessment</label>
                              
                                <!-- <span >Overall Assessment</span> -->
                              </div>
                            </div> 

                            <span class="pr-4 "> 
                                <div placeholder=""
                                    
                                    
                                      ></div>
                                </span>
                              <span class="pr-4 "> 
                                <label class="input-grading-label">mastery</label>
                                <!-- <div placeholder="mastery"
                                  class="input-grading-label"
                                    
                                    
                                      ></div> -->
                                </span>
                              
                            <!-- <span>{{task.info.card_type}}</span> -->

                      </div>
                    </div>
                  

                    <div class="col-3" >
                      <div class="row d-inline-flex" style="float: right;" v-for="(question,index2) of task.questions" :key=index2>

                            <div class="custom-span-style-grade-overall" >
                              <div >
                                <label >skill {{index2 + 1}}</label>
                                <!-- <span >skill {{index2 + 1}}</span> -->
                              </div>
                            </div> 

                            <span class="pr-4 "> 
                                <div placeholder=""
                                
                                    class="custom-input-style-grade-number2"
                                    
                                      ></div>
                                </span>

                      </div>

                    </div>

                    <div class="col-4">
                          <div class="row d-inline-flex" style="float: right;">

                                <div class="custom-span-style-grade-overall" >
                                  <div >
                                    <label >Overall Weight</label>
                                    <!-- <span >Overall Weight</span> -->
                                  </div>
                                </div> 

                                <span class="pr-4 "> 
                                    <div placeholder=""
                                    v-html="task.info.ilo_weight"
                                        class="custom-input-style-grade-number2"
                                        
                                        
                                        ></div>
                                    </span>

                          </div>
                          
                        </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="task.info.card_type === 'submission'">
              <div class="col-12" >
                <div class="learner-grading-item">
                  <div class="row d-inline-flex" style="float: right;">
                    <div class="col-5">
                      <span>{{task.info.name}}</span>
                      <div class="row d-inline-flex" style="float: right;">

                            <div class="custom-span-style-grade-overall" >
                              <div >
                                <label >Overall Assessment</label>
                              
                                <!-- <span >Overall Assessment</span> -->
                              </div>
                            </div> 

                            <span class="pr-4 "> 
                                    <div placeholder=""
                                      v-html="task.info.overall_assessment_percentage"
                                        
                                        ></div>
                                    </span>
                                  <span class="pr-4 "> 
                                    <label class="input-grading-label">{{task.info.overall_assessment_label}}</label>
                                    <!-- <div placeholder="mastery"
                                      class="input-grading-label"
                                      
                                        
                                        ></div> -->
                                    </span>
                              
                            <!-- <span>{{task.info.card_type}}</span> -->

                      </div>
                    </div>

                    <div class="col-3" >
                      <div class="row d-inline-flex" style="float: right;" v-for="(question,index2) of task.questions" :key=index2>

                            <div class="custom-span-style-grade-overall" >
                              <div >
                                <label >Rubric {{index2 + 1}}</label>
                                <!-- <span >skill {{index2 + 1}}</span> -->
                              </div>
                            </div> 

                            <span class="pr-4 "> 
                                <div placeholder=""
                                  v-html="question.score"
                                
                                    class="custom-input-style-grade-number2"
                                    
                                      ></div>
                                </span>

                      </div>

                    </div>


                    <div class="col-4">
                      <div class="row d-inline-flex" style="float: right;">

                            <div class="custom-span-style-grade-overall" >
                              <div >
                                <label >Overall Weight</label>
                                <!-- <span >Overall Weight</span> -->
                              </div>
                            </div> 

                            <span class="pr-4 "> 
                                <div placeholder=""
                                
                                    class="custom-input-style-grade-number2"
                                    
                                    v-html="task.info.ilo_weight"
                                      ></div>
                                </span>

                      </div>
                      
                    </div>

                  </div>
                </div>
              </div>
          </div>
          </div>
        </div>
    </modal>
</template>
<script>
import api from "../services/api";
export default {
  data() {
    return {
      user_first_name: '',
      user_last_name: '',
      overall_grade_label: '',
      overall_grade_percentage: '',
      learn_mod_name: '',
      learnerSelectedIndex: [], 
      userSectionID: ''
    };
  },
  
  methods : {
    beforeOpen(event) {
      console.log(event, "*** event");
      this.userSectionID = event.params.userSectionID;
      this.user_first_name = event.params.first_name;
      this.user_last_name = event.params.last_name
      this.getLearnerData(this.userSectionID);
    },
    getLearnerData(userSectionID){
      api
        .getLearnerGradeReports(userSectionID)
          .then(res => {
            console.log(res, "**res")
            let response = res.data;
            let lo_assessments = response.data;
            this.user_first_name = response.user_first_name;
            this.user_last_name = response.user_last_name;
            this.overall_grade_label = response.overall_grade_label;
            this.overall_grade_percentage = response.overall_grade_percentage;
            this.learn_mod_name = response.learn_mod_name;
            this.learnerSelectedIndex = []

            for (let i =0; i < lo_assessments.length; i++)
              {
                let temp = [];
                  console.log(lo_assessments[i])

                if (lo_assessments[i].card_type === 'quiz') 
                  {
                    const rowx = {
                      card_type: lo_assessments[i].card_type,
                      name: lo_assessments[i].name,
                      ilo_weight: lo_assessments[i].data.attributes.ilo_weight,
                      overall_assessment_percentage: String(lo_assessments[i].data.attributes.overall_percentage).split('.')[0]+" %",
                      //overall_assessment_label: lo_assessments[i].data.attributes.overall_assmnt_item.attributes.assessment_label
                    }
                  for (let j =0; j< lo_assessments[i].data.attributes.quiz_responses.length;j++)
                    {
                      const rwox2 ={
                        correct: lo_assessments[i].data.attributes.quiz_responses[j].attributes.is_correct,              
                      }
                      temp.push(rwox2)
                    }
                  const rowx3 ={
                    info: rowx,
                    questions: temp,
                  }
                  this.learnerSelectedIndex.push(rowx3)
                }
              else if (lo_assessments[i].card_type === 'dialogic') 
                {
                  const rowx ={
                    card_type: lo_assessments[i].card_type,
                    name: lo_assessments[i].name,
                    ilo_weight: lo_assessments[i].data.attributes.ilo_weight,
                    // overall_assessment_percentage: String(lo_assessments[i].data.attributes.overall_percentage).split('.')[0]+" %",
                    // overall_assessment_label: lo_assessments[i].data.attributes.overall_assmnt_item.attributes.assessment_label,
                  }
                  for ( let key in lo_assessments[i].data.attributes.dialogic_debrief_evaluation)
                    {
                      const rwox2 ={
                        concept: lo_assessments[i].data.attributes.dialogic_debrief_evaluation[key][0].attributes.question_concept,
                        grade: lo_assessments[i].data.attributes.dialogic_debrief_evaluation[key][0].attributes.topic_name,
                                        
                      }
                      temp.push(rwox2)
                    }
                    const rowx3 ={
                    info: rowx,
                    questions: temp,
                  }
                  this.learnerSelectedIndex.push(rowx3)
                }
              else if (lo_assessments[i].card_type === 'submission') 
                {
                  const rowx ={
                    card_type: lo_assessments[i].card_type,
                    name: lo_assessments[i].name,
                    ilo_weight: lo_assessments[i].ilo_weight,
                    overall_assessment_percentage: String(lo_assessments[i].overall_grade).split('.')[0]+" %",
                    overall_assessment_label: lo_assessments[i].data[0].attributes.rubric_criteria,
                  }
                  for ( let j =0; j<lo_assessments[i].data.length;j++)
                    {
                      const rwox2 ={
                        score: lo_assessments[i].data[j].attributes.score,
                                        
                      }
                      temp.push(rwox2)
                    }
                  const rowx3 ={
                    info: rowx,
                    questions: temp,
                  }
                  this.learnerSelectedIndex.push(rowx3)
                }
              else if (lo_assessments[i].card_type === 'email') 
                {
                 for (let j =0; j<lo_assessments[i].data.attributes.learner_debrief_items.length;j++)
                   {
                     const rwox2 ={correct: lo_assessments[i].data.attributes.learner_debrief_items[j].attributes.email_assessment_item.attributes.assessment_label,
                                      
                     }
                     temp.push(rwox2)
                   }
               }
              else{
                
                  const rwox2 ={correct: false,}
                  temp.push(rwox2)  
              }
            }
       });
    }
  }
}
</script>
