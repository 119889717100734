<template>
  <div class="container-full">
    <div class="top-header">
      <backDashboard />
      <headerTitle />
      <userLogout />
    </div>
    <div class="site-content">
      <menuSidebar />
      <comingSoon />
    </div>
  </div>
</template>
<script>
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import menuSidebar from "../components/menuSidebar.vue";
import headerTitle from "../components/headerTitle.vue";
import comingSoon from "../components/comingSoon.vue";

export default {
  name: "exportAssessments",
  components: {
    backDashboard,
    userLogout,
    menuSidebar,
    headerTitle,
    comingSoon
  },
  data() {
      return {
      }
  },
  methods: {
  },
  created() {
  }
};
</script>
