<template>
  <modal
    name="videoModal"
    class="modal-bottomScreen"
    width="100%"
    height="auto"
    :clickToClose="true"
    :scrollable="false"
    @before-open="beforeOpen"
  >
    <div class="modal-content modal-grade-content">
      <button
        type="button"
        class="modal-close"
        @click="$modal.hide('videoModal')"
      >
        <em class="icon-close"></em>
      </button>
      <div class="modal-body py-4 px-5">
        <h1>{{ this.videoData.title }}</h1>
        <div class="content">
          <div class="introduction-video">
            <div
              class="wistia_responsive_wrapper data-video"
              style="
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
              "
            >
              <iframe
                :src="preIframUrl + videoData.wistia_code + postIframUrl"
                title="Video.mp4"
                class="wistia_embed"
                name="wistia_embed"
              ></iframe>
            </div>
          </div>
          <div class="video-transcript">
            <h2>Transcript</h2>
            <div v-html="videoData.transcript"></div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "videoModal",
  data() {
    return {
      videoData: "",
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
    };
  },
  methods: {
    beforeOpen(event) {
      this.videoData = event.params.video;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-grade-content {
  width: auto;
}
.introduction-video {
  height: 100%;
  max-height: 100px;
  width: 100%;
  margin-right: 64px;
}
.video-transcript {
  display: block;
  position: relative;
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  h2 {
    font-weight: bold;
  }
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.modal-body {
  display: flex;
  flex-direction: column;
  h1 {
    font-family: 'robotoblack', sans-serif;
    font-size: 36px;
    margin-bottom: 32px;
  }
  h2 {
    font-size: 28px;
    margin-bottom: 16px;
  }
}
// .modal-grade-content {
//   margin: 0 !important;
// }
</style>