<template>
  <div id="resetPassword" class="login-container">
    <loginSidebar />
    <div class="login-right">
      <div class="login-form">
        <div class="form-group">
          <h3>Password Reset</h3>
          <p>A password reset code has been sent to: <span>briangilbert@gmail.com</span></p>
          <a class="form-link" href="javascript:void(0);">Change Email</a>
        </div>
        <div class="form-group">
          <label>Password Reset Code</label>
          <input
            type="text"
            class="form-control"
            name="username"
          />
        </div>
        <div class="form-group pt-1 mb-3">
          <button type="button" class="btn primary" v-on:click="login()">Confirm</button>
        </div>
        <div class="text-center">
          <a href="javascript:void(0);" class="forgot-link">Resend Code</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import loginSidebar from "../components/loginSidebar.vue";
export default {
  name: "resetPassword",
  components: {
    loginSidebar,
  },
  data() {
    return {
    };
  },
  methods: {

  }
};
</script>
