<template>
  <div class="top-header-title" v-if="moduleDetails && moduleDetails.attributes">
    <!--<img :src="moduleDetails.attributes.learn_mods[0].attributes.photo_url.thumbnail" />-->
    <div class="top-header-details">
      <h1>{{moduleDetails.attributes.name}} <span v-if="moduleDetails.attributes.time_started">({{moduleDetails.attributes.time_started}} - {{moduleDetails.attributes.time_completed}})</span></h1>
      
    </div>
  </div>
</template>
<script>
export default {
  name: "headerTitle",
  props: ["moduleDetails"],
  data() {
    return {
      moduleData: {}
    };
  },
  created() {
    setTimeout(() => {
      this.moduleData = JSON.parse(localStorage.getItem("section"));
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
.top-header-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h1 {
    font-size: 18px;
  }
}
.top-header-title img {
  border-radius: 4px;
}

.top-header-details {
  margin-left: 16px;
  flex-direction: column;
}
</style>
