<template>
  <div class="container-full">
    <div class="top-header">
      <backDashboard />
      <headerTitle :moduleDetails="section" />
      <userLogout />
    </div>
    <div class="site-content">
      <menuSidebar />
      <div class="main-content pt-4">
        <div class="main-title align-items-center">
          <h3>Milestone Date<span class="text-lowercase">(s)</span></h3>

          <!-- Modules dropdown Start -->
          <div class="main-searchForms">
            <div class="search-dropdown" v-if="modules_all.length > 1">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ ModuleSelectedName }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                <a
                  class="dropdown-item"
                  type="button"
                  @click="toggleSort(sortColumn, sortOrder, '')"
                ></a>
                <a
                  v-for="(mods, index) in modules_all"
                  :key="index"
                  class="dropdown-item"
                  type="button"
                  @click="getSelectedModules(index)"
                  >{{ mods.attributes.name }}</a
                >
              </div>
            </div>
          </div>

          <!-- Modules dropdown End -->
        </div>
        <div class="data-table column-7">
          <div class="data-head">
            <div class="data-col">Learning object</div>
            <div class="data-col">Release hold date</div>
            <div class="data-col">&nbsp;</div>
            <div class="data-col" v-if="!isBasicInstructor">Action</div>
          </div>
          <div class="data-content">
            <!-- data row repeat here -->
            <ValidationObserver ref="form">
              <span v-if="milestone_dates.length > 0">
                <span
                  v-for="(milestone, index) in milestone_dates"
                  :key="index"
                >
                  <div v-if="!milestone.attributes._destroy" class="data-row">
                    <div class="data-col">
                      <span class="form-data">
                        <div class="data-dropdown">
                          <button
                            :class="[
                              'btn btn-secondary dropdown-toggle',
                              isActive &&
                              !milestone.attributes.learning_object_id
                                ? 'form-invalid'
                                : '',
                            ]"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            :disabled="isBasicInstructor"
                          >
                            <div class="select-value text-truncate">
                              <div
                                v-if="milestone.attributes.learning_object_id"
                              >
                                <div
                                  v-for="lo in loDatas"
                                  :key="lo.id"
                                  @click="
                                    setLo(
                                      lo.attributes.learning_object_id,
                                      index
                                    )
                                  "
                                >
                                  <div
                                    v-if="
                                      parseInt(
                                        milestone.attributes.learning_object_id
                                      ) ==
                                      parseInt(lo.attributes.learning_object_id)
                                    "
                                    class="text-truncate"
                                  >
                                    {{ lo.attributes.card_order }}:
                                    {{ lo.attributes.name }}
                                  </div>
                                </div>
                              </div>
                              <div v-else class="data-dropdown-placeholder">
                                Select Learning Object
                              </div>
                            </div>
                          </button>
                          <div
                            class="dropdown-menu dropdown-scroll"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              class="dropdown-item"
                              type="button"
                              v-for="lo in loDatas"
                              :key="lo.id"
                              @click="
                                setLo(lo.attributes.learning_object_id, index)
                              "
                            >
                              {{ lo.attributes.card_order }}:
                              {{ lo.attributes.name }}
                            </button>
                          </div>
                          <span
                            :class="[
                              isActive &&
                              !milestone.attributes.learning_object_id
                                ? 'invalid'
                                : '',
                            ]"
                            :style="[
                              isActive &&
                              !milestone.attributes.learning_object_id
                                ? { display: 'block' }
                                : { display: 'none' },
                            ]"
                            >Mandatory</span
                          >
                        </div>
                      </span>
                    </div>
                    
                    
                   
                    <div class="data-col">
                      <ValidationProvider
                        class="form-data"
                        v-if="milestone.attributes.hold_on_complete"
                        name="Date"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <div
                          :class="[
                            'data-calender',
                            isActive && errors[0] ? 'form-invalid' : '',
                          ]"
                        >
                          <datetime
                            type="datetime"
                            v-model="milestone.attributes.release_hold_date"
                            use12-hour
                            :format="'MMM dd yyyy, hh:mm a'"
                            :disabled="isBasicInstructor"
                          ></datetime>
                        </div>
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="errors[0]"
                          >Mandatory</span
                        >
                      </ValidationProvider>
                      
                    </div>
                    <div class="data-col">
                      <div
                        :class="[
                          'note-dropdown',
                          !milestone.attributes.hold_note ? 'note-grey' : '',
                        ]"
                      >
                        <button
                          type="button"
                          :id="'dropdown' + index"
                          @click="showRecords(index)"
                        >
                          <img src="../assets/images/icon-yellownote.svg" />
                        </button>
                        <div
                          :class="[
                            'dropdown-menu',
                            'dropdown-menu-right',
                            show === index ? 'show' : 'block',
                          ]"
                          aria-labelledby="dropdownMenu2"
                        >
                          <div class="head">
                            <h4>Hold Message</h4>
                            <a @click="saveRemoveNote()">
                              <em class="icon-close"></em>
                            </a>
                          </div>
                          <div class="content">
                            <textarea
                              class="form-control"
                              rows="4"
                              maxlength="500"
                              v-model="milestone.attributes.hold_note"
                              :disabled="isBasicInstructor"
                            ></textarea>
                          </div>
                          <div class="footer">
                            <button
                              class="btn danger"
                              @click="saveRemoveNote(index, true)"
                              v-if="
                                milestone.attributes.hold_note &&
                                !isBasicInstructor
                              "
                            >
                              Remove Note
                            </button>
                            <button
                              class="btn primary"
                              @click="saveRemoveNote()"
                              v-if="!isBasicInstructor"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="data-col" v-if="!isBasicInstructor">
                      <div class="data-trash">
                        <a>
                          <em
                            class="icon-trash"
                            @click="deleteLo(milestone.id, index, true)"
                          ></em>
                        </a>
                      </div>
                      <div
                        class="data-trash"
                        v-if="milestone.attributes.isSave"
                      ></div>
                    </div>
                  </div>
                </span>
              </span>
              <div class="data-row" v-if="!isBasicInstructor">
                <div class="data-col">
                  <button @click="AddLo()" class="btn primary w-100">
                    + Learning Object
                  </button>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
        <div class="main-title button-right">
          <button
            type="button"
            class="btn primary save-btn"
            @click="saveData()"
            v-if="!isBasicInstructor"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <modal
      name="deleteRubricModal"
      height="auto"
      :width="350"
      :scrollable="true"
    >
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">
          Are you sure you want to delete?
        </div>
        <div class="modal-footer justify-content-end">
          <button
            type="button"
            @click="$modal.hide('deleteRubricModal')"
            class="btn medium default"
          >
            Cancel
          </button>
          <button
            type="button"
            @click="deleteLo(deleteId, deleteIndex, false)"
            class="btn medium primary"
          >
            Delete
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import userLogout from "../components/userLogout.vue";
import backDashboard from "../components/backDashboard.vue";
import menuSidebar from "../components/menuSidebar.vue";
import headerTitle from "../components/headerTitle.vue";
import { Datetime } from "vue-datetime";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";

export default {
  name: "manageRubric",
  components: {
    backDashboard,
    userLogout,
    menuSidebar,
    datetime: Datetime,
    headerTitle,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      ModuleSelectedName: "",
      modules_all: [],
      loDatas: [],
      modules: {},
      section: {},
      milestone_dates: [],
      destroy_dates: [],
      show: null,
      isActive: "",
      date: "",
      deleteId: "",
      deleteIndex: "",
      isBasicInstructor: false,
      search_learner: "",
      sortOrder: "asc",
      sortColumn: "name",
      lo_index: "",
      page: 1,
    };
  },
  methods: {
    getSelectedModules(index) {
      this.milestone_dates = [];
      api
        .getLOList(this.section.id, this.modules_all[index].id)
        .then((res) => {
          console.log(res);
          this.loDatas = res.data.lo_list;
          // console.log(this.loDatas, "*** this.loDatas");
          this.getMilestones(this.section.id, this.modules_all[index].id);
        })
        .catch(() => {});
      this.ModuleSelectedName = this.modules_all[index].attributes.name;
    },
    getMultiELMList() {
      api
        .getMultiELMList(this.section.id)
        .then((res) => {
          this.modules_all = res.data.data.attributes.learn_mods;
          this.ModuleSelectedName = this.modules_all[0].attributes.name;
          console.log(this.modules_all);
        })
        .catch(() => {});
    },
    setInstructorPermissions() {
      this.instructorInfo = JSON.parse(
        localStorage.getItem("instructor")
      ).users;
      let role = this.instructorInfo.attributes.user_roles[0];
      if (role == "instructor_basic") {
        this.isBasicInstructor = true;
      }
    },
    showRecords(i) {
      this.show = i;
    },
    getLoListData() {
      this.section = JSON.parse(localStorage.getItem("section"));

      // Get Section Information
      api
        .getLearnerDetails(
          this.section.id,
          this.page,
          this.search_learner,
          this.sortColumn,
          this.sortOrder,
          this.lo_index
        )
        .then((res) => {
          this.section_details = res.data.section;
          api
            .getLOList(
              this.section.id,
              this.section_details.attributes.learn_mods[0].id
            )
            .then((res) => {
              console.log(res);
              this.loDatas = res.data.lo_list;
              // console.log(this.loDatas, "*** this.loDatas");
              this.getMilestones(
                this.section.id,
                this.section_details.attributes.learn_mod.id
              );
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    getMilestones(id, elm_id) {
      utilFunctionService.showLoader();
      api
        .getMilestones(id, elm_id)
        .then((res) => {
          if (res.data && res.data.data) {
            if (res.data.data.length > 0) {
              this.milestone_dates = [];
              this.milestone_dates = res.data.data;
              this.milestone_dates.forEach((milestone) => {
                milestone.attributes._destroy = false;
                milestone.attributes.release_hold_date = milestone.attributes
                  .release_hold_date
                  ? moment(milestone.attributes.release_hold_date).format()
                  : "";
                milestone.attributes.completed_by = milestone.attributes
                  .completed_by
                  ? moment(milestone.attributes.completed_by).format()
                  : "";
              });
            } else {
              this.AddLo();
            }
          }
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    deleteLo(id, indexs, isModel) {
      if (isModel) {
        this.deleteId = id;
        this.deleteIndex = indexs;
        this.$modal.show("deleteRubricModal");
      } else {
        this.$modal.hide("deleteRubricModal");
        if (id) {
          utilFunctionService.showLoader();
          utilFunctionService.showSuccess(
            commonConfig.appMessages.deleteRecord
          );
          utilFunctionService.hideLoader();
          this.milestone_dates[indexs].attributes._destroy = true;
          this.destroy_dates.push(this.milestone_dates[indexs]);
          this.milestone_dates.splice(indexs, 1);
        } else {
          this.milestone_dates.splice(indexs, 1);
          utilFunctionService.hideLoader();
        }
      }
    },
    AddLo() {
      this.isActive = false;
      this.milestone_dates.push({
        id: "",
        type: "section_milestone",
        attributes: {
          section_id: "",
          learning_object_id: "",
          hold_note: "",
          hold_on_complete: true,
          warning_email_days: "",
          completed_by: "",
          release_hold_date: "",
          isSave: true,
        },
      });
    },
    saveRemoveNote(index, status) {
      if (status) {
        this.milestone_dates[index].attributes.hold_note = "";
      }
      this.show = null;
    },
    saveData(id, index) {
      if (this.milestone_dates.length > 0 || this.destroy_dates.length > 0) {
        this.$refs.form.validate().then((success) => {
          this.isActive = true;
          if (!success) {
            return;
          } else {
            utilFunctionService.showLoader();
            let fData = new FormData();
            const lo_listdata = [];
            let indexdata = null;
            this.milestone_dates.forEach((milestone) => {
              indexdata = lo_listdata.findIndex(
                (y) =>
                  parseInt(y) ===
                  parseInt(milestone.attributes.learning_object_id)
              );
              if (indexdata > -1) {
                utilFunctionService.showerr(
                  commonConfig.appMessages.sameLoRecord
                );
                return;
              } else {
                lo_listdata.push(milestone.attributes.learning_object_id);
              }
              if (milestone.id) {
                fData.append(
                  "section[section_milestones_attributes][][id]",
                  milestone.id
                );
              }
              fData.append(
                "section[section_milestones_attributes][][learning_object_id]",
                milestone.attributes.learning_object_id
              );
              fData.append(
                "section[section_milestones_attributes][][hold_note]",
                milestone.attributes.hold_note
              );
              fData.append(
                "section[section_milestones_attributes][][hold_on_complete]",
                milestone.attributes.hold_on_complete
              );
              if (!milestone.attributes.hold_on_complete) {
                milestone.attributes.release_hold_date = "";
              }
              fData.append(
                "section[section_milestones_attributes][][release_hold_date]",
                milestone.attributes.hold_on_complete
                  ? moment(milestone.attributes.release_hold_date).format("LLL")
                  : ""
              );
              fData.append(
                "section[section_milestones_attributes][][_destroy]",
                milestone.attributes._destroy ? true : false
              );
            });
            if (indexdata !== null && indexdata > -1) {
              utilFunctionService.hideLoader();
              return;
            }
            this.destroy_dates.forEach((milestone) => {
              if (milestone.id)
                fData.append(
                  "section[section_milestones_attributes][][id]",
                  milestone.id
                );
              fData.append(
                "section[section_milestones_attributes][][_destroy]",
                milestone.attributes._destroy ? true : false
              );
            });
            api
              .saveMilestones(this.section.id, fData)
              .then((res) => {
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.saveRecord
                );
                utilFunctionService.hideLoader();
                console.log(id, index, res);
                this.getMilestones(this.section.id, this.section.attributes.learn_mod.id);
              })
              .catch(() => {
                utilFunctionService.hideLoader();
              });
          }
        });
      } else {
        utilFunctionService.showLoader();
        utilFunctionService.showerr(commonConfig.appMessages.addLoRecord);
        utilFunctionService.hideLoader();
      }
    },
    setLo(id, index) {
      //console.log(this.milestone_dates, "*** this.milestone_dates");
      //console.log(id, "*** setLo id");
      //console.log(index, "*** setLo index");
      this.milestone_dates[index].attributes.learning_object_id = id;
    },
  },
  created() {
    this.section = JSON.parse(localStorage.getItem("section"));
    this.module = this.section;
    let dateVal = new Date();
    this.date = moment(dateVal).format();
    this.getLoListData();
    this.setInstructorPermissions();
    this.getMultiELMList();
    this.getMilestones(this.section.id, this.section.attributes.learn_mods[0].id);
  }
};
</script>


<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";

.button-right {
  float: right;
}
</style>
