<template>
  <div id="resetPassword" class="login-container">
    <loginSidebar />
    <div class="login-right">
      <div class="login-form" v-if="!passwordChanged && invalidToken">
        <p class="form-group">
          It looks like you clicked on an invalid password reset link. Please try again.
        </p>
        <div class="form-group">
          <button type="button" class="btn primary" v-on:click="goToForgotPassword()">
            Forgot password?
          </button>
        </div>
      </div>
      <div class="login-form" v-if="!passwordChanged && !invalidToken">
        <ValidationObserver ref="form">
          <form>
            <div class="form-group">
              <h3>Set New Password</h3>
              <p>Enter your new password below</p>
            </div>
            <div class="form-group">
              <label>New Password</label>
              <div class="position-relative">
                <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                  <input
                    :type="passwordFieldType"
                    name="password"
                    class="form-control"
                    @input="valueChange()"
                    v-model="password"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
                  <a
                    class="forgot-show"
                    @click="switchVisibility"
                    v-if="passwordFieldType === 'password'"
                  >Show</a>
                  <a
                    class="forgot-show"
                    @click="switchVisibility"
                    v-if="passwordFieldType === 'text'"
                  >Hide</a>
              </div>
            </div>
            <div class="form-group pt-1 mb-3">
              <button type="button" class="btn primary" v-on:click="resetPassword()">Set New Password</button>
            </div>
            <div class="text-center">
              <a v-on:click="returnToLogin()" class="forgot-link">Back to Log In</a>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div v-if="passwordChanged" class="login-form">
        <h4>Password has been changed</h4>
          <p class="form-group">
            Your password has been successfully changed. You can now login with your new password.
          </p>
          <div class="form-group">
            <button type="button" class="btn primary" v-on:click="returnToLogin()">
              Login
            </button>
          </div>
      </div>
    </div>
  </div>
</template>
<script>

import api from "../services/api";
import loginSidebar from "../components/loginSidebar.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "resetPassword",
  components: {
    ValidationProvider,
    ValidationObserver,
    loginSidebar
  },
  data() {
    return {
      password : '',
      isActive: false,
      invalidCred: false,
      passwordFieldType: "password",
      invalidToken: '',
      passwordChanged: false,
      token: ''
    };
  },
  methods: {
    valueChange() {
      this.invalidCred = false;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    resetPassword() {
     this.$refs.form.validate().then(success => {
          this.isActive = true;
          if (!success) {
            return;
          } else {
            this.isActive = false;
            const _fb = new FormData();
            _fb.append("reset_password[token]", this.token);
            _fb.append("reset_password[password]", this.password);
            api
              .resetPassword(_fb)
              .then(() => {
                this.passwordChanged = true
              }).catch(() => {})
        }
      });
    },
    returnToLogin() {
      this.$router.replace({ name: "login" });
    },
    goToForgotPassword() {
      this.$router.replace({ name: "forgot-password" });
    }
  },
  async created(){
    this.token = this.$route.params.token
      api
        .verifyToken(this.token)
        .then((res) => {
          if (res.data.is_valid)
            this.invalidToken = false
          else
           this.invalidToken = true
        });
    }
};
</script>
