import axios from 'axios'
import { utilFunctionService } from "@/utils/utils.service"

const SERVER_URL = process.env.VUE_APP_URL
const instance = axios.create({
  baseURL: SERVER_URL
});

instance.interceptors.response.use(function(res) {
  return res
}, function(error) {
  if (error.response.data.error === "Signature verification raised") {
    utilFunctionService.removeLocalStorageService('instructor');
    utilFunctionService.removeLocalStorageService('sso_authenticated');
    utilFunctionService.removeLocalStorageService('profile');
    utilFunctionService.removeLocalStorageService('profile_url');
    location.reload();
  } else {
    return Promise.reject(error);
  }
})

export default {
  async execute(method, resource, data, config) {
    let accessToken = utilFunctionService.getAuthToken();
    return instance({
      method: method,
      url: resource,
      data,
      headers: {
        Authorization: `${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin, Content-Type, X-Auth-Token"
      },
      ...config
    })
  },
  async executeFormData(method, resource, data, config) {
    let accessToken = utilFunctionService.getAuthToken();
    return instance({
      method: method,
      url: resource,
      data,
      headers: {
        Authorization: `${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      ...config
    })
  },

  loginUser(user) {
    return this.execute('POST', '/v1/users/login.json', { user })
  },
  loginLtiUser(form) {
    return this.execute('POST', '/v1/users/login.json', form)
  },
  getList(type, pageNum) {
    return this.execute('GET', `instructor/v1/modules.json?type=${type}&page=${pageNum}`)
  },
  searchModules(searchQuery, type, pageNum) {
    return this.execute('GET', `instructor/v1/modules/search_modules.json?type=${type}&search=${searchQuery}&page=${pageNum}`)
  },
  getMultiELMList(section_id) {
    return this.execute('GET', `instructor/v1/modules/${section_id}/section_multi_elm.json`)
  },
  getMultiELMListLight(section_id) {
    return this.execute('GET', `instructor/v1/modules/${section_id}/section_multi_elm_light.json`)
  },
  getResponse(id) {
    return this.execute('GET', `instructor/v1/modules/${id}.json`)
  },
  updateMsgSetting(id, fd) {
    return this.execute('PATCH', `instructor/v1/sections/${id}.json`, fd)
  },
  getLOList(id, elm_id) {
    return this.execute('GET', `instructor/v1/modules/${id}/section_lo_with_detail.json?learn_mod_id=${elm_id}`)
  },
  updateSectionLo(id, fb) {
    return this.execute('PATCH', `instructor/v1/learning_object_sections/${id}.json`, fb )
  },
  rubricData(customerCourseId, id) {
    return this.execute('GET', `instructor/v1/customer_courses/${customerCourseId}/rubric_details.json?learning_object_id=${id}`)
  },
  createRubric(fd, customerCourseId, id) {
    return this.execute('POST', `instructor/v1/customer_courses/${customerCourseId}/rubric_details.json?learning_object_id=${id}`, fd)
  },
  removeRubric(customerCourseId, rubricId, loId) {
    return this.execute('DELETE', `instructor/v1/customer_courses/${customerCourseId}/rubric_details/${rubricId}.json?learning_object_id=${loId}`)
  },
  editRubric(customerCourseId, rubricId, loId, fd) {
    return this.execute('PATCH', `instructor/v1/customer_courses/${customerCourseId}/rubric_details/${rubricId}.json?learning_object_id=${loId}`, fd)
  },
  getMilestones(id,elm_id) {
    return this.execute('GET', `instructor/v1/sections/${id}/section_milestones.json?learn_mod_id=${elm_id}`)
  },
  deleteMilestones(section_id, id) {
    return this.execute('DELETE', `instructor/v1/sections/${section_id}/section_milestones/${id}.json`)
  },
  saveMilestones(section_id,fb) {
    return this.execute('PATCH', `instructor/v1/sections/${section_id}/section_milestones/1.json`,fb)
  },
  characterConfig(customerCourseId, loId) {
    return this.execute('GET', `instructor/v1/customer_courses/${customerCourseId}/rubric_details/character_responses.json?learning_object_id=${loId}`)
  },
  updateInterimResp(customerCourseId,data) {
    return this.execute('POST', `instructor/v1/customer_courses/${customerCourseId}/rubric_details/update_lo_detail.json`, data);
  },
  removeEmailTypeRubric(customerCourseId, rubricId, loId) {
    return this.execute('DELETE', `instructor/v1/customer_courses/${customerCourseId}/rubric_details/${rubricId}.json?learning_object_id=${loId}&rubric[parent_id]=${rubricId}`)
  },
  distributeWeight(customerCourseId, loId, data) {
    return this.execute('POST', `instructor/v1/customer_courses/${customerCourseId}/rubric_details/update_lo_detail.json?learning_object_id=${loId}`, data);
  },
  distributeWeightEqually(customerCourseId, loId, data) {
    return this.execute('POST', `instructor/v1/customer_courses/${customerCourseId}/rubric_details/update_lo_detail.json?learning_object_id=${loId}`, data);
  },
  getSectionBreakdown(id) {
    return this.execute('GET', `instructor/v1/modules/${id}/student_list_csv?section_id=${id}`);
  },
  getLearnerDetails(id ,page ,search ,colName ,order ,index = '', learn_mod_id) {
    return this.execute('GET', `instructor/v1/modules/${id}/learner_details.json?page=${page}&sort_column=${colName}&sort_order=${order}&lo_index=${index}&search=${search}&learn_mod_id=${learn_mod_id}`);
  },
  getSectionLo(id) {
    return this.execute('GET', `instructor/v1/modules/${id}/section_lo.json`);
  },
  updateProfile(id, data) {
    return this.execute('PATCH', `instructor/v1/profiles/${id}.json`, {user : data});
  },
  getInstructorProfile(id) {
    return this.execute('GET', `instructor/v1/profiles/${id}.json`);
  },
  learningObjLists(id, section_id) {
    return this.execute('GET', `instructor/v1/user_sections/learner_view.json?learn_mod_id=${id}&section_id=${section_id}`);
  },
  plagiarismLOsList(id, learn_mod_id) {
    return this.execute('GET', `instructor/v1/sections/${id}/plagiarism_los.json?learn_mod_id=${learn_mod_id}`);
  },
  forgotPassword(data) {
    return this.execute('POST',`v1/password/forgot.json`,data)
  },
  stickyNotesOnLearnerView(id, data) {
    return this.execute('PATCH', `instructor/v1/sections/${id}.json`, data);
  },
  verifyToken(token){
    return this.execute('GET', `/v1/password/verify_token.json?token=${token}`)
  },
  resetPassword(form){
    return this.execute('POST', `/v1/password/reset.json`, form)
  },
  learningModulesLists(id) {
    return this.execute('GET', `instructor/v1/user_sections.json?module_id=${id}`);
  },
  updateNotes(id, data) {
    return this.execute('PATCH', `instructor/v1/sections/${id}.json`, data);
  },
  deleteNotes(id, data) {
    return this.execute('PATCH', `instructor/v1/sections/${id}.json`, data);
  },
  removeProfileImage(id) {
    return this.execute('DELETE', `instructor/v1/profiles/${id}/remove_profile_image.json`);
  },
  getLearnerloDetails(sectionId, id) {
    return this.execute('GET', `instructor/v1/user_sections/${id}.json?module_id=${sectionId}`);
  },
  getLearnerSubmissiondata (scorable_type	,scorable_id ,customer_course_id ,learning_object_id) {
    return this.execute('GET', `instructor/v1/submission_gradings.json?scorable_type=${scorable_type}&scorable_id=${scorable_id}&customer_course_id=${customer_course_id}&learning_object_id=${learning_object_id}`);
  },
  saveLearnerSubmissiondata (fb) {
    return this.execute('PATCH', `instructor/v1/submission_gradings/1.json`,fb);
  },
  getUserSubmissions(id) {
    return this.execute('GET', `/v1/user_submissions/${id}.json`);
  },
  getUserEmailSubmissions(id) {
    return this.execute('GET', `/v1/user_email_submissions.json?id=${id}`);
  },
  getSubmissionData(id){
    return this.execute('GET', `/v1/user_submissions/${id}.json`);
  },
  updateloNotes(id, sectionId, data) {
    return this.execute('POST', `instructor/v1/user_sections/${id}/update_user_lo.json?module_id=${sectionId}`, data);
  },
  updateUserLearnObj(id, sectionId, data) {
    return this.execute('POST', `instructor/v1/user_sections/${id}/update_user_lo.json?module_id=${sectionId}`, data);
  },
  updateWritingAssessment(id, data) {
    return this.execute('PATCH', `instructor/v1/writing_assessments/${id}.json`, data);
  },
  updateWritingAssessmentSentence(id, data) {
    return this.execute('PATCH', `instructor/v1/writing_assessment_sentences/${id}.json`, data);
  },
  quizQuestions(id, loId) {
    return this.execute('GET', `/v1/quiz_questions.json?id=${id}&user_lo_id=${loId}`);
  },
  getSubmissionEmailDebrief(id) {
    return this.execute('GET', `/v1/user_submissions/${id}/evaluation.json`);
  },
  getQuizResponse(id) {
    return this.execute('GET', `/v1/quiz_evaluations/${id}.json`);
  },
  getDialogicAnswers(id) {
    return this.execute('GET', `/v1/dialogic_evaluations/${id}/dialogic_answers.json`);
  },
  getdialogicQuestions(dilogic_learn_obj_id,user_lo_id,retry) {
    return this.execute('GET', `/v1/dialogic_questions.json?dilogic_learn_obj_id=${dilogic_learn_obj_id}&user_lo_id=${user_lo_id}&retry=${retry}`);
  },
  dialogicEvaluations(id) {
    return this.execute('GET', `/v1/dialogic_evaluations/${id}.json`);
  },
  createEvaluateDialogy(id) {
    return this.execute('POST', `/v1/dialogic_evaluations/${id}/evaluate.json`);
  },
  getUserChatMessages(id) {
    return this.execute('GET', `/v1/user_chat_messages.json?user_chat_id=${id}`);
  },
  getEvaluateChat(id) {
    return this.execute('GET', `/v1/user_chats/${id}.json`);
  },
  getPreview(type ,id) {
    return this.execute('GET', `instructor/v1/submission_gradings/preview.json?scorable_type=${type}&scorable_id=${id}`);
  },
  finalEvaluation(id) {
    return this.execute('GET', `/v1/modules/${id}/module_details/final_evaluation.json`);
  },
  getEmailSubmissions(id) {
    return this.execute('GET', `/v1/user_email_submissions.json?id=${id}`);
  },
  getSubmissionEmailEvaluation(id ,resp_id) {
    return this.execute('GET', `/v1/user_email_submissions/evaluation.json?id=${id}&email_sub_resp_id=${resp_id}`);
  },
  sendChatMessage(data) {
    return this.execute('POST', `instructor/v1/messages.json`, data);
  },
  getChatMessages(id) {
    return this.execute('GET', `instructor/v1/messages.json?user_learn_obj_id=${id}`);
  },
  savedChatMessages(id, data) {
    return this.execute('PATCH', `instructor/v1/messages/${id}.json`, data);
  },
  getSavedChatMessages() {
    return this.execute('GET', `instructor/v1/messages/saved_messages.json`);
  },
  getQuizQuestions(id) {
    return this.execute('GET', `instructor/v1/learning_object_details/quiz_questions.json?quiz_learn_obj_id=${id}`);
  },
  getDialogicQuestions(id) {
    return this.execute('GET', `instructor/v1/learning_object_details/dialogic_questions.json?dialogic_learn_obj_id=${id}`);
  },
  readMessage(data) {
    return this.execute('POST', `instructor/v1/messages/mark_read.json`, data);
  },
  readSubmissionCount(data) {
    return this.execute('POST', `instructor/v1/notifications/mark_read.json`, data);
  },
  sendFeedback(data) {
    return this.execute('POST', `instructor/v1/submission_gradings/send_all_feedback.json`, data);
  },
  moduleData(id) {
    return this.execute('GET', `instructor/v1/modules/${id}.json`);
  },
  getEmailList(id) {
    return this.execute('GET', `/v1/user_email_evaluations/${id}/user_email_iterations.json`);
  },
  emailFeedbackResponse(id) {
    return this.execute('GET', `/v1/user_email_evaluations/${id}.json`);
  },
  lastSendAllFeedbackDate(sectionId, loId) {
    return this.execute('GET', `instructor/v1/sent_all_feedbacks.json?section_id=${sectionId}&lo_id=${loId}&current_timestamp=${new Date()}`);
  },
  getSectionLoDetails(id) {
    return this.execute('GET', `instructor/v1/modules/${id}/report_data.json`);
  },
  getLoReport(sectionId, loId, learnModId) {
    return this.execute('GET', `instructor/v1/user_sections/learning_object_report?section_id=${sectionId}&learning_object_id=${loId}&learn_mod_id=${learnModId}`);
  },
  getCodingLoAnalysisReport(sectionId, loId, learnModId) {
    return this.execute('GET', `instructor/v1/user_sections/coding_analysis_report?section_id=${sectionId}&learning_object_id=${loId}&learn_mod_id=${learnModId}`);
  },
  getAggregateDialogicReport(sectionId, learnModId) {
    return this.execute('GET', `instructor/v1/user_sections/aggregate_dialogic_report?section_id=${sectionId}&learn_mod_id=${learnModId}}`);
  },
  getAggregateCodingReport(sectionId, learnModId) {
    return this.execute('GET', `instructor/v1/user_sections/aggregate_coding_report?section_id=${sectionId}&learn_mod_id=${learnModId}`);
  },
  getAggregateQuizReport(sectionId, learnModId) {
    return this.execute('GET', `instructor/v1/user_sections/aggregate_quiz_report?section_id=${sectionId}&learn_mod_id=${learnModId}`);
  },
  getAllReports(sectionId, learnModId) {
    return this.execute('GET', `instructor/v1/user_sections/all_reports?section_id=${sectionId}&learn_mod_id=${learnModId}`);
  },
  postContact(contact_data) {
    const data = {
      contact_data
    }
    return this.execute('POST', `/v1/contact_us.json`, data);
  },
  getLearnerGrades(id) {
    return this.execute('GET', `instructor/v1/modules/${id}/grade_report_data.json`);
  },
  getLearnersGradeCsv(id){
    return this.execute('GET', `instructor/v1/user_sections/grade_report_download?section_id=${id}`);
  },
  getLearnerGradeReports(id){
    return this.execute('GET', `instructor/v1/user_sections/${id}/learner_grade_report.json`)
  },
  resetDialogic(id) {
    return this.execute('POST', `/v1/dialogic_evaluations/${id}/reset_dialogic.json`);
  },
  resetEmail(id) {
    return this.execute('POST', `/v1/user_email_evaluations/${id}/reset_email_lo.json`);
  },
  resetQuiz(id) {
    return this.execute('POST', `/v1/quiz_evaluations/${id}/reset_quiz.json`);
  },
  resetEmailSubmission(id) {
    return this.execute('POST', `/v1/modules/${id}/module_details/reset_submission_email`);
  },
  resetSubmission(id) {
    return this.execute('POST', `/v1/modules/${id}/module_details/reset_submission`);
  },
  getInstructorResourceVideos() {
    return this.execute('GET', `instructor/v1/modules/resource_videos.json`)
  },
  searchInstructorResourceVideos(search_query) {
    return this.execute('GET', `instructor/v1/modules/search_resource_videos.json?search_query=${search_query}`)
  },
  getGradingReportData(section_id) {
    return this.execute('GET', `instructor/v1/sections/${section_id}/reports_grading/learner_grading_data`)
  },
  getCompletionReportData(section_id) {
    return this.execute('GET', `instructor/v1/sections/${section_id}/reports_completion/learner_completion_data`)
  },
  getAssessmentReportData(section_id, learn_mod_id) {
    return this.execute('GET', `instructor/v1/sections/${section_id}/reports_assessment/learner_assessment_data?learn_mod_id=${learn_mod_id}`)
  },
  getReportsAvailable(section_id) {
    return this.execute('GET', `instructor/v1/sections/${section_id}/reports_available`)
  },
  getLlmChatHistory(data) {
    return this.execute('GET', `v1/llm_learner_messages?user_learn_obj_id=${data.user_learn_obj_id}`);
  },
  getLlmChatAssessment(data) {
    return this.execute('GET', `v1/user_llm_chat_evaluations/${data}`);
  },
  getLlmDialogicAssessment(data) {
    return this.execute('GET', `v1/user_llm_dialogic_evaluations/${data}`);
  },
  getLlmEmailAssessment(data) {
    return this.execute('GET', `v1/user_llm_email_evaluations/${data}`);
  },
  getFinalLlmDialogicFeedback(student_id) {
    return this.execute('POST', `admin/v1/get_final_dialogic_feedback`, student_id);
  },
  getDialogicFinalFeedbackMultiLo(data) {
    return this.execute('POST', `admin/v1/get_final_dialogic_feedback_multilo`, data);
  },
}

