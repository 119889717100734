<template>
  <div class="main-menu">
    <div class="main-menubar">
      <ul>
        <li :class="[currentPage('/learnerCentral') ? 'active' : '']">
          <router-link to="/learnerCentral">
            Learner Central
            <em v-if="currentPage('/learnerCentral')" class="icon-next"></em>
          </router-link>
        </li>
        <li :class="[currentPage('/milestoneDates') ? 'active' : '']">
          <router-link to="/milestoneDates">
            Milestone Dates
            <em v-if="currentPage('/milestoneDates')" class="icon-next"></em>
          </router-link>
        </li>
        <li
          :class="[currentPage('/messaging') ? 'active' : '']"
          v-if="!isBasicInstructor"
        >
          <router-link to="/messaging"
            >Messaging
            <em v-if="currentPage('/messaging')" class="icon-next"></em>
          </router-link>
        </li>
        <li :class="[currentPage('/manageRubric') ? 'active' : '']">
          <router-link to="/manageRubric">
            Manage Rubrics
            <em v-if="currentPage('/manageRubric')" class="icon-next"></em>
            <!-- <em class="icon-next"></em> -->
          </router-link>
        </li>
        <!--<li :class="[currentPage('/moduleGuide') ? 'active' : '']">
          <router-link to="/moduleGuide">
            Module Guide
              <em v-if="currentPage('/moduleGuide')" class="icon-next"></em>
          </router-link>
        </li>
        <li :class="[currentPage('/supportMaterials') ? 'active' : '']">
          <router-link to="/supportMaterials">
            Support Materials
              <em v-if="currentPage('/supportMaterials')" class="icon-next"></em>
          </router-link>
        </li>
        <li :class="[currentPage('/reports') ? 'active' : '']">
          <router-link to="/reports">
            Reports
            <em v-if="currentPage('/reports')" class="icon-next"></em>
          </router-link>
        </li>-->
        <li
          v-if="isUWInstructor"
          :class="[currentPage('/coding-reports') ? 'active' : '']"
        >
          <router-link to="/coding-reports">
            Coding Reports
            <em v-if="currentPage('/coding-reports')" class="icon-next"></em>
          </router-link>
        </li>
        <li
          :class="[currentPage('/plagiarism') ? 'active' : '']"
          v-if="enable_plagiarism"
        >
          <router-link to="/plagiarism">
            Plagiarism
            <em v-if="currentPage('/plagiarism')" class="icon-next"></em>
          </router-link>
        </li>
        <li :class="[currentPage('/learnerView') ? 'active' : '']">
          <router-link to="/learnerView">
            Learner View
            <em v-if="currentPage('/learnerView')" class="icon-next"></em>
            <!-- <em class="icon-next"></em> -->
          </router-link>
        </li>
        <!--<li :class="[currentPage('/exportAssessments') ? 'active' : '']">
          <router-link to="/exportAssessments">
            Export Assessments
              <em v-if="currentPage('/exportAssessments')" class="icon-next"></em>
          </router-link>
        </li>-->
        <li :class="[currentPage('/resourcesView') ? 'active' : '']">
          <router-link to="/resourcesView">
            Instructor Resources
            <em v-if="currentPage('/resourcesView')" class="icon-next"></em>
          </router-link>
        </li>

        <li :class="[currentPage('/reportingView') ? 'active' : '']">
          <router-link to="/reportingView">
            Reporting
            <em v-if="currentPage('/reportingView')" class="icon-next"></em>
          </router-link>
        </li>

        <!--<li>
          <a class="export-btn" @click="exportStudentList()">
            Export Student List
          </a>
        </li>

        <li>
          <router-link to="/active" class="dashboard-active-item">
            Back to Dashboard
          </router-link>
        </li>-->
      </ul>
    </div>
  </div>
</template>
<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
export default {
  name: "menuSidebar",
  data() {
    return {
      isBasicInstructor: false,
      enable_grading: "",
      enable_plagiarism: false,
      instructorInfo: {},
      moduleData: {}
    };
  },
  props: ["moduleID"],
  computed: {
    isUWInstructor: function(){
      return this.instructorInfo.attributes.user_roles[0] === "instructor" && this.instructorInfo.attributes.email.includes("uwaterloo.ca");
    }
  },
  methods: {
    currentPage(route) {
      if (this.$route.path === route) {
        return true;
      } else {
        return false;
      }
    },
    setInstructorPermissions() {
      this.instructorInfo = JSON.parse(
        localStorage.getItem("instructor")
      ).users;
      let role = this.instructorInfo.attributes.user_roles[0];
      if (role == "instructor_basic") {
        this.isBasicInstructor = true;
      }
    },
    exportStudentList() {
      utilFunctionService.showLoader();
      api
        .getSectionBreakdown(this.moduleID)
        .then((response) => {
          utilFunctionService.hideLoader();
          let res = response.data.csv_data;
          let fileName = response.data.filename;
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "text/csv" })
          );
          var link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          utilFunctionService.showSuccess(
            commonConfig.appMessages.studentCSVExportSuccessful
          );
        })
        .catch(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showerr(
            commonConfig.appMessages.studentCSVExportFail
          );
        });
    },
  },
  created() {
    this.instructorInfo = JSON.parse(localStorage.getItem("instructor")).users;
    this.setInstructorPermissions();
    setTimeout(() => {
      console.log("module", localStorage.getItem("module"));
      this.moduleData = JSON.parse(localStorage.getItem("module"));
      let learn_mod_attr = this.moduleData.attributes.learn_mods[0].attributes;
      this.enable_grading = learn_mod_attr.enable_grading;
      this.enable_plagiarism =
        this.moduleData.attributes.course_plagiarism_enabled;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.export-btn:hover {
  color: white;
}
</style>
