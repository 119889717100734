import Vue from 'vue'
import Vuex from 'vuex'
import grading from './modules/grading';
import learnerCentralStore from './modules/learnerCentralStore';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    grading,
    learnerCentralStore
  },
  strict: debug,
  plugins: [createPersistedState()],
})